import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function HelpPage(props) {
  return (
        <Box
          sx={{
            "& .MuiTypography-h5": { mb: 3, mt: 1 },
            "& .MuiTypography-h6": { mb: 2, mt: 1 },
            "& .MuiTypography-body1": { mb: 1.5 },
            "& ol, & ul": { my: 0 },
            "& li": { mb: 1 },
          }}
        >
          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">SIFp (Serious Injury or Fatality potential)</Typography>
            <Typography>First, let's define what a SIF event is:</Typography>
            <ul>
              <li>
                <b>Life-Threatening:</b> Work-related injury or illness that required immediate life-preserving rescue action, and if not applied
                immediately would likely have resulted in the death of that person.
              </li>
              <li>
                <b>Life-Altering:</b> Work-related injury or illness that resulted in a permanent and significant loss of a major body part or organ
                function that permanently changes or disables that person's normal life activity.
              </li>
              <li>
                <b>Fatal:</b> Work-related fatal injury or illness.
              </li>
            </ul>
            <Typography>Two examples that have the same outcome, but quite different potential:</Typography>
            <ol>
              <li>
                Employee fractures foot when they climb out of a truck cab, miss the bottom rung of the ladder, and fall 90cm to the ground. Their
                foot rolled off a small rock.
              </li>
              <li>
                Employee fractures foot when backed over by a forklift truck. The forklift operator backed up without looking, and the backup alarm
                was not functioning.
              </li>
            </ol>
            <Typography>
              Most organisations have great difficulty in assessing event potential. The use of risk matrices and other tools usually require a high
              degree of subjective judgement and are prone to all manner of cognitive biases. This application provides you with a structured and
              binary (yes / no) decision tree system to make more accurate and consistent judgement about SIF potential.
            </Typography>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Risk Controls</Typography>
            <Typography>
              This analytical framework is focused on whether the presence of risk controls is adequate to either mitigate or eliminate the
              possibility of unplanned events occurring.{" "}
            </Typography>
            <Typography>Safety theory suggests that if we do the following well enough:</Typography>
            <ol>
              <li>Risk-assess a task accurately enough (via Bowtie or other accepted method),</li>
              <li>Determine the controls that will negate the possibility of that risk outcome,</li>
              <li>Thoroughly implement those controls when performing the task,</li>
            </ol>
            <Typography>
              then the chances of a serious incident occurring are dramatically reduced. This is where the analysis begins, where we ask "which
              controls - if properly implemented - would have stopped this incident from occurring".
            </Typography>
            <Typography variant="h6">What is a control?</Typography>
            <Typography>
              An act, object (engineered) or system (combination of act and object) intended to prevent or mitigate an unwanted event. Examples of
              controls include:
            </Typography>
            <ul>
              <li>Pre-use mechanical inspection of mobile equipment.</li>
              <li>Risk assessment for barricading and drop zone control.</li>
              <li>Operator training in dangerous substance handling.</li>
            </ul>
            <Typography>
              These examples are obviously important controls in establishing a well-managed risk environment, but they aren't necessarily "critical
              controls".
            </Typography>
            <Typography variant="h6">What is a critical control?</Typography>
            <Typography>
              A critical control is a control that is crucial to preventing the event or mitigating the consequences of the event. The absence or
              failure of a critical control would significantly increase the risk despite the existence of the other controls. Examples of critical
              controls include:
            </Typography>
            <ul>
              <li>A properly worn harness with correct length lanyard attached to a solid anchor point.</li>
              <li>Assessing for fatigue prior to commencing a long journey at the wheel of a vehicle.</li>
              <li>Continuous monitoring of atmosphere quality during confined space entry.</li>
            </ul>
            <Typography>
              In most operating situations where a high-risk task needs control protections, there will typically be around 5-6 critical controls that
              are meant to minimize the acute risks of the activity.
            </Typography>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Control Enablement</Typography>
            <Typography>
              In the event of a serious incident (or potential incident), workers are more likely to have attempted to implement relevant controls,
              and remember that almost 100% of the time, workers are just trying to get the job done. Sometimes there is some kind of difficulty with
              doing that (situational or exceptional circumstances), but they also may neglect the control entirely or not be properly enabled to
              implement the relevant control. This is an important distinction later when considering organisational factors.
            </Typography>
            <Typography>
              Fully <b>enabled</b> controls are those that involve:
            </Typography>
            <ul>
              <li>adequate operator competence,</li>
              <li>easy to interpret and accurate procedure,</li>
              <li>appropriate and good condition tools or equipment,</li>
              <li>an environment that makes implementing that control very doable for the operator.</li>
            </ul>
            <Typography>
              Even the most complex isolation procedures can be readily facilitated with training, experience, documentation, and the right equipment
              and physical working context.
            </Typography>
            <Typography>
              It is important to remember here, that we say <i>FULLY ENABLED</i> from the perspective of the operator, not anyone else. Note that even
              with everything else set up for safe work, a challenging schedule and/or urgency from above will impact enablement of controls -
              especially those that take time to setup for the task.
            </Typography>
            <Typography>
              Examples of <b>difficult</b> or <b>non-enabled</b> controls:
            </Typography>
            <ul>
              <li>I need to use an EWP for any task above 3 meters, but the groundwork that surrounds the task is too uneven to use one</li>
              <li>We are expected to get this job done with one less operator than we need to do it by the book</li>
              <li>The gloves we have to use make it hard to grip the tools we have to perform isolation work.</li>
              <li>Management have delayed the acquisition of new lifting equipment, so we have to make do with older substandard kit</li>
            </ul>
            <Typography>Our definitions for the three enablement choices:</Typography>
            <ol>
              <li>
                <b>Enabled:</b> The control was entirely possible to be implemented by the worker in its 'required' manner but was implemented poorly
                or wasn't implemented at all.
              </li>
              <li>
                <b>Difficult:</b> The control was difficult to properly implement or unsuited to the particular work context, and either a version of
                the control was implemented, or it wasn't attempted.
              </li>
              <li>
                <b>Non-enabled:</b> The control was unable to be implemented by the worker in its 'required' manner due to unavailability of tools,
                equipment, or other situational circumstances.
              </li>
            </ol>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Human Errors</Typography>
            <Typography>
              Incidents frequently tend to involve human error somewhere in the system of work - from the frontline in-the-moment worker decision, to
              the very top of decision making in an organisation.
            </Typography>
            <Typography>There are two approaches to the problem of human fallibility:</Typography>
            <ol>
              <li>The person approach focuses on the errors of individuals, blaming them for forgetfulness, inattention, or moral weakness</li>
              <li>
                The system approach concentrates on the conditions under which individuals work and try to build defenses to avert errors or mitigate
                their effects
              </li>
            </ol>
            <Typography>
              Neither of the two statements above apply perfectly - and BOTH are almost always relevant in some way to situations where work does not
              go as planned.{" "}
            </Typography>
            <Typography>
              Incidents happen when barriers to unsafe work (controls) break down and fail to protect the worker. Of course, the worker has a role to
              play in this, but behaviours and results are influenced by a variety of factors beyond the control of front-line workers.
            </Typography>
            <Typography variant="h6">Worker intention</Typography>
            <Typography>
              When a critical control is either absent or ineffective, we sometimes call this a violation or a 'workaround'. For many reasons, this is
              often misinterpreted language, and can lead to a 'blame the worker' response. In the vast majority of incident situations, that is not a
              valid response, because the worker may have had to compensate for a situation that didn't allow for easy implementation of controls. The
              fact that the work has not gone to 'plan' will be down to a range of factors, and this is why we prefer to talk about 'workarounds'.
            </Typography>
            <Typography>
              Non-observance of critical rules or procedures can occur in different ways and for different reasons, with two broad categories outlined
              in research on human error:
            </Typography>
            <ol>
              <li>
                <b>Unintentional</b> Error result from unplanned human error, where there is a mis-read of the work context, and a critical control(s)
                is not fully implemented. These are usually the result of a cognitive failure of some kind, and are termed Slips, Lapses, or Mistakes.
                In more general terms, the worker meant to perform the task in the 'right' way, but something happens in the workers thinking process
                that results in an unintentional error.
              </li>
              <li>
                <b>Intentional</b> Workarounds occur from a deliberate intention to operate outside the 'rules'. They are almost always positively
                intended and result from unusual operating contexts or a longer-term cultural drift away from the rules. Note five conditions that
                tend to facilitate intentional workarounds:
                <ul>
                  <li>Expectation that rules have to be broken.</li>
                  <li>Sense of competence to work around procedures.</li>
                  <li>Existence of the opportunity to break a rule.</li>
                  <li>Inadequate work planning.</li>
                  <li>Work environment unsuited to rules.</li>
                </ul>
              </li>
            </ol>
            <Typography variant="h5">Slip or Lapse</Typography>
            <Typography>
              <i>"Right intended action gets executed in the wrong way"</i>
            </Typography>
            <Typography>
              These are actions that didn't go as planned during a task that is likely to be familiar to the worker (ie changing down to into 3rd gear
              when you correctly intended to change up to 5th). <i>"Whoops, that's not what I meant to do!"</i> is often the response to a slip, or
              you might hear: <i>"I lost my place midway through the task"</i>, which would indicate a lapse.
            </Typography>
            <Typography>Examples of Slips:</Typography>
            <ul>
              <li>Using too much strength and over-torquing a bolt.</li>
              <li>An elevated work platform operator pushing the joystick to the left instead of the right.</li>
              <li>Selecting the wrong size nail for the job.</li>
              <li>Checking a dial but for the wrong value.</li>
            </ul>
            <Typography>Examples of Lapses:</Typography>
            <ul>
              <li>Forgetting to nail down a joist.</li>
              <li>Taking a mask off to talk to a colleague and then forgetting to put it back on.</li>
              <li>Failing to secure scaffolding because of an interruption.</li>
              <li>Forgetting to remove a wall radiator before removing the wallpaper.</li>
            </ul>
            <Typography variant="h6">Types of Slips or Lapses:</Typography>
            <ul>
              <li>
                <b>Auto-pilot:</b> In 2011, Daniel Kahneman brought our attention to the concept of thinking fast and thinking slow, and during the
                last decade these ideas grounded in neuroscience have an important application in workplace safety. Slow thinking is the conscious,
                deliberate consideration of a situation or problem, and is critical to noticing and resolving challenges in workplace exposure. Fast
                thinking is the automatic, habitual, non-deliberate and largely unconscious form of thinking (hence auto-pilot) that allows us to
                perform routine tasks quite efficiently - especially when the task environment is highly predictable. A key workplace challenge is
                differentiating between when being in 'auto-pilot' is ok, and when it's not ok, especially when performing a critical risk task.
              </li>
              <li>
                <b>Pressure:</b> Pressure most often manifests in team members and how they manage critical-risk tasks when operational leaders and
                supervisors react poorly to process upsets, missed deadlines, or some form of operational crisis. Poor decision quality and excessive
                risk tolerance by workers is almost guaranteed to follow. Reactions to pressure that involve a sense of urgency and stress are
                negatively affected by fatigue, so when these cognitive factors are identified as present in a given incident, the importance of
                addressing it is significantly increased.
              </li>
              <li>
                <b>Perception:</b> The human visual system operates in a way that is unlike a video camera and involves the brain deciding what it
                will pay attention to, and then - to some extent - making up a story that fits the model of the world that our brains have created
                from past experience. This means we can't completely trust what we think we see - especially in situations where the unexpected occurs
                or where information doesn't seem to 'fit' our current model of the world. This has significant implications for team members noticing
                situations or actions that create undesirable exposure or hazards. A normal brain doesn't naturally know how to notice deeply and
                broadly, but it can be trained to do so.
              </li>
              <li>
                <b>Fatigue:</b> Research on quality sleep loss and excessive work intervals has shown that risk for error multiplies dramatically
                (geometric rather than linear). There is also a proven relationship between cognitive fatigue and reduced decision-making
                effectiveness, self-centeredness, visual acuity, and the likelihood of greater risk-taking to meet a perceived deadline. Broadly
                speaking, an excessive level of cognitive fatigue acts to increase the risks of all the other cognitive hazards that contribute to
                human error.
              </li>
              <li>
                <b>Memory:</b> When the implementation of critical procedures and controls rely on experience or accurate memory, human error is
                likely to become a factor. One important way of addressing this is to provide the workforce with clear, concise, and easily followed
                procedures that align with the mechanics of how the brain works. Poorly written, ambiguous, or out-of-date standard operating
                procedures lead team members to misinterpret what is needed to operate with minimum risk.
              </li>
              <li>
                <b>Groupthink:</b> All operations will see new or unexpected situations arise that require team members to notice increasing exposure
                and elevated risk and call that out before it becomes critical. This positive team member behaviour is somewhat unnatural in the face
                of the competing desire to "go along to get along" with the group, and must be actively reinforced as not only OK, but necessary for
                the team to maintain a safe operating environment.
              </li>
            </ul>
            <Typography variant="h5">Mistakes</Typography>
            <Typography>
              <i>"Wrong intended action gets executed in the right way"</i>
            </Typography>{" "}
            <Typography>
              Mistakes are generally errors of judgement or decision-making where the worker has done the wrong thing believing it to be right. These
              situations may involve misapplication of a good rule, application of a bad rule, or simply a lack of requisite knowledge or skill to
              perform the task. You might hear an operator say: <i>"That should have worked - it has every other time"</i>.
            </Typography>
            <Typography>Examples of Mistakes include:</Typography>
            <ul>
              <li>Leaving insufficient room when overtaking in the face of oncoming traffic.</li>
              <li>Misinterpreting the sound of a machine breakdown and failing to switch off.</li>
              <li>Choosing the wrong anchor point for a lanyard to connect to.</li>
              <li>Selecting the wrong vehicle for the conditions.</li>
            </ul>
            <Typography variant="h6">Types of Mistakes:</Typography>
            <ul>
              <li>
                <b>Decision:</b> Poor decision making is very often a product of cognitive bias that leads to flawed judgement about future
                probabilities. Workers and their leaders are often faced with too many choices to process when making a decision, and the brain uses
                'shortcuts' based on what has happened and worked before. This is a necessary and useful thought process, but sometimes results in
                error of judgement. The use of too little information; favouring information that supports preconceptions; being influenced by where
                and who the information is coming from (the halo effect); favouring information from your closest colleagues rather than elsewhere,
                are just a few of the types of bias that affect decision-making on whether to, or how to manage exposure.
              </li>
              <li>
                <b>Planning:</b> The simplest indication of a planning failure is where the job planner fails to identify all the risks of the task,
                which will inevitably result in increased exposure beyond what is acceptable and run the risk of an unplanned or negative outcome.
                This can happen in the field where the frontline worker or supervisor moves to task too quickly before properly analysing the risks.
                The risks should also consider work performed prior (upstream), work to be performed as a result of the current task, and adjoining
                work that may have an effect on the current task.
              </li>
              <li>
                <b>Information:</b> There are at least three indicators of information failures: when key aspects of the work context are unknown to
                the worker when they are planning how to manage it; when technical documentation for the task are unavailable, inaccurate or out of
                date; and when the worker simply fails to access available information to fully identify the risks of the situation.
              </li>
              <li>
                <b>Skill:</b> When the worker is handicapped by a lack of competence, knowledge or experience, their ability to complete a task that
                involves a potentially hazardous piece of equipment, or a task that has inherently difficult procedural requirements, is severely
                compromised. Moreover, unexpected hazards that emerge in the field that were not planned for, are more likely to be unnoticed or
                responded to in a way that puts them at greater risk.
              </li>
            </ul>
            <Typography variant="h5">Routine Violation</Typography>
            <Typography>
              A routine violation is a deliberate short-cut or deviation from the rule or procedure that has become - over time - the accepted way of
              operating. It is very rarely a case of willful rule-breaking, and usually results from an intention to get the job done despite the
              consequences. They almost always involve the intentional failure to apply a 'good rule' in favour of saving time or reducing effort and
              will generally become accepted within a work group or across a site operation. You might hear an operator say:{" "}
              <i>"It's just the way we've tended to do that around here"</i>.{" "}
            </Typography>
            <Typography>Examples of Routine Violations include:</Typography>
            <ul>
              <li>Scaffolders not clipping on their harnesses above 4 m.</li>
              <li>Truck drivers not wearing seat belts.</li>
              <li>Elevated platform operators not carrying out pre-use checks.</li>
              <li>A site manager allowing untrained drivers to operate plant.</li>
            </ul>
            <Typography variant="h5">Exceptional Violation</Typography>
            <Typography>
              An exceptional violation is a deliberate short-cut or deviation from the rule or procedure due to one-off or unusual operating condition
              where the established control is not suitable for managing the task risk, and a different solution must be created to control exposure.
              These situations are inherently difficult to comply with the established rules and require some operator / supervisor creativity that
              should be driven by high-quality risk assessment and careful design of ad hoc controls. You might hear an operator say:{" "}
              <i>"We didn't have any choice and given the circumstances it was the best thing we could do"</i>.{" "}
            </Typography>
            <Typography>Examples of Exceptional Violations include:</Typography>
            <ul>
              <li>Compromise exclusion zones because two trucks arrive for loading simultaneously.</li>
              <li>Increasing manual handling requirements to compensate for a machine breakdown.</li>
              <li>An isolation requirement not covered by procedure requiring a one-off approach.</li>
              <li>A one-off work at height breach to manage an EWP failure. </li>
            </ul>
            <Typography variant="h5">Situational Violation</Typography>
            <Typography>
              A deliberate short-cut or deviation from the rule or procedure because of the established operating context (work environment, equipment
              availability, available resources including people) that renders the rule or procedure difficult or impossible to implement - sometimes
              known as a 'bad rule'. These situations are typically the result of long-term unsuitability of established controls and tend to result
              in procedural adaptations over time that often carry elevated risk. You might hear an operator say:{" "}
              <i>"It's the only way we can get this task done, given the way the site is set up and the people we have to do it"</i>.{" "}
            </Typography>
            <Typography>Examples of Situational Violations include:</Typography>
            <ul>
              <li>The delivery dock space is too small to allow for mandated pedestrian exclusion zones.</li>
              <li>Having an outdated processing plant means working with riskier procedures.</li>
              <li>The working environment is too cold for mandated PPE so different products are used.</li>
              <li>The journey management policy doesn't integrate and work with shift structure policy.</li>
            </ul>
            <Typography variant="h5">Act of Sabotage</Typography>
            <Typography>
              A deliberate and malicious act with the intention to harm the organisation and/or its people. These are extremely rare, and although
              possible, they are very unlikely to be the case. The question we should ask:{" "}
              <i>"Was this violation likely to have been motivated by a person with malicious intent to damage the organisation in some way?"</i>
            </Typography>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Operational Factors</Typography>
            <Typography>
              All incidents occur within a context, whether they occur 'inside' or 'outside' the physical operating boundaries controlled by the
              business. This context is driven by a number of things such as operating culture, leadership behaviour, risk-reward balance, equipment
              availability, supervision quality, and so on. The operating context plays a significant role in the decisions that frontline workers
              make, when juggling risk and reward to get the job done. Let's look at a few examples:
            </Typography>
            <ul>
              <li>
                In the face of flu-season driven absences, the sites management team has decided they won't adjust the work schedule because they are
                behind on production targets.
              </li>
              <li>
                A new piece of equipment is put into commission to replace an older model, but the revised procedural training has not been scheduled
                for the crews expected to use it.
              </li>
              <li>
                Crew members that always get the job done quickly tend to be promoted into supervisor roles and expect their colleagues to operate
                just like they did.
              </li>
              <li>
                Day shift workers rarely stay back to participate in a handover to the evening shift, and frequently leave the work environment in a
                hazardous state.
              </li>
              <li>
                Management has known for a long time that exclusion zone requirements for forklifts are impossible to comply with, but still impose
                sanctions for non-compliant behaviour.
              </li>
            </ul>
            <Typography>
              What you will notice with these examples is that workers are not always set up by their leaders for performance of work with the right
              level of control. In many cases they will assess that it's ok to take shortcuts or excessive risk to get the job done, and the behaviour
              (or non-behaviour) by leaders and supervisors lends weight to that assessment. If the incident involves what is often called an
              <i>intentional 'workaround'</i> or deliberate <i>rule violation</i>, it is very likely that the behaviour has been influenced by an
              Operational Factor such as:
            </Typography>
            <ul>
              <li>
                Mixed messaging from leaders who haven't been consistent with communicating their priorities and may not have followed up on known
                issues in the past,
              </li>
              <li>
                Absent, distracted or risk-tolerant supervisors who don't adequately help their crews to understand how and when to assess risk and
                shift their approach to the work,
              </li>
              <li>
                Work crews and/or Contractors that don't adequately collaborate on risk assessments and how to treat those risks before they become
                hazards and incidents,
              </li>
              <li>
                Challenging workplace environments that are known to have inherently high levels of hazard but have not been addressed in the past'
                and
              </li>
              <li>
                Sometimes individual workers fail to adequately manage their own risks even when everything needed for safe work is available to them.
              </li>
            </ul>

            <Typography variant="h5">Leader Behaviour</Typography>
            <Typography>
              The focus of leaders who lead operations at the 'coalface' is typically governed by several different (but hopefully) complementary key
              performance indicators. At a basic level, the most obvious will involve something to do with production targets, followed by product
              quality, cost management, and the safety of their people.{" "}
            </Typography>
            <Typography>
              Many organisations speak about safety being their #1 objective, but in reality, it is more aligned to a quality objective which will be
              secondary to production-related objectives. That being said, no leader is likely to ignore their safety objectives, as even the most
              transactional leader will want to avoid the suffering (on many fronts) associated with a worker injury, asset damage or avoidable errors
              that have a negative impact on production capability.
            </Typography>
            <Typography>
              So the task of a leader is complex and managing priorities is a constant juggling act. Examples of how this juggling act can negatively
              affect how work is performed include:
            </Typography>
            <ul>
              <li>Work pressure or urgency has been created by leader communication about targets or KPIs.</li>
              <li>Risk-tolerant behaviour that nevertheless achieves production KPIs has been condoned.</li>
              <li>Over-reactions to unplanned outcomes have made transparent reporting difficult.</li>
              <li>Failure to take action in the past to resolve hazards has triggered operator cynicism.</li>
              <li>Field supervision inadequacies have not been addressed or been overlooked in the past.</li>
            </ul>
            <Typography>Normalisation of deviation in expectations of critical risk behaviours has occurred over time.</Typography>

            <Typography variant="h5">Management</Typography>
            <Typography>
              Operational management is concerned with designing and controlling the processes of production and constant adaptation of business
              operations to meet changing customer and market contexts. Aspects of operational management that have a direct bearing on how high-risk
              work is controlled includes how work is designed, resourced, and supported by appropriate equipment, and how change is managed from time
              to time.{" "}
            </Typography>
            <Typography>
              While it is the leaders of an operation who hold most decision-making responsibility, there are many technical specialist roles that
              influence how well operational management supports high-risk work in the 'field', or 'on the floor'.
            </Typography>
            <Typography>
              So operational management has many inherent challenges that can affect how people manage high-risk work, with some examples below:
            </Typography>
            <ul>
              <li>Relevant tools and equipment were not adequately provided where it is needed.</li>
              <li>Relevant tools and equipment have not been adequately maintained.</li>
              <li>Rules or procedures were out of date with current plant and work processes.</li>
              <li>Poor-fit safety management systems have been treated as noise that could be tolerated.</li>
              <li>Management of change (MOC) processes were inadequate or excluded key stakeholders.</li>
              <li>Staffing of workers was insufficient for the relevant critical risk activities.</li>
            </ul>

            <Typography variant="h5">Supervision</Typography>
            <Typography>
              Supervision of high-risk work is a critical element of a well-run business operation. It starts with work planning and setting up the
              work crew with a clear understanding of where the highest risk exists and rehearsing how those risks should be managed before work
              commences. Supervision continues in the field with observation of tasks being performed, feedback provided to crew members, and problem
              solving when the state of hazards appears to change.
            </Typography>
            <Typography>
              What the supervisor pays attention to, and how they engage their people, has a critical influence on how work is performed within an
              acceptable 'risk envelope'.
            </Typography>
            <Typography>
              The task of a supervisor is to ensure they have prepared their team adequately for managing high-risk work in the most controlled manner
              possible. Examples of supervision failures:
            </Typography>
            <ul>
              <li>Supervisor field presence during critical risk tasks was inadequate.</li>
              <li>Engagement of frontline workers in problem solving did not happen.</li>
              <li>Poor performance management of team members resulted in misaligned behaviour.</li>
              <li>Excessive risk tolerance crept into operational or task-handling decisions.</li>
              <li>Rules relating to critical procedures and equipment handling were poorly understood.</li>
              <li>Planning was inadequate or absent for critical risk tasks or work orders.</li>
            </ul>

            <Typography variant="h5">Individual</Typography>
            <Typography>
              We know that workers are not able to function like robots, and they come to work with a unique personality, background, competency, and
              experience. They also have a non-work life that can and often does affect their ability to be focused and alert when its most important
              - ie when they are most at risk.
            </Typography>
            <Typography>
              Issues such as attitudes toward risk, lifestyle-induced fatigue, understanding (and acceptance) of rules, and over-confidence, are some
              of the contributors to why many incidents occur. Some examples:
            </Typography>
            <ul>
              <li>Relevant rules don't make sense to experienced frontline workers.</li>
              <li>Perceived +ives of breaking the rules outweighed the perceived -ives.</li>
              <li>Rules were seen as too strict or unnecessary and get in the way of task completion.</li>
              <li>A high value was placed on timesaving to get the job done quickly.</li>
              <li>Lifestyle-driven fatigue affected the capacity of worker(s) to perform safely.</li>
              <li>Excessive self-confidence in worker ability led to completing tasks without required controls.</li>
            </ul>

            <Typography variant="h5">Team Function</Typography>
            <Typography>
              While some tasks involve lone workers (ie driving vehicles), most high-risk work is performed in teams of two or more workers, and how
              these workers operate together can have a significant bearing on how well-controlled the work is performed.
            </Typography>
            <Typography>
              In some cases, different teams performing different tasks can impact each other's level of risk - especially when there is inadequate
              planning or collaboration by each team. Sometimes that collaboration is focused on an effective shift-change, or it may be two aspects
              of a larger task that need to be coordinated.
            </Typography>
            <Typography>
              Teams who work together (and with other teams) effectively are more likely to have successful outcomes. Some examples:
            </Typography>
            <ul>
              <li>Conflicts between local teams occurred on the same shift or in shift handovers.</li>
              <li>Social pressure existed to maintain consistency with historical practices even when risk increased.</li>
              <li>Team cohesion was questionable where contractors work alongside employees.</li>
            </ul>

            <Typography variant="h5">Environment</Typography>
            <Typography>
              The environment where work is performed is a critical influence on whether controls are likely to be implemented in a way that manages
              risk to a desirable level. While work plans should anticipate the variables of weather, space, surfaces and so on, it is impossible to
              forecast every eventuality in a risk assessment. In situations like this, workers need to adapt their approach to the situation, and
              this can sometimes result in a less than well-controlled task.
            </Typography>
            <Typography>
              In many cases, workers have become used to adapting to a situation that is not covered by 'standard operating procedure', but this
              usually involves an increased level of risk (or reduction in control). Some examples:
            </Typography>
            <ul>
              <li>Challenging local operating conditions made compliance difficult or impossible.</li>
              <li>Poor housekeeping of the work environment created a hazardous environment.</li>
              <li>Ambient conditions - too hot, cold, humid, wet, dry - contributed to compliance challenges.</li>
            </ul>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Organisational Factors</Typography>
            <Typography>
              Organisational factors behind the occurrence of an incident are often called 'latent factors' or 'system influences'. We must look much
              further upstream of the event to isolate the influence of organisational systems, but because they have an indirect relationship to the
              event, it is very easy to miss their impact.{" "}
            </Typography>
            <Typography>
              If we don't properly identify these systems, as sure as night follows day, the incident is very likely to repeat itself, no matter what
              actions we put in place at the operational level.
            </Typography>
            <Typography>Some examples of how this happens:</Typography>
            <ul>
              <li>
                Site management do their best to analyse and forecast labour budget settings but corporate restructuring decisions or centrally driven
                changes in operating strategy result in significant work planning and scheduling challenges at a local level.{" "}
              </li>
              <li>
                Workers are expected to operate within policies or rules that are difficult to interpret or are inconsistent, and poor or absent
                corporate communication of critical procedures contributes indirectly to the incident.
              </li>
              <li>
                Contractors are simultaneously held to the highest standard of safety performance while their contract with the organisation penalises
                any late delivery regardless of circumstance, or heavily rewards completion ahead of schedule.
              </li>
              <li>
                Equipment that routinely fails and forces workers to work around the resulting problems are traced back to a procurement decision or a
                maintenance contract that is focused mostly on cost rather than quality.
              </li>
            </ul>
            <Typography>
              In many cases, analysis of potential organisational factors is a difficult task to perform with confidence - especially when an incident
              report has inadequate information. But where controls for high-risk work are not enabled for frontline workers, it is very important to
              carefully assess whether latent organisational systems are part of the problem. Your knowledge and experience of how the different
              central organisational departments interact to support safe work will help you, and further research (beyond the incident report) is
              necessary part of the investigation process.
            </Typography>
            <Typography variant="h6">Organisational Factors & Control Enablement</Typography>
            <Typography>
              The influence of enablement on whether organisational factors may have influenced an incident will usually result in one of the
              following two scenarios:
            </Typography>
            <ul>
              <li>
                <b>If any control was difficult or non-enabled:</b> Your review of control inadequacy suggests that 'enablement' of this control in
                the field was less than acceptable, and a review of organisational factors beyond the control of local operational management is
                required.
              </li>
              <li>
                <b>If all controls were enabled:</b> Your review of control inadequacy suggests that 'enablement' of controls in the field was most
                likely acceptable, but it is nevertheless possible that one or more organisational factors beyond the control of operational
                management contributed to the event occurring. If you wish to complete this step to analyse that possibility, continue with a review
                of potential organisational factors.
              </li>
            </ul>

            <Typography variant="h5">Plant & Equipment</Typography>
            <Typography>
              While risks associated with actual use of plant & equipment is an operational concern, decisions about safety-in-design, procurement,
              and maintenance agreements are more often a centralised corporate responsibility. We often see breakdowns in communication and
              collaboration between corporate and operational functions that result in misalignment of needs.
            </Typography>
            <Typography>
              Issues relating to cost and the setting up (and governing) of supplier contracts may dominate the focus of those managing procurement,
              while operations are concerned more about the suitability of plant and equipment, training of personnel in their use, and ongoing
              maintenance effectiveness.
            </Typography>
            <Typography>
              Any incidents that have identifiable causal factors associated with equipment failures, maintenance, or suitability to the operating
              environment, are likely to have latent causes beyond the responsibility and decision-making power of operational management. Consider
              some of the following:
            </Typography>
            <ul>
              <li>Safety is not a primary consideration at the design phase of work, plant, equipment, environment, and processes.</li>
              <li>Plant & equipment subject to periodic maintenance is not thoroughly operational or fit for purpose.</li>
              <li>Plant & equipment are procured on the basis of lowest cost rather than lowest risk for required capacity.</li>
              <li>Tools, equipment, and plant/machinery aren't readily available where they are required throughout operations.</li>
              <li>Signals of plant, tool, or equipment failure fail to be noticed or responded to with review and change management.</li>
            </ul>

            <Typography variant="h5">Safety Management System</Typography>
            <Typography>
              A Safety Management System is a set of policies, procedures and plans designed to systematically manage health and safety at work with
              the objective of minimising the risk of injury and illness from workplace operations.
            </Typography>
            <Typography>
              Broadly speaking, the system should provide clarity about how risk should be managed and provide the tools and governance structure to
              ensure workplace risk is managed within the risk appetite of the organisation. The following are the main functions of the system:
            </Typography>
            <ul>
              <li>Define how the organisation is set up to manage risk.</li>
              <li>Identify workplace risk and implement suitable controls.</li>
              <li>Implement effective communications across all levels of the organisation.</li>
              <li>Implement a process to identify and correct non-conformity and non-compliance issues.</li>
              <li>Implement a continual improvement process.</li>
            </ul>
            <Typography>
              Complex operations that are forever changing and adapting to external environments mean that a safety management system also must be
              constantly adapted. This ongoing task that often creates challenging administrative burdens, and leaves gaps or misalignments with the
              realities of operations. Examples of weaknesses in the safety management system:
            </Typography>
            <ul>
              <li>Operational safety risks are only partially assessed and treated, and/or unverified in the field.</li>
              <li>Safety-related policies are not fully in alignment with known critical risks and/or are poorly communicated.</li>
              <li>Oversight of safety is poorly informed, with the true state of risk management hidden from senior leaders.</li>
              <li>Procedural documentation is out of date and/or difficult to absorb and understand by operators.</li>
              <li>Risk controls are not reviewed and/or periodically audited for protective effectiveness and compliance.</li>
              <li>The work environment (including fixed or temporary structures) is not set up appropriately for safe operation.</li>
              <li>Comprehensive plans are not in place to respond to emergencies and/or capability to respond is inadequate.</li>
            </ul>

            <Typography variant="h5">Human Resource Management</Typography>
            <Typography>The central Human Resources function has a very broad remit, and responsibilities include:</Typography>
            <ul>
              <li>Human resource planning to ensure enough people are available for required roles, both now and in the future.</li>
              <li>Recruitment and selection process and criteria (and often the handling actual recruitment)</li>
              <li>
                Performance management processes that clearly articulate job requirements and the performance of individuals to those requirements.
              </li>
              <li>Learning and development that ensures adequate competency for operational requirements.</li>
              <li>Career planning to ensure talent is managed and promoted throughout the organisation.</li>
              <li>Reward and recognition systems that reinforce desirable behaviours.</li>
              <li>Industrial relations, including award structures and how they balance employee wellbeing and production needs.</li>
              <li>Employee participation and communication to facilitate people being heard and to hear what's going on.</li>
              <li>Personal wellbeing practices as they relate to all employees in the organisation.</li>
            </ul>
            <Typography>
              While all of the above have some level of influence on how effectively operations manages risk, there are a few that are
              mission-critical. Potential issues relating to human resource management:
            </Typography>
            <ul>
              <li>Operational goals/targets are not adequately balanced between risk and production performance outcomes.</li>
              <li>Operator knowledge and skills are not fully adequate for the task required to be performed.</li>
              <li>Supervisor skills and knowledge are insufficient to perform effective management of high-risk work.</li>
              <li>Supervision is inadequately resourced to perform in-field critical risk task oversight.</li>
              <li>Work planning and resourcing are not designed to fully enable operators to perform tasks at a safe pace.</li>
              <li>Critical risk tasks are not always allocated to competent employees or contractors with systems of safe work.</li>
              <li>Communication tends to be top-down and does not facilitate timely awareness of critical risk management.</li>
            </ul>

            <Typography variant="h5">External Management</Typography>
            <Typography>
              External entities and/or individuals to the organisation are also referred to as third parties, and for the purposes of this exercise we
              are talking mainly about contractors, suppliers, and regulatory bodies. All three of these external entities can and will have some
              level of effect on the risk profile of the business, and to some extent can directly affect how risk is managed.
            </Typography>
            <Typography>
              For many businesses, <b>contractors</b> handle a large volume of the work performed by the organisation (sometimes the majority), and
              the extent to which they meet acceptable risk management standards is often governed by how contracts are established, whether adequate
              due diligence is performed pre-contract, and how well contractor work is overseen by the organisation's own employees.
            </Typography>
            <Typography>
              The organisation will be heavily dependent on the capability of <b>suppliers</b> of physical equipment, key work systems, maintenance
              services etc. Their ability to supply goods and services that meet minimum standards of risk management has a direct bearing on the
              organisation's likelihood of performing reliable work.
            </Typography>
            <Typography>
              Bodies that perform <b>regulatory</b> functions generally set minimum standards for performance of high-risk work and how certain types
              of high-risk plant and equipment must be used. In some cases, regulations fall behind technological change, and when minimum standards
              are followed without considering their applicability, risk can often be elevated. Potential issues to consider:
            </Typography>
            <ul>
              <li>Contracting out work is not preceded by validation of contractor safety management system and practices.</li>
              <li>Work performed by contractors is not adequately validated in the field to ensure meeting of safety obligations.</li>
              <li>The choice of external suppliers is not influenced by their impact on the safety of operations.</li>
              <li>Supplier goods and services critical to operational reliability are rarely reviewed for stability and quality.</li>
              <li>Regulatory requirements are considered as the targeted standard for high-risk work, rather than the minimum.</li>
            </ul>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Improving Critical Controls</Typography>
            <Typography>
              Most organisations utilise the "Hierarchy of Controls" (HOC) as a model for assessing inherent control effectiveness. There are
              well-known problems and issues with this approach such as:
            </Typography>
            <ul>
              <li>
                Easily misused as a classification mechanism, with the model (and most users) vague about what constitutes each category. For example:
                <ul>
                  <li>Monitoring of weather causes work at height to be cancelled - is it elimination or administrative?</li>
                  <li>Should elimination be permanent, or can it be temporary?</li>
                  <li>Is an airbag an engineering control or PPE?</li>
                </ul>
              </li>
              <li>
                The hierarchy has only 5 categories (if you aggregate isolation and engineering which most do), and the 'jump' from administrative to
                engineering and isolation is a large one - in effort, complexity, and investment.
              </li>
              <li>
                The HOC framework says that engineering solutions are more effective than administrative or PPE (lowest), but in practice, a
                well-designed PPE solution may be the best answer to an exposure that can't be eliminated (ie leather gators for workers exposed to
                snakes in long grass). Moreover, within each category, there is scope for wide variations in control or action effectiveness, despite
                classified as the same HOC category.
              </li>
            </ul>
            <Typography>
              In response to these issues above, Incident Analytics™ developed a more comprehensive methodology to assess controls for their ability
              to manage exposure, and this has been hard-wired into the analytical process. An explanation on how to interpret and use this framework
              follows:
            </Typography>

            <Typography variant="h5">Exposure management functions</Typography>
            <Typography>
              <i>"What controls should do"</i>
            </Typography>
            <Typography>
              Once we've accepted there is a hazardous energy that needs to be controlled, we should try to do the following in order:
            </Typography>
            <ol>
              <li>
                <i>Eliminate the energy that exists or could exist (very hard to do in most circumstances)</i>
              </li>
              <li>
                <i>Minimise the energy (less difficult than #1)</i>
              </li>
              <li>
                <i>Prevent or minimise the release of energy</i>
              </li>
            </ol>
            <Typography>
              If none of these are possible, we have to see how we can keep the hazardous energy under control. This will involve controlling
              deviations in how people operate in the face of hazardous energy. The first option is to:
            </Typography>
            <ol start="4">
              <li>
                <i>Separate the potential event from humans or a critical structure in either time, space, or both.</i>
              </li>
            </ol>
            <Typography>If we can't control the potential deviation by separation, we have to:</Typography>
            <ol start="5">
              <li>
                <i>Protect the human or structure from the potential event</i>
              </li>
            </ol>
            <Typography>And lastly, assuming the event may occur and result in an unplanned outcome, we have to:</Typography>
            <ol start="6">
              <li>
                <i>Have response mechanisms in place that minimise the impact short and long term.</i>
              </li>
            </ol>
            <Typography>Note: The exposure management functions above are an adaptation of William Haddon's 10 Countermeasures</Typography>

            <Typography variant="h5">Exposure management systems</Typography>
            <Typography>
              <i>"How controls should work"</i>
            </Typography>
            <Typography>
              Just as with <b>exposure management functions</b>, there is an implied hierarchy of effectiveness when considering how a control should
              work in practice. There are four categories of control system that are defined more broadly as either <b>physical systems</b>{" "}
              (independent of human action) or <b>behavioural systems</b> (reliant on human action).
            </Typography>
            <Typography variant="h6">Physical Systems</Typography>
            <ol>
              <li>
                <Typography>
                  <b>Passive / Continuous:</b> Permanent physical control that requires no human action, apart from periodic maintenance
                </Typography>
                <Typography>Examples: a wall between pedestrians and mobile plant; work area ventilation system</Typography>
              </li>
            </ol>
            <ol start="2">
              <li>
                <Typography>
                  <b>Exposure-triggered:</b> Physical control that is triggered by a particular level of exposure and is automated
                </Typography>
                <Typography>Examples: vehicle airbag; interlocks and light curtains</Typography>
              </li>
            </ol>
            <Typography variant="h6">Behavioural Systems</Typography>
            <ol>
              <li>
                <Typography>
                  <b>Technical:</b> Technical control that relies on a particular combination of human action and equipment
                </Typography>
                <Typography>Examples: emergency shutdown system; confined space monitoring</Typography>
              </li>
            </ol>
            <ol start="2">
              <li>
                <Typography>
                  <b>Procedural:</b> Procedural control that relies solely on competent human action
                </Typography>
                <Typography>Examples: checklist completion; defensive driving</Typography>
              </li>
            </ol>

            <Typography variant="h5">Using the Controls Effectiveness Framework</Typography>
            <Typography>
              After auditing hundreds of 'critical' controls across most high-hazard industrial sectors, we know that the majority of controls involve
              a very heavy reliance on workers knowing and working precisely to the letter of a written procedure or rule. This means that human error
              - which is a naturally occurring feature of being human - is rarely taken into account.{" "}
            </Typography>
            <Typography>
              We therefore need to challenge ourselves to look for ways to strengthen critical controls so that they are not solely reliant on human
              decision making, memory, and situational awareness.
            </Typography>
            <Typography>
              The framework is a matrix that cross-references 'how controls should work' with 'what controls should do' and applies a score against
              each matrix element. These scores are based on relative control effectiveness in the 'real world' regardless of how they may have been
              developed, and account for the expected reliability of thorough implementation:
            </Typography>
            <img src="" alt="Framework matrix" />
            <Typography>
              From this matrix, you will note that physical and exposure-triggered control systems have substantially higher effectiveness in managing
              exposure, and the challenge for organisations is to strive to push their control mechanisms beyond being merely procedural in nature.
              This is obviously easier said than done (cost being a factor), but a vigilant effort at control improvement will produce significant
              benefit in exposure management.
            </Typography>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Slips or Lapses</Typography>
            <Typography variant="h6">Auto-Pilot</Typography>
            <ul>
              <li>
                Educate leaders and supervisors in these concepts to ensure they have a deep understanding of how a normal brain is hard-wired to
                behave in these two ways, the pitfalls, and how to play a positive role in limiting the additional exposure that can be created.
              </li>
              <li>
                Consider education of frontline operational teams in fast/slow brain function, and how to trigger slow brain in critical moments where
                unexpected changes in exposure have occurred.
              </li>
              <li>
                Build 'cognitive nudges' into identified critical procedural steps - especially those that have revealed themselves to be classical
                auto-pilot situations. For example, journey management should involve contact with drivers to trigger road scanning behaviour and
                switch on alertness.
              </li>
              <li>
                Consider whether the structure of tasks may unwittingly encourage fast thinking and explore re-design of those tasks to require a
                shift to slow thinking.
              </li>
            </ul>
            <Typography variant="h6">Pressure</Typography>
            <ul>
              <li>
                Consider training and/or coaching of leaders in managing emotional reactions to competing demands, and the challenge of remaining calm
                in the face of perceived crisis.{" "}
              </li>
              <li>
                Front line team members can also benefit from awareness of how urgency and stress is transferred down through the hierarchy, and
                reinforcement of the expectation from senior leadership that they can and should push back when risk levels stretch beyond acceptable
                limits.
              </li>
              <li>
                Where urgency or stress is a product of excessive risk tolerance or misalignment, this should be addressed with leaders via a formal
                reset of expectations and realignment of risk appetite and what is tolerated in the field.
              </li>
              <li>
                Consider how organisational messaging may reinforce urgency or stress, ie "we're a can-do culture", "we get things done", "we do more
                with less", can be reframed as "we take our time to do things properly", "we think things through before acting", "we focus on doing
                it right first time".
              </li>
            </ul>
            <Typography variant="h6">Perception</Typography>
            <ul>
              <li>
                Consider teaching front line team members 'noticing' skills to improve their ability to see and respond to their own and other's
                hazardous work contexts and teach them to look for SPECIFIC hazards rather than 'safety issues'.
              </li>
              <li>
                Prioritise vehicle operators for this kind of training, given they are most at risk if a hazard slips under their guard and their
                reaction is too slow.
              </li>
              <li>
                Create and use checklists for critical inspections to ensure nothing is missed and develop creative visual messaging that guide safe
                action.
              </li>
              <li>
                Ensure all documentation is designed so that steps in critical processes are clearly mapped out, and important information is obvious.
              </li>
            </ul>
            <Typography variant="h6">Fatigue</Typography>
            <ul>
              <li>
                Review your organisation's Fatigue Risk Management System and policy to determine whether it matches best practice, or at least the
                organisation's appetite for worker risks. If it doesn't, make the necessary changes as soon as possible, and ensure effective
                communication and implementation.
              </li>
              <li>
                If the FRMS does meet best practice, then the implementation of it is either inconsistent or is being de-prioritised in favour of
                production objectives, which should attract an obvious response.
              </li>
              <li>
                Provide knowledge and skills training to employees on how fatigue impacts decision-making and how to better manage issues such as
                shiftwork.
              </li>
              <li>
                Use a reliable and proven technology to assess and monitor fatigue in high-risk team members and build systems to proactively address
                high-exposure individuals and work contexts.
              </li>
            </ul>
            <Typography variant="h6">Memory</Typography>
            <ul>
              <li>Consider whether SOP and critical control documentation is adequately clear, current, and comprehensive.</li>
              <li>
                Make sure that any review includes frontline operators, and any re-working of them factors in 'brain-aligned' language, pictographs,
                is fully tested on the intended audience before being released.
              </li>
              <li>
                Consider opportunities to insert 'cross-checks' into critical task steps that have been identified via other investigations as being
                vulnerable to memory failure.
              </li>
              <li>Design (or re-design) critical control checklists with the assistance of frontline operators.</li>
            </ul>
            <Typography variant="h6">Groupthink</Typography>
            <ul>
              <li>
                Consider elevating this issue at pre-starts and toolbox talks by explaining this natural human behaviour, sharing examples, and
                discussing with the group why the courage to call risk-based concerns out is so critical to the safety of the team.
              </li>
              <li>
                Also address the challenge of constructively receiving feedback for team members, which is an important precursor to willingness of
                colleagues to intervene or call out exposure.
              </li>
              <li>
                Typically, this kind of intervention requires some coaching of supervisors and leaders in the basics of this aspect of behavioural
                theory, and how to discuss this in a constructive way with the team.
              </li>
              <li>
                Institute a recognition program for team members who exercise the courage to call out undesirable exposure - especially relating to a
                colleague's behaviour - being careful to treat this as positive reinforcement all-round, rather than an opportunity to create
                embarrassment or shame.
              </li>
            </ul>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Mistakes</Typography>
            <Typography variant="h6">Decision</Typography>
            <ul>
              <li>
                Consider raising frontline and leader group's awareness of the nature and effect of biases on safe operations and use familiar
                scenarios to bring the concepts to life.
              </li>
              <li>
                Encourage people to act as a 'devil's advocate' and call out when bias appears to be limiting thorough exploration of a problem. This
                applies as much to leaders as it does to frontline team members.
              </li>
              <li>
                Use real data to challenge and reinforce strongly held ideas about 'what is unlikely to happen' based on experience, particularly with
                long-term workers.
              </li>
              <li>
                Given it is much easier to see biases in others than it is in ourselves, establish forums for raising issues and sharing ideas, and
                adapt toolbox talks and pre-starts to surface biases affecting good decision-making. Remember, though, that bias is a natural human
                brain function.
              </li>
            </ul>
            <Typography variant="h6">Planning</Typography>
            <ul>
              <li>
                Involve people with technical expertise in the process of identifying and planning for mitigation of task risks by canvassing opinion
                before the task is performed.
              </li>
              <li>
                Make sure that the planning process encourages and respects dissenting perspectives to ensure that all exposures are identified and
                mitigated appropriately.
              </li>
              <li>
                Implement a process to ensure work plans are reviewed for impacts on other work crews, in terms of physical location and downstream /
                upstream process impacts.{" "}
              </li>
              <li>
                When work is completed (successfully or unsuccessfully), implement a process that identifies the learnings from that task that can be
                used for future work planning..
              </li>
            </ul>
            <Typography variant="h6">Information</Typography>
            <ul>
              <li>Review high-risk task operating procedures to ensure they are current with actual work processes and relevant equipment.</li>
              <li>Assess whether procedural documentation is constructed in a way that is easily absorbed by relevant operators.</li>
              <li>
                Review processes that enable operators to accurately assess environmental, equipment and process-based exposures before tasks are
                performed.
              </li>
              <li>
                Assess whether relevant operators have ready access to important task-related information that helps to fully identify the risks of
                the situation.
              </li>
            </ul>
            <Typography variant="h6">Skill</Typography>
            <ul>
              <li>Review training programs to ensure the content and method of delivery effectively set a platform for skill development.</li>
              <li>
                Reassess the capacity of relatively inexperienced operators to handle high risk tasks without experienced personnel on hand, and
                isolate sub-tasks that can should only be handled by experienced operators.{" "}
              </li>
              <li>
                Address work planning and scheduling processes that allocate operators to tasks to avoid the possibility of inexperience causing a
                critical error.
              </li>
              <li>
                In the event that staffing challenges cause compromised manning decisions to be made, develop more effective processes to mitigate
                those risks by ensuring experience is best matched to critical risk tasks.
              </li>
            </ul>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Operational Factors</Typography>
            <Typography variant="h6">Leader Behaviour</Typography>
            <ol>
              <li>
                <b>Work pressure or urgency has been created by leader communication about targets or KPIs.</b> There is a delicate balance for
                leaders to strike between motivating workers to drive toward production targets at the same time as reinforcing the identification and
                careful management of exposure to risk. In response, ensure leaders have a deep understanding of the impact of their communication to
                direct reports and in written updates to the workforce - particularly in relation to urgency messaging.{" "}
              </li>
              <li>
                <b>Risk-tolerant behaviour that nevertheless achieves production KPIs has been condoned.</b> Some high 'production' workers achieve
                their outputs by ignoring rules and shortcutting critical procedures, creating undesirable risk. When these workers' rule infractions
                are tolerated rather than counseled, it sends a message to all workers that safety rules are unimportant compared with meeting
                production targets. Leaders, and particularly supervisors, need to be reminded of the longer-term negative impacts of this imbalanced
                approach to recognition.
              </li>
              <li>
                <b>Over-reactions to unplanned outcomes have made transparent reporting difficult.</b> Potentially serious events often go unnoticed
                when they aren't reported, and when leaders over-react to single incidents, they actively contribute to non-reporting by
                victim-blaming. It is critical that leaders recognise what a balanced response looks like to unplanned outcomes - acknowledging that
                it is system failure that drives most serious negative events and understanding how to realign systems is where the focus should be
                placed.
              </li>
              <li>
                <b>Failure to take action in the past to resolve hazards has triggered operator cynicism.</b> Hazards that link to critical risk
                situations are the best leading indicator of major failure in the future, so reporting and resolution of hazards is critical to a
                reliable operation. Failure to resolve (or failure to communicate when resolved) hazards will reduce worker motivation to report.
                Solutions can include upgrading accountability of leaders and supervisors to close out hazard-related actions effectively and promptly
                and communicate to affected teams what has been done.
              </li>
              <li>
                <b>Field supervision inadequacies have not been addressed or been overlooked in the past.</b> Most operations suffer to some degree
                from the effects of constraints on training budgets and challenges in recruitment, resulting in a supervisor capability shortfall.
                This situation can render critical risk management processes such as work-planning, pre-starts, and field observation ineffective.
                Known skill and competency gaps should be promptly addressed, and if that isn't the right response, review the suitability of people
                in supervisor roles and change personnel.
              </li>
              <li>
                <b>Normalisation of deviation in expectations of critical risk behaviours has occurred over time.</b> How tasks are performed is very
                likely to evolve over time as workers find more efficient and effective ways to get the job done. Sometimes this means added exposure
                to risk, but unplanned outcomes may fly under the radar, resulting in acceptance of the informal work practice - especially if
                productivity has improved. A careful assessment of the acceptability of adapted practices should be done for high-risk work situations
                to ensure exposure is still acceptable, and formal practices reinstated if that shows unacceptable risk exists.
              </li>
            </ol>
            <Typography variant="h6">Management</Typography>
            <ol>
              <li>
                <b>Relevant tools and equipment were not adequately provided where it is needed.</b> Tools and equipment required for high-risk tasks
                are sometimes not adequately provided where it is needed. Any suggestion this might be the case should result in a process to
                determine whether critical business equipment (PPE, tools etc) is both provisioned AND readily available to operators performing
                critical risk tasks by surveying relevant work crews, and bridge any gaps found.
              </li>
              <li>
                <b>Relevant tools and equipment have not been adequately maintained.</b> Tools and equipment for high-risk work should receive the
                highest priority maintenance. When maintenance issues are identified, seek feedback from experienced operators about the effectiveness
                of maintenance on critical plant & equipment, whether the cycle is adequate, and whether the performance of maintenance addresses all
                issues - and act on feedback with urgency.
              </li>
              <li>
                <b>Rules or procedures were out of date with current plant and work processes.</b> Rules and procedural documentation are difficult to
                keep perfectly up of date with current plant and work processes. Incidents that involve inadequate information should result in a
                review of rules and/or procedures for their currency in relation to actual work processes, and ensure they reflect the perspective of
                job experts who operate regularly in the field and are readily available to all affected operators.
              </li>
              <li>
                <b>Poor-fit safety management systems have been treated as noise that could be tolerated.</b> Safety management system elements (i.e.
                risk assessment, hazard reporting, permitting, incident management, induction, performance reporting etc) are sometimes poor fit to
                the specific operating context they are meant to apply to, but tend to be treated as noise that can be tolerated. Canvass supervisor
                and manager opinion on whether key systems designed effectively and add value to operational risk management rather than just system
                noise. The results of this research can form the basis for change or realignment.
              </li>
              <li>
                <b>Management of change (MOC) processes were inadequate or excluded key stakeholders.</b> Management of change (MOC) processes
                associated with high-risk activities should be well-designed and inclusive. When change processes (work process, equipment design,
                workspace etc) are highlighted as a causal factor, review the processes for their utility (effectiveness and simplicity) and whether
                there is adequate knowledge of them amongst those responsible for implementation.
              </li>
              <li>
                <b>Staffing of workers was insufficient for the relevant critical risk activities.</b> Staffing levels for critical risk activities is
                a major contributor to risk-taking behaviour and incident causality. Review manning practices for critical risk tasks with a wide
                audience to ensure that any concerns or issues with manpower shortage in high-risk work events are managed as a priority over other
                less critical tasks. Juggling of resource allocation is a fact of operational life, but it is critical that the management of
                high-risk tasks is never compromised.
              </li>
            </ol>
            <Typography variant="h6">Supervision</Typography>
            <ol>
              <li>
                <b>Supervisor field presence during critical risk tasks was inadequate.</b> Most operations suffer to some degree from the effects of
                long-term increases to spans of control and the administrative burdens for supervisors, resulting in capacity constraints. This places
                great strain on the effectiveness of field supervision of high-risk work and must be addressed either by reorganising the workload of
                supervisors, counseling them on their priority-setting, reinforcing the field-engagement side of their role, or all of the above.
              </li>
              <li>
                <b>Engagement of frontline workers in problem solving did not happen.</b> The best source of information for risk assessment and how
                to respond to those risks will most likely come from those who perform the task most regularly. When assessing risks inherent in a
                work plan or reviewing a task already underway against an unexpected situation, supervisors should canvass the opinions of experienced
                team members. These positive supervisor behaviours need to be encouraged and mentored by their immediate manager, and the need to
                always be the 'expert' must be contained in order for crewmembers to be able to step up with solutions to work challenges.
              </li>
              <li>
                <b>Poor performance management of team members resulted in misaligned behaviour.</b> Supervisors can be reluctant to call out and
                address misaligned behaviour, especially by their more experienced workers, and often when that supervisor was more recently a
                crewmember. Constructive performance management is a skill that needs to be learnt and requires the courage of conviction, borne from
                a deep awareness of how badly work can go wrong. Training in the mechanics and behaviours of performance management is one way to
                address this, but it is also critical that supervisors are closely mentored and supported by their managers to have what are often
                hard conversations with their best people.
              </li>
              <li>
                <b>Excessive risk tolerance crept into operational or task-handling decisions.</b> It must be remembered that drift in practices is a
                normal human tendency and must be counteracted by diligent oversight from managers and safety personnel. When supervision becomes lax
                or blind eyes are turned to risky work practices, the work may still be successful, and the increased exposure over 'normal' work may
                slowly become the 'new normal' if not properly supervised and debriefed. Shifts in work practices as a result of inadequate
                supervision should be called out and firmly addressed when it is noticed or highlighted by an incident investigation.{" "}
              </li>
              <li>
                <b>Rules relating to critical procedures and equipment handling were poorly understood.</b> While this is potentially an induction or
                training issue, the primary responsibility for confirming worker knowledge, competence, and confidence prior to commencing high risk
                tasks sits with the immediate supervisor. System issues aside (and there is no doubt in this situation there will be many), workers
                should not be allocated responsibilities they are not ready for, unless they are under tightly mentored control by either supervisor
                or more experienced worker. This situation calls for counseling and/or training of relevant supervisors, as well as a review of the
                accessibility of procedural documentation (see Safety Management System).
              </li>
              <li>
                <b>Planning was inadequate or absent for critical risk tasks or work orders.</b> Inadequate planning (particularly risk assessment) is
                one of the most frequent casual factors in serious incidents. Job planning as a whole is rarely the exclusive domain of the
                supervisor, but the risk assessment part of job planning is more usually the responsibility of supervisors, and failure to envision
                how key hazards should be managed is a critical breakdown. This aspect of job planning requires experience and collaboration with job
                experts, so there is a competence factor (planning skills) that needs to be addressed, and a behavioural element (collaboration) that
                needs to be coached.
              </li>
            </ol>
            <Typography variant="h6">Individual</Typography>
            <ol>
              <li>
                <b>Relevant rules don't make sense to experienced frontline workers.</b> While some procedures may be out of date or unsuitable for
                local circumstances, this situation is more driven by individual workers who have adapted to their 'own way of doing things' over
                time, and they have rejected what is most likely a 'good rule' or procedure. This behaviour can have a detrimental influence on less
                experienced worker colleagues who don't have the experience to get away with their 'own way of doing things'. Counseling on the risk
                of these effects should be considered to ensure consistency of behaviour when performing high-risk work.
              </li>
              <li>
                <b>Perceived +ives of breaking the rules outweighed the perceived -ives.</b> When workers make a judgement that short cuts or
                'workarounds' are desirable from a personal perspective (i.e. makes job easier for them rather than any feeling of imposed urgency)
                but haven't adequately weighed the risk of excessive exposure to harm, they should be counseled and/or performance managed to ensure
                they fully understand the risks they have exposed themselves and others to, and why the rules are critical to follow. Repeated
                behaviours would require a firmer response.
              </li>
              <li>
                <b>Rules were seen as too strict or unnecessary and got in the way of task completion.</b> Rules may be challenged and/or ignored by
                workers for several reasons, and in some circumstances that may be a valid response - especially when the situation or physical
                context does not suit the defined procedure, or it is difficult to implement controls as they are defined. When these special
                circumstances do not exist, and it is just the worker's preference in the moment, there should be counseling of that worker to
                reinforce the benefits and the requirement for implementing controls as they are defined. Repeated workarounds or deviations become
                the basis for more formal performance management.
              </li>
              <li>
                <b>A high value was placed on timesaving to get the job done quickly.</b> There will always be some element of urgency driven by
                schedule and targets in any production environment. Some of it may be caused by blunt leader messaging, but the desire to save time is
                also a hard-wired human predisposition, and not necessarily connected to any real pressure from operational leadership. This natural
                tendency, when identified, requires a response that reinforces the message that high-risk work should never be hurried, regardless of
                the desire to just 'get the job done'. This reinforcement is best handled by immediate supervisors and if necessary, their managers as
                well.
              </li>
              <li>
                <b>Lifestyle-driven fatigue affected the capacity of worker(s) to perform safely.</b> When fatigue is an obvious or provable component
                of a serious incident, it should first be explored for organisational influences (i.e. work schedule, inadequate breaks, production
                pressure, etc) before assigning fatigue as 'lifestyle-driven'. When those causes are exhausted, however, the worker must take
                accountability for their 'fitness for work' if they have a high level of pre-existing fatigue before their shift commences. Solutions
                beyond worker counseling involve fatigue testing at shift commencement, and reinforcement to supervisors of the dangers of fatigue and
                how to recognise fatigue in their crew.
              </li>
              <li>
                <b>Excessive self-confidence in operator ability led to completing tasks without required controls.</b> Risk-taking behaviour may be
                influenced by an unrealistic level of self-confidence that is not matched by either experience or actual capability. These behaviours
                may also be influenced by a more general 'go hard or go home' mindset that isn't just confined to the stereotype of younger male
                workers. Whatever the specific context for these behaviours, they need to be firmly counseled with warnings in place, and any repeat
                of those behaviours would attract termination. Moreover, an incident that has this category of causal factor is a signal that
                recruitment processes may not have adequate screening (see HR Management).
              </li>
            </ol>
            <Typography variant="h6">Environment</Typography>
            <ol>
              <li>
                <b>Challenging local operating conditions made compliance difficult or impossible.</b> Physical space, structural design, and other
                less controllable factors may increase exposure to levels that are difficult to manage for operators, despite the implementation of
                procedural controls. It is difficult to design controls to suit every potential operating environment that an organisation may have
                throughout its business activities. This means that workarounds are often necessary, and it is important that managers and supervisors
                are vigilant to how well those necessary altered practices control exposure to risk. It means avoiding compliance for compliance' sake
                and being prepared to sensibly examine how a task can be best performed given the specific circumstance that is encountered.
              </li>
              <li>
                <b>Poor housekeeping of the work environment created a hazardous environment.</b> There is a suggestion by many that one can predict
                incident likelihood by examining the quality of housekeeping, given it may be a good indicator of worker attitudes to rules and
                control requirements. Where an incident is partly contributed to by the work environment being untidy, equipment out of place, or a
                more chaotic operating situation, this can and should be addressed in the short term by immediate rectification. The longer-term
                solution, however, is to explore and address the attitudes that allowed the state of housekeeping to deteriorate in the first place.
                Reinforcing the need for diligent management of the work environment, has the potential for that focus to generalise across behaviours
                associated with implementing work controls.
              </li>
              <li>
                <b>Ambient conditions - too hot, cold, humid, wet, dry - contributed to compliance challenges.</b> While this is not confined to
                outside work, these situations are less controllable when operators are outside the physical boundaries of the business, and risks are
                numerous in type and severity. Temperature extremes present their own obvious direct hazards to humans, but there are also indirect
                impacts, such as the degraded ability to make good decisions in the face of unexpected exposure. Where a worker fails to implement
                critical controls due to these circumstances, it is clear evidence that too much onus has been placed on the worker to self-manage in
                unacceptably trying circumstances. The solution is to rethink work allocations and controls for temperature extremes, in order to
                reduce exposure in those circumstances.
              </li>
            </ol>
            <Typography variant="h6">Team Function</Typography>
            <ol>
              <li>
                <b>Conflicts between local teams occurred on the same shift or in shift handovers.</b> Where different crews operate in the same
                production environment or handle the same process over different shifts, communication and collaboration about issues and concerns
                between different teams is critical to smooth operations. When this doesn't occur, exposure to risk tends to increase for all
                concerned. Teamwork between crews who overlap in their activities should be a priority for supervisors and their managers, setting up
                open dialogue and reporting of manpower, process, equipment, and environmental issues that may be relevant to effectively managing
                exposure. When teamwork issues are identified, leaders should engage different teams in combined problem-solving sessions where any
                differences can be surfaced and addressed.
              </li>
              <li>
                <b>Social pressure existed to maintain consistency with historical practices even when risk increased.</b> Supervisors and managers
                benefit from an understanding of the psychology of groups and how they are more likely to hold on to practices they are comfortable
                with, even in the face of unexpected exposure situations. This is a natural human phenomenon and must be actively counteracted by
                reinforcing the need for team members to put aside their biases and/or comfort and examine a situation with fresh eyes. Supervisors,
                in particular, must be alert to the team's tendency to continue a process that has become unsuitable, or revert to a 'tried and true'
                practice that doesn't fit the risk profile of the task. One way of addressing this is to encourage the team to practice challenging
                themselves and making that a more natural thing to do.
              </li>
              <li>
                <b>Team cohesion was questionable where contractors work alongside employees.</b> Work in a modern production environment often
                involves employees and contractors operating side by side on the same task, or handling tasks in close proximity. The employee may be
                a supervisor or a collaborator. Regardless of level, the difference in status can often result in behaviours that aren't conducive to
                effective identification of risks or task problem-solving. Examples might include overuse of authority, lack of respect for technical
                expertise, and so on. It is critical that high-risk tasks (in particular) involve equality of recognition of technical capability and
                contribution to managing risks, regardless of employee / contractor status. Leaders have a significant role to play in ensuring this.
              </li>
            </ol>
          </Paper>

          <Paper sx={{ p: 3, m: 3 }}>
            <Typography variant="h5">Organisational Factors</Typography>
            <Typography variant="h6">Plant & Equipment</Typography>
            <ol>
              <li>
                <b>Safety is not a primary consideration at the design phase of work, plant, equipment, environment, and processes.</b> Safe design
                means the integration of control measures early in the design process to eliminate or, if this is not reasonably practicable, minimise
                risks to health and safety throughout the lifecycle of the product/process/plant being designed. Buildings, structures, machinery and
                equipment, tools and vehicles all need safe design to protect the people who use them from getting sick or injured, and safe design
                starts at the concept phase. Incidents with latent causes that can be confidently associated with a design failure, point to the need
                to review processes that ensure risks are accurately recognised and appropriately respond to at the stage of design. The current
                incident has already uncovered the need for redesign, but it is equally important that the system for managing the risk of this
                eventuality (safety in design) is reviewed and improved before any significant future projects are considered.
              </li>
              <li>
                <b>Plant & equipment subject to periodic maintenance is not thoroughly operational or fit for purpose.</b> Maintenance on key plant
                and equipment may be performed by company employees or contractors or both, but regardless of those responsible, the key questions
                needing to be asked are:
                <ul>
                  <li>Is emergency maintenance completed within agreed response times?</li>
                  <li>Is corrective maintenance performed within agreed response times?</li>
                  <li>Is preventative maintenance performed per agreed cycles?</li>
                  <li>Does maintenance meet agreed quality criteria?</li>
                </ul>
              </li>
              <li>
                <b>Plant & equipment are procured on the basis of lowest cost rather than lowest risk for required capacity.</b> While procurement
                people are likely to have performance criteria that includes cost savings, this should be balanced carefully against the opinion and
                feedback from operational experts. When adequate consultation doesn't occur, the balance is very likely to weight more heavily toward
                cost rather than field risk exposure. The key to resolving this imbalance is to renegotiate the process of decision-making and review
                the principles that guide key plant and equipment purchases to make sure that decisions are informed by field risks and past incident
                / reliability history.
              </li>
              <li>
                <b>Tools, equipment, and plant/machinery aren't readily available where they are required throughout operations.</b> The key questions
                here centre on a) does the requisite equipment exist but simply doesn't make it into the hands of those who need it, and b) is there
                inadequate supply of relevant equipment to the frontline due to procurement challenges. In this, we have either an internal supply
                chain issue or an external supply chain issue. In some instances, the problem may lie with inadequate operational leadership
                oversight, and in these cases, it is a leadership failure. The response should include careful analysis of where the root of the
                problem lies, and immediately address even if it requires a temporary budget-relief measure.
              </li>
              <li>
                <b>Signals of plant, tool, or equipment failure fail to be noticed or responded to with review and change management.</b> Incidents
                involving equipment breakdown should result in some form of review, particularly if there are repeated instances of failure. This kind
                of review is best performed by technical experts close to frontline operations, or by experienced operators that have solid technical
                knowledge. From a review, you should expect to have answers that point to why failures are occurring at a 'system' level, rather than
                merely at the level of operator-use. You should also expect to see answers to why 'management of change' failures may have contributed
                to those failures, ie when equipment is first procured and installed or distributed, were users properly trained in its use, and were
                impacts on surrounding work processes properly analysed and accounted for. Moreover, a final question to be resolved is why critical
                points of failure have not been observed and responded to in the past - how the system of reporting and response is failing to address
                those issues.
              </li>
            </ol>
            <Typography variant="h6">HR Management</Typography>
            <ol>
              <li>
                <b>Operational goals/targets aren't adequately balanced between risk and production performance.</b> For the purpose of motivating
                appropriate focus on safety outcomes (individually and team), most organisations tend to use 'whole of business' aggregate lag metrics
                such as LTI targets and allocate a modest percentage toward overall recognition and/or reward - depending on job level. This approach,
                while prevalent, tends to focus people on safety as a secondary and largely reactive concern. 'Safe production' should be the
                overarching theme, which means embedding recognition for production target achievement with process and people reliability metrics.
                These should be integrated as much as possible and be focused on what each individual has the capacity to directly influence.
              </li>
              <li>
                <b>Operator knowledge and skills are not fully adequate for the task required to be performed.</b> All organisations must balance task
                allocation with worker competence and accept that workers are at different phases of development and competence. Where critical risk
                tasks are required to be performed, only operators with adequate knowledge should take lead roles with strict requirements for guiding
                and orienting new or less experienced workers, and tight competence assessment requirements before they are allowed to lead critical
                risk tasks themselves.
              </li>
              <li>
                <b>Supervisor skills and knowledge are insufficient to perform effective management of high-risk work.</b> In many ways, this is even
                more critical than for frontline workers. Supervisors may lack adequate skill or knowledge in both technical and/or leadership tasks,
                and this represents a major elevation of risk to others and to the organisation. Any evidence that a supervisor may be inadequate in
                these areas should result in performance development or redeployment if they don't develop requisite skills or knowledge.
              </li>
              <li>
                <b>Supervision is inadequately resourced to perform in-field critical risk task oversight.</b> Some supervisors may have a challenge
                in balancing their areas of day-to-day focus, but when supervisor resourcing is simply inadequate, management must address this
                concern as a priority regardless of budgetary constraints in the short term. Temporary resourcing inadequacies have a habit of
                becoming hard-baked in the structure, so in the longer term, a red flag must be raised where incident investigations bear this out as
                a significant causal factor.
              </li>
              <li>
                <b>Work planning and resourcing are not designed to enable operators to perform tasks at a safe pace.</b> Once frontline resourcing
                challenges are adequately validated (and validation IS necessary), it must be understood what elevations in exposure are happening as
                a result, and an honest assessment of risk vs reward be carried out. Restructuring or resource reduction can occur as a 'drift' issue,
                where it slowly creeps up on leadership with increased injury or incident rates, but a reluctance to accept that resourcing is THE
                problem. Management and supervisors must be well-oriented to a frank conversation about how much risk are they really prepared to wear
                in day-to-day operation and respond accordingly with resource allocation.
              </li>
              <li>
                <b>Critical risk tasks are not always allocated to competent employees or contractors with proven systems of safe work.</b> See #2 and
                #3 as this is a similar theme, except in the case of contractors where the response to this issue may require an indirect approach via
                contract managers, and an increase in field oversight of contractor work.
              </li>
              <li>
                <b>Communication tends to be top-down and does not facilitate timely awareness of critical risk management.</b> One of the best
                examples of this is a failure to proactively enquire about how critical risks are being managed in the field, to learn about issues
                such as equipment, scheduling, resourcing, customer demand etc. Rather than doing this, many central leadership functions rely on lag
                metrics and incident reports to inform them of critical risk management concerns. Active seeking of worker opinion and the use of
                high-quality lead metrics to guide where and how to engage in the field, is what is required to address this.
              </li>
            </ol>
            <Typography variant="h6">Safety Management System</Typography>
            <ol>
              <li>
                <b>Operational safety risks are only partially assessed and treated, and/or unverified in the field.</b> The only reliable way to
                assess and treat field risks is to observe and report - consistently and accurately - on how work is performed within the envelope of
                critical control implementation. This is called verification of critical controls and includes healthy and open dialogue about the
                true nature of risks in the field, and how adequate procedural requirements are matched with equipment and schedule.
              </li>
              <li>
                <b>Safety-related policies are not fully in alignment with known critical risks and/or are poorly communicated.</b> An example of this
                might be when we say: 'workers will participate in safety training', but operational leadership are not able to release their people
                to participate, or requisite training has only been designed for some levels of employee. Policies operate as guideposts for the
                business, and when they are not understood properly, or don't make sense to those they should affect, then addressing that
                misalignment is critical for the organisation and leadership's credibility and proper functioning.
              </li>
              <li>
                <b>Oversight of safety is poorly informed, with the true state of risk management hidden from senior leaders.</b> When leaders are
                absent from the field for long enough, they will only tend to assess work 'as imagined' rather than work 'as done', with all the
                issues that gap creates. In this situation, leaders tend to over-react to incidents, and under-react to weak and no-so-weak signals of
                failure. Of course, it is the case that senior leaders have little opportunity to visit the field of operations, so their systems of
                data gathering must be proactive and reliable, and there must be healthy suspicion about metrics and dashboard reporting, and constant
                challenge of how the business is responding to known risks.
              </li>
              <li>
                <b>Procedural documentation is out of date and/or difficult to absorb and understand by operators.</b> When procedural documentation
                is not regularly updated, it becomes prone to inaccuracies. Processes, tools, or systems may have changed since the documentation was
                created, rendering the instructions outdated and potentially misleading. This can result in operators making errors, wasting time, or
                even causing accidents. Outdated or complex procedural documentation can hinder operators' ability to perform tasks efficiently. They
                may struggle to find the information they need or spend unnecessary time deciphering unclear instructions. As a result, productivity
                can suffer, leading to delays, inefficiencies, and frustration among operators. The response to this situation is to develop a better
                system to periodically assess procedural accuracy and ensure that a regular audit meets a reasonable level of accuracy.
              </li>
              <li>
                <b>Risk controls are not reviewed and/or periodically audited for protective effectiveness and compliance.</b> Risk controls are put
                in place to mitigate or manage identified risks within an organisation. However, without periodic reviews and audits, there is no
                assurance that these controls are still effective in addressing the risks they were designed to mitigate. As circumstances change, new
                risks emerge, or existing risks evolve, the effectiveness of controls can diminish. This can leave the organisation vulnerable to
                potential threats and increase the likelihood of incidents or losses. Annual bow-tie analysis or a similar process carried out by job
                experts can mitigate these risks. Performing a meta-analysis of incidents where critical controls have failed or been absent is
                another way to address this issue.
              </li>
              <li>
                <b>The work environment (including fixed or temporary structures) is not set up appropriately for safe operation.</b> Conduct a
                thorough assessment: Begin by conducting a comprehensive assessment of the work environment, including fixed and temporary structures.
                This assessment should identify potential hazards, such as inadequate lighting, unsafe flooring, improper ventilation, ergonomic
                issues, and any other risks that may exist. Engage with experts, such as safety professionals or consultants, if needed. Engage
                employees in the process by encouraging them to report any safety concerns or hazards they encounter. They are often the ones directly
                experiencing the work environment and can provide valuable insights. Establish a culture of safety and open communication, ensuring
                that employees feel empowered to voice their concerns without fear of reprisal.
              </li>
              <li>
                <b>Comprehensive plans are not in place to respond to emergencies and/or capability to respond is inadequate.</b> Begin by identifying
                the potential emergencies that could occur in your organization. Consider natural disasters, technological failures, security
                incidents, medical emergencies, or any other events that could disrupt normal operations or pose a risk to employees, customers, or
                assets. Assess the likelihood and impact of each identified emergency scenario. Evaluate the vulnerabilities, critical assets, and
                potential consequences associated with each event. This will help you prioritize and allocate resources effectively. Form an emergency
                response team comprising individuals from various departments or disciplines within your organization. This team should include
                representatives from management, operations, safety, security, human resources, and IT, among others. Define their roles and
                responsibilities clearly. Create detailed procedures for responding to each type of emergency identified. These procedures should
                include steps to mitigate, respond to, and recover from the emergency. Consider evacuation procedures, communication protocols,
                emergency contacts, medical response plans, and any specific actions needed for each scenario.
              </li>
            </ol>
            <Typography variant="h6">External Management</Typography>
            <ol>
              <li>
                <b>Contracting out work is not preceded by validation of contractor safety management system and practices.</b> Validating a
                contractor's safety management system and practices is crucial to ensure that they meet the required safety standards and can
                effectively manage risks in their work. Request relevant documentation from the contractor, such as their safety manual, safety
                policies, procedures, training records, incident reports, and certifications. Review these documents to assess the comprehensiveness
                and alignment with industry standards and regulations. Evaluate the contractor's safety performance by analyzing their safety
                statistics, incident rates, and near-miss reports. Assess their track record for incidents, accidents, and their responsiveness in
                implementing corrective actions. This information can provide insights into their commitment to safety and their ability to manage
                risks effectively.
              </li>
              <li>
                <b>Work performed by contractors is not adequately validated in the field to ensure meeting of safety obligations.</b> Conduct site
                visits to assess the contractor's work environment, practices, and safety culture firsthand. Observe the implementation of safety
                measures, the condition of equipment and machinery, and the adherence to safety protocols. Look for potential hazards and evaluate the
                effectiveness of their safety controls. Ensure that the contractor complies with all relevant safety regulations and legal
                requirements. Verify that they have the necessary permits, licenses, and certifications required for their operations. Check if their
                safety practices align with industry standards and best practices.
              </li>
              <li>
                <b>The choice of external suppliers is not influenced by their impact on the safety of operations.</b> Develop clear and comprehensive
                safety evaluation criteria that suppliers must meet. These criteria should align with your organization's safety standards and
                objectives. Consider factors such as the supplier's safety record, safety management systems, safety training programs, compliance
                with safety regulations, and commitment to continuous improvement in safety performance. Implement a supplier qualification process
                that includes safety as a key evaluation factor. Require potential suppliers to provide relevant safety documentation, such as safety
                policies, safety records, incident history, safety certifications, and safety management system documentation.{" "}
              </li>
              <li>
                <b>Supplier goods and services critical to operational reliability are rarely reviewed for stability and quality.</b> Identify the
                suppliers whose goods or services are crucial for operational reliability. These may include suppliers of key components, equipment,
                raw materials, or critical services. Prioritize suppliers based on their impact on operational stability and reliability. Define
                performance criteria that reflect stability and quality requirements for the goods and services provided by critical suppliers. These
                criteria should align with your organisation's reliability objectives and operational needs. Consider factors such as product/service
                specifications, reliability, durability, consistency, and compliance with industry standards.
              </li>
              <li>
                <b>Regulatory requirements are considered as the targeted standard for high-risk work, rather than the minimum.</b> Stay updated on
                relevant regulations and standards applicable to high-risk work. Monitor changes in legislation, industry guidelines, and best
                practices to ensure you have the latest information regarding safety requirements. Conduct a thorough analysis to identify any gaps
                between your current safety practices and the regulatory requirements. Compare your existing processes, procedures, and controls
                against the specific requirements outlined in the regulations. This analysis will help you identify areas that need improvement to
                meet or exceed the regulatory standards.
              </li>
            </ol>
          </Paper>
        </Box>
  );
}
