import React from "react";
//import { useRecoilState } from "recoil";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//import helpState from "./helpState";

export default function HelpPageAnalysisErrorTypes(props) {

  //const [help, setHelp] = useRecoilState(helpState);

  const scrollToSection = (section) => {
    var sectionEl = document.getElementById(section);
    if(sectionEl) sectionEl.scrollIntoView(true);
  }

 
  return (
        <Box>
            <Typography className="sticky-head" variant="h6" sx={{position:"sticky", top: 0}}>Human Errors</Typography>
            <Typography>
              Incidents frequently tend to involve human error somewhere in the system of work - from the frontline in-the-moment worker decision, to
              the very top of decision making in an organisation.
            </Typography>
            <Typography>There are two approaches to the problem of human fallibility:</Typography>
            <ol>
              <li>The person approach focuses on the errors of individuals, blaming them for forgetfulness, inattention, or moral weakness</li>
              <li>
                The system approach concentrates on the conditions under which individuals work and try to build defenses to avert errors or mitigate
                their effects
              </li>
            </ol>
            <Typography>
              Neither of the two statements above apply perfectly - and BOTH are almost always relevant in some way to situations where work does not
              go as planned.{" "}
            </Typography>
            <Typography>
              Incidents happen when barriers to unsafe work (controls) break down and fail to protect the worker. Of course, the worker has a role to
              play in this, but behaviours and results are influenced by a variety of factors beyond the control of front-line workers.
            </Typography>
            <Typography variant="h6">Worker intention</Typography>
            <Typography>
              When a critical control is either absent or ineffective, we sometimes call this a violation or a 'workaround'. For many reasons, this is
              often misinterpreted language, and can lead to a 'blame the worker' response. In the vast majority of incident situations, that is not a
              valid response, because the worker may have had to compensate for a situation that didn't allow for easy implementation of controls. The
              fact that the work has not gone to 'plan' will be down to a range of factors, and this is why we prefer to talk about 'workarounds'.
            </Typography>
            <Typography>
              Non-observance of critical rules or procedures can occur in different ways and for different reasons, with two broad categories outlined
              in research on human error:
            </Typography>
            <ol>
              <li>
                <b>Unintentional</b> Error result from unplanned human error, where there is a mis-read of the work context, and a critical control(s)
                is not fully implemented. These are usually the result of a cognitive failure of some kind, and are termed Slips, Lapses, or Mistakes.
                In more general terms, the worker meant to perform the task in the 'right' way, but something happens in the workers thinking process
                that results in an unintentional error.
              </li>
              <li>
                <b>Intentional</b> Workarounds occur from a deliberate intention to operate outside the 'rules'. They are almost always positively
                intended and result from unusual operating contexts or a longer-term cultural drift away from the rules. Note five conditions that
                tend to facilitate intentional workarounds:
                <ul>
                  <li>Expectation that rules have to be broken.</li>
                  <li>Sense of competence to work around procedures.</li>
                  <li>Existence of the opportunity to break a rule.</li>
                  <li>Inadequate work planning.</li>
                  <li>Work environment unsuited to rules.</li>
                </ul>
              </li>
            </ol>
            <Box id="Slip or Lapse"></Box>
            <Typography  className="sticky-head" variant="h6" sx={{position:"sticky", top: 40, bottom: 200}}  onClick={() => scrollToSection("Slip or Lapse")}>Slip or Lapse</Typography>
            <Typography>
              <i>"Right intended action gets executed in the wrong way"</i>
            </Typography>
            <Typography>
              These are actions that didn't go as planned during a task that is likely to be familiar to the worker (ie changing down to into 3rd gear
              when you correctly intended to change up to 5th). <i>"Whoops, that's not what I meant to do!"</i> is often the response to a slip, or
              you might hear: <i>"I lost my place midway through the task"</i>, which would indicate a lapse.
            </Typography>
            <Typography>Examples of Slips:</Typography>
            <ul>
              <li>Using too much strength and over-torquing a bolt.</li>
              <li>An elevated work platform operator pushing the joystick to the left instead of the right.</li>
              <li>Selecting the wrong size nail for the job.</li>
              <li>Checking a dial but for the wrong value.</li>
            </ul>
            <Typography>Examples of Lapses:</Typography>
            <ul>
              <li>Forgetting to nail down a joist.</li>
              <li>Taking a mask off to talk to a colleague and then forgetting to put it back on.</li>
              <li>Failing to secure scaffolding because of an interruption.</li>
              <li>Forgetting to remove a wall radiator before removing the wallpaper.</li>
            </ul>
            <Typography variant="h6">Types of Slips or Lapses:</Typography>
            <ul>
              <li>
                <b>Auto-pilot:</b> In 2011, Daniel Kahneman brought our attention to the concept of thinking fast and thinking slow, and during the
                last decade these ideas grounded in neuroscience have an important application in workplace safety. Slow thinking is the conscious,
                deliberate consideration of a situation or problem, and is critical to noticing and resolving challenges in workplace exposure. Fast
                thinking is the automatic, habitual, non-deliberate and largely unconscious form of thinking (hence auto-pilot) that allows us to
                perform routine tasks quite efficiently - especially when the task environment is highly predictable. A key workplace challenge is
                differentiating between when being in 'auto-pilot' is ok, and when it's not ok, especially when performing a critical risk task.
              </li>
              <li>
                <b>Pressure:</b> Pressure most often manifests in team members and how they manage critical-risk tasks when operational leaders and
                supervisors react poorly to process upsets, missed deadlines, or some form of operational crisis. Poor decision quality and excessive
                risk tolerance by workers is almost guaranteed to follow. Reactions to pressure that involve a sense of urgency and stress are
                negatively affected by fatigue, so when these cognitive factors are identified as present in a given incident, the importance of
                addressing it is significantly increased.
              </li>
              <li>
                <b>Perception:</b> The human visual system operates in a way that is unlike a video camera and involves the brain deciding what it
                will pay attention to, and then - to some extent - making up a story that fits the model of the world that our brains have created
                from past experience. This means we can't completely trust what we think we see - especially in situations where the unexpected occurs
                or where information doesn't seem to 'fit' our current model of the world. This has significant implications for team members noticing
                situations or actions that create undesirable exposure or hazards. A normal brain doesn't naturally know how to notice deeply and
                broadly, but it can be trained to do so.
              </li>
              <li>
                <b>Fatigue:</b> Research on quality sleep loss and excessive work intervals has shown that risk for error multiplies dramatically
                (geometric rather than linear). There is also a proven relationship between cognitive fatigue and reduced decision-making
                effectiveness, self-centeredness, visual acuity, and the likelihood of greater risk-taking to meet a perceived deadline. Broadly
                speaking, an excessive level of cognitive fatigue acts to increase the risks of all the other cognitive hazards that contribute to
                human error.
              </li>
              <li>
                <b>Memory:</b> When the implementation of critical procedures and controls rely on experience or accurate memory, human error is
                likely to become a factor. One important way of addressing this is to provide the workforce with clear, concise, and easily followed
                procedures that align with the mechanics of how the brain works. Poorly written, ambiguous, or out-of-date standard operating
                procedures lead team members to misinterpret what is needed to operate with minimum risk.
              </li>
              <li>
                <b>Groupthink:</b> All operations will see new or unexpected situations arise that require team members to notice increasing exposure
                and elevated risk and call that out before it becomes critical. This positive team member behaviour is somewhat unnatural in the face
                of the competing desire to "go along to get along" with the group, and must be actively reinforced as not only OK, but necessary for
                the team to maintain a safe operating environment.
              </li>
            </ul>
            <Typography  className="sticky-head" variant="h6" sx={{position:"sticky", top: 80, bottom: 160}}>Mistakes</Typography>
            <Typography>
              <i>"Wrong intended action gets executed in the right way"</i>
            </Typography>{" "}
            <Typography>
              Mistakes are generally errors of judgement or decision-making where the worker has done the wrong thing believing it to be right. These
              situations may involve misapplication of a good rule, application of a bad rule, or simply a lack of requisite knowledge or skill to
              perform the task. You might hear an operator say: <i>"That should have worked - it has every other time"</i>.
            </Typography>
            <Typography>Examples of Mistakes include:</Typography>
            <ul>
              <li>Leaving insufficient room when overtaking in the face of oncoming traffic.</li>
              <li>Misinterpreting the sound of a machine breakdown and failing to switch off.</li>
              <li>Choosing the wrong anchor point for a lanyard to connect to.</li>
              <li>Selecting the wrong vehicle for the conditions.</li>
            </ul>
            <Typography variant="h6">Types of Mistakes:</Typography>
            <ul>
              <li>
                <b>Decision:</b> Poor decision making is very often a product of cognitive bias that leads to flawed judgement about future
                probabilities. Workers and their leaders are often faced with too many choices to process when making a decision, and the brain uses
                'shortcuts' based on what has happened and worked before. This is a necessary and useful thought process, but sometimes results in
                error of judgement. The use of too little information; favouring information that supports preconceptions; being influenced by where
                and who the information is coming from (the halo effect); favouring information from your closest colleagues rather than elsewhere,
                are just a few of the types of bias that affect decision-making on whether to, or how to manage exposure.
              </li>
              <li>
                <b>Planning:</b> The simplest indication of a planning failure is where the job planner fails to identify all the risks of the task,
                which will inevitably result in increased exposure beyond what is acceptable and run the risk of an unplanned or negative outcome.
                This can happen in the field where the frontline worker or supervisor moves to task too quickly before properly analysing the risks.
                The risks should also consider work performed prior (upstream), work to be performed as a result of the current task, and adjoining
                work that may have an effect on the current task.
              </li>
              <li>
                <b>Information:</b> There are at least three indicators of information failures: when key aspects of the work context are unknown to
                the worker when they are planning how to manage it; when technical documentation for the task are unavailable, inaccurate or out of
                date; and when the worker simply fails to access available information to fully identify the risks of the situation.
              </li>
              <li>
                <b>Skill:</b> When the worker is handicapped by a lack of competence, knowledge or experience, their ability to complete a task that
                involves a potentially hazardous piece of equipment, or a task that has inherently difficult procedural requirements, is severely
                compromised. Moreover, unexpected hazards that emerge in the field that were not planned for, are more likely to be unnoticed or
                responded to in a way that puts them at greater risk.
              </li>
            </ul>
            <Typography  className="sticky-head" variant="h6" sx={{position:"sticky", top: 120, bottom: 120}}>Routine Violation</Typography>
            <Typography>
              A routine violation is a deliberate short-cut or deviation from the rule or procedure that has become - over time - the accepted way of
              operating. It is very rarely a case of willful rule-breaking, and usually results from an intention to get the job done despite the
              consequences. They almost always involve the intentional failure to apply a 'good rule' in favour of saving time or reducing effort and
              will generally become accepted within a work group or across a site operation. You might hear an operator say:{" "}
              <i>"It's just the way we've tended to do that around here"</i>.{" "}
            </Typography>
            <Typography>Examples of Routine Violations include:</Typography>
            <ul>
              <li>Scaffolders not clipping on their harnesses above 4 m.</li>
              <li>Truck drivers not wearing seat belts.</li>
              <li>Elevated platform operators not carrying out pre-use checks.</li>
              <li>A site manager allowing untrained drivers to operate plant.</li>
            </ul>
            <Typography  className="sticky-head" variant="h6" sx={{position:"sticky", top: 160, bottom: 80}}>Exceptional Violation</Typography>
            <Typography>
              An exceptional violation is a deliberate short-cut or deviation from the rule or procedure due to one-off or unusual operating condition
              where the established control is not suitable for managing the task risk, and a different solution must be created to control exposure.
              These situations are inherently difficult to comply with the established rules and require some operator / supervisor creativity that
              should be driven by high-quality risk assessment and careful design of ad hoc controls. You might hear an operator say:{" "}
              <i>"We didn't have any choice and given the circumstances it was the best thing we could do"</i>.{" "}
            </Typography>
            <Typography>Examples of Exceptional Violations include:</Typography>
            <ul>
              <li>Compromise exclusion zones because two trucks arrive for loading simultaneously.</li>
              <li>Increasing manual handling requirements to compensate for a machine breakdown.</li>
              <li>An isolation requirement not covered by procedure requiring a one-off approach.</li>
              <li>A one-off work at height breach to manage an EWP failure. </li>
            </ul>
            <Typography  className="sticky-head" variant="h6" sx={{position:"sticky", top: 200, bottom: 40}}>Situational Violation</Typography>
            <Typography>
              A deliberate short-cut or deviation from the rule or procedure because of the established operating context (work environment, equipment
              availability, available resources including people) that renders the rule or procedure difficult or impossible to implement - sometimes
              known as a 'bad rule'. These situations are typically the result of long-term unsuitability of established controls and tend to result
              in procedural adaptations over time that often carry elevated risk. You might hear an operator say:{" "}
              <i>"It's the only way we can get this task done, given the way the site is set up and the people we have to do it"</i>.{" "}
            </Typography>
            <Typography>Examples of Situational Violations include:</Typography>
            <ul>
              <li>The delivery dock space is too small to allow for mandated pedestrian exclusion zones.</li>
              <li>Having an outdated processing plant means working with riskier procedures.</li>
              <li>The working environment is too cold for mandated PPE so different products are used.</li>
              <li>The journey management policy doesn't integrate and work with shift structure policy.</li>
            </ul>
            <Typography  className="sticky-head" variant="h6" sx={{position:"sticky", bottom: 0}}>Act of Sabotage</Typography>
            <Typography>
              A deliberate and malicious act with the intention to harm the organisation and/or its people. These are extremely rare, and although
              possible, they are very unlikely to be the case. The question we should ask:{" "}
              <i>"Was this violation likely to have been motivated by a person with malicious intent to damage the organisation in some way?"</i>
            </Typography>

        </Box>
  );
}
