import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ApprovalIcon from "@mui/icons-material/VerifiedOutlined";
import Divider from "@mui/material/Divider";
import { incidentFields } from "../app/appConstants";
import {
  yupBooleanRequired,
  yupBooleanRequiredIfOtherFieldNotNull,
  yupInputRequiredIfOtherFieldTrue,
  yupSelectionRequiredIfOtherFieldTrue,
  yupInputRequiredIfOtherFieldTrueAndHasChild,
} from "../formUtils/validationRules";
import Loader from "../app/Loader";
import ControlActions from "./ControlActions";
import useOptionSets from "../optionsets/useOptionSets";
import snackbarState from "../app/snackbarState";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import permissionsState from "../auth/permissionsState";

const defaultValues = {
  controls: [],
};

export default function IncidentActions({ incident, analysis, handlePhaseChange, fullPage }) {
  const optionSets = useOptionSets();
  const auth = useRecoilValue(authState);
  const permissions = useRecoilValue(permissionsState);
  const canAction = !permissions.fetching && permissions.granted[permissions.all.incidentAnalyse];
  const [, setSnackbar] = useRecoilState(snackbarState);
  const [controlsExpanded, setControlsExpanded] = useState({ control0: true, control1: true, control2: true });

  const controlsFormSchema = yup.object().shape({
    controls: yup.array().of(
      yup.object().shape({
        cogHaz1Action: yupBooleanRequiredIfOtherFieldNotNull("cogHaz1"),
        cogHaz1ActionImprovement: yupInputRequiredIfOtherFieldTrue("cogHaz1Action"),
        cogHaz2Action: yupBooleanRequiredIfOtherFieldNotNull("cogHaz2"),
        cogHaz2ActionImprovement: yupInputRequiredIfOtherFieldTrue("cogHaz2Action"),
        cogHaz3Action: yupBooleanRequiredIfOtherFieldNotNull("cogHaz3"),
        cogHaz3ActionImprovement: yupInputRequiredIfOtherFieldTrue("cogHaz3Action"),
        controlAction: yupBooleanRequired,
        controlActionImprovement1: yupInputRequiredIfOtherFieldTrue("controlAction"),
        controlActionImprovement2: yupInputRequiredIfOtherFieldTrueAndHasChild("controlAction", "controlActionImprovement3"),
        controlSystemTypeImproved: yupSelectionRequiredIfOtherFieldTrue("controlAction"),
        counterMeasureTypeImproved: yupSelectionRequiredIfOtherFieldTrue("controlAction"),
        operationalFactor1Action: yupBooleanRequiredIfOtherFieldNotNull("operationalFactor1"),
        operationalFactor1ActionImprovement: yupInputRequiredIfOtherFieldTrue("operationalFactor1Action"),
        operationalFactor2Action: yupBooleanRequiredIfOtherFieldNotNull("operationalFactor2"),
        operationalFactor2ActionImprovement: yupInputRequiredIfOtherFieldTrue("operationalFactor2Action"),
        operationalFactor3Action: yupBooleanRequiredIfOtherFieldNotNull("operationalFactor3"),
        operationalFactor3ActionImprovement: yupInputRequiredIfOtherFieldTrue("operationalFactor3Action"),
        orgFactor1Action: yupBooleanRequiredIfOtherFieldNotNull("orgFactor1"),
        orgFactor1ActionImprovement: yupInputRequiredIfOtherFieldTrue("orgFactor1Action"),
        orgFactor2Action: yupBooleanRequiredIfOtherFieldNotNull("orgFactor2"),
        orgFactor2ActionImprovement: yupInputRequiredIfOtherFieldTrue("orgFactor2Action"),
        orgFactor3Action: yupBooleanRequiredIfOtherFieldNotNull("orgFactor3"),
        orgFactor3ActionImprovement: yupInputRequiredIfOtherFieldTrue("orgFactor3Action"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useForm({
    resolver: yupResolver(controlsFormSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const { fields } = useFieldArray({
    control,
    keyName: "controlId",
    name: "controls",
  });

  useEffect(() => {
    if (analysis) {
      reset(analysis);
    }
  }, [analysis, reset]);



  const handleApprovalPhaseChange = () => {
    handlePhaseChange(Object.assign({}, incident, {state: "approval"}));
  }

  const handleComplete = async (formData) => {
    setSnackbar({ severity: "info", message: "Saving actions" });
    await fetchSimple(`/api/analysis/actions`, "POST", auth.token, null, handleApprovalPhaseChange, formData).then((data) =>
      reset(Object.assign({}, { id: incident.id }, data))
    );
    setSnackbar({ severity: "success", message: "Actions saved successfully" });
  };

  const focusErrors = (errors) => {
    var expanded = errors.controls.reduce((obj, item, i) => Object.assign(obj, { [`control${i}`]: true }), {});
    setControlsExpanded(Object.assign({}, controlsExpanded, expanded));
  };

  const riskCategory = watch("riskCategory");

  return (
    <form noValidate style={{ width: "100%", height: "100%", overflowY: "auto" }}>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          borderColor: "palette.divider",
          backgroundColor: "background.white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flex: "1 1 auto", overflowY: "auto" }}>
          {!optionSets.fetching && (
            <Box>
              <Box sx={{ px: 3, py: 3 }}>
                <Typography color="text.secondary" variant="overline" sx={{ lineHeight: 1.5 }}>
                  {incidentFields["riskCategory"]?.label}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
                  {optionSets.itemsById[analysis.riskCategory.toString()]?.label}
                </Typography>
              </Box>
            </Box>
          )}
          {analysis.fetching || optionSets.fetching ? (
            <Loader />
          ) : (
            <Box sx={{ mb: 3 }}>
              {fields.map((ca, idx) => (
                <ControlActions
                canAction={canAction}
                  key={ca.id}
                  idx={idx}
                  controlsExpanded={controlsExpanded}
                  setControlsExpanded={setControlsExpanded}
                  control={control}
                  analysis={ca}
                  riskCategory={riskCategory}
                  watch={watch}
                  setValue={setValue}
                  fullPage={!!fullPage}
                />
              ))}
            </Box>
          )}
        </Box>

        {fullPage && <Divider />}

        {canAction && <Box
          sx={{
            p: 3,
            backgroundColor: fullPage ? "background.white" : "background.mainLight",
            borderTop: fullPage ? 0 : 1,
            borderColor: "divider",
            ...(fullPage ? { px: 3, pb: 3 } : { flex: "0 1 90px", boxShadow: 4, clipPath: "polygon(0% -20%, 100% -20%, 100% 100%, 0% 100%)" }),
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{ ml: "auto" }}
              startIcon={<ApprovalIcon />}
              disabled={analysis.fetching || fields.length === 0 || isSubmitting || (incident.state !== "analysis" && !isDirty)}
              color="success"
              variant="contained"
              size="large"
              onClick={handleSubmit(handleComplete, focusErrors)}
            >
              Approval
            </Button>
          </Box>
        </Box>}
      </Box>
    </form>
  );
}
