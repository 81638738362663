import React from "react";
import { useRecoilValue } from "recoil";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid-premium";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LoadingButton from '@mui/lab/LoadingButton';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Add from "@mui/icons-material/Add";
import Download from "@mui/icons-material/FileDownload";
import ClientsAutocomplete from "../clients/ClientsAutocomplete";
import permissionsState from "../auth/permissionsState";

export default function IncidentsToolbar({ addIncident, exportIncidents, exporting, setDensityButtonEl, detailOpen, hideGridSettings, isClientRole }) {

  const permissions = useRecoilValue(permissionsState);
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Toolbar
      sx={{
        minHeight: 8,
        maxHeight: 8,
        backgroundColor: "background.greyMid",
        borderBottom: 1,
        borderBottomColor: "border.main",
      }}
    >
      {!isClientRole && <ClientsAutocomplete
        sx={{
          minWidth: 260,
          mr: 2,
          "& .MuiFormControl-root .MuiInputBase-root": {
            backgroundColor: "background.white",
            pl: 1,
          },
          "& .MuiFormControl-root .MuiInputBase-root input": { pl: 0 },
          "& .MuiFormControl-root .MuiInputBase-root.Mui-focused": { backgroundColor: "background.main" },
          "& .MuiFormControl-root .MuiInputBase-root:hover": { backgroundColor: "background.mainLight" },
        }}
      />}
      {!permissions.fetching && permissions.granted[permissions.all.incidentCreate] && <Button variant="contained" aria-label="Add Incident" color="secondary" onClick={addIncident}>
        <Add sx={{ mr: 1 }} />
        Add {!detailOpen && !hideGridSettings && "Incident"}
      </Button>}
      <Box sx={{mx: "auto"}}></Box>
      {!!exportIncidents && !permissions.fetching && permissions.granted[permissions.all.incidentExport] ? (
        !detailOpen || xlUp ? (
          <LoadingButton
            loading={!!exporting}
            loadingPosition="start"
            startIcon={<Download />}
            size="small"
            aria-label="Export"
            color="primary"
            sx={{ ml: 2, mr: 1 }}
            onClick={exportIncidents}
          >
            Export
          </LoadingButton>
        ) : (
          <IconButton
            disabled={!!exporting} aria-label="Add Incident" color="primary" sx={{ ml: 2, mr: 1 }} onClick={exportIncidents}>
            <Download />
          </IconButton>
        )
      ) : null}

      {(!detailOpen || xlUp) && !hideGridSettings && (
        <Box>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton sx={{ ml: 1 }} />
          <GridToolbarDensitySelector ref={setDensityButtonEl} sx={{ ml: 1 }} />
        </Box>
      )}
    </Toolbar>
  );
}
