import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";


  const MasterDetailPanel = styled(Paper, { shouldForwardProp: (prop) => prop !== "detailsOpen" && prop !== "drawerSize" && prop !== "anchor" && prop !== "isWidget"})(
    ({ theme, detailsOpen, drawerSize, sx }) => ({
      ...sx,
      borderLeft: 0,
      marginTop: theme.spacing(7),
      position: "relative",
      height: "calc(100vh - 56px)",
      [`${theme.breakpoints.up("sm")}`]: {
        height: "calc(100vh - 64px)",
        marginTop: theme.spacing(8),
      },
      flexGrow: 1,
      //width: 'auto',
      //padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
      marginBottom: 0,
      ...(detailsOpen && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginBottom: 0,
        marginRight: drawerSize.right.xs,
        height: "calc(100vh - 56px)",
        [`${theme.breakpoints.up("sm")}`]: {
          marginTop: theme.spacing(8),
          height: "calc(100vh - 64px)",
          marginRight: drawerSize.right.sm,
        },
        [`${theme.breakpoints.up("md")}`]: {
          height: "calc(100vh - 64px)",
          marginRight: drawerSize.right.md
        },
        [`${theme.breakpoints.up("lg")}`]: {
          height: "calc(100vh - 64px)",
          marginRight: drawerSize.right.lg,
        }, 
        [`${theme.breakpoints.up("xl")}`]: {
          height: "calc(100vh - 64px)",
          marginRight: drawerSize.right.xl,
        },
      }),
    })
  );

  export default MasterDetailPanel