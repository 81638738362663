import  { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const init = { fetching: true };

const useOptionSetsImpl = () => {
  const [optionSets, setOptionSets] = useState(init);
  useEffect(() => {
    fetch('/api/optionsetvalues')
      .then(results => results.json())
      .then(data => {
          setOptionSets(data);
      });
  }, []);

  return optionSets;
};

const useOptionSets = singletonHook(init, useOptionSetsImpl);

export default useOptionSets
