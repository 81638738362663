import React, { useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import UnignoreIcon from "@mui/icons-material/Replay";
import RuleIcon from "@mui/icons-material/Rule";
import IncidentEventDetails from "./IncidentEventDetails";
import IncidentAdditionalDetails from "./IncidentAdditionalDetails";
import snackbarState from "../app/snackbarState";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import { yupInputRequired, yupDateInputRequired } from "../formUtils/validationRules";
import IncidentViewContainer from "./IncidentViewContainer";

const defaultValues = {
  id: "",
  clientId: "",
  name: "",
  reference: "",
  injuryType: "",
  businessDivision: "",
  eventTitle: "",
  incidentMechanism: "",
  eventDetails: "",
  actual: "",
  potential: "",
  date: null,
  time: null,
  location: "",
  locationAdditional: "",
  riskCategoryClient: "",
  eventOwnership: "",
  timeWithinShift: "",
  shiftPattern: "",
  riskCategory: "",
  fatalityRisk: "",
  sif: "",
  controls: [],
};

export default function IncidentRestrictedView({
  incident,
  incidentFetching,
  handlePhaseChange,
  handleClose,
  handleNavigate,
  fullPage,
  hideTitle,
  phaseView,
}) {
  const [, setSnackbar] = useRecoilState(snackbarState);
  const auth = useRecoilValue(authState);

  const incidentFormSchema = yup.object().shape({
    reference: yupInputRequired,
    eventTitle: yupInputRequired,
    eventDetails: yupInputRequired,
    date: yupDateInputRequired,
    time: yupDateInputRequired
  });

  const { control, reset, setValue } = useForm({
    resolver: yupResolver(incidentFormSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (incident) {
      reset(Object.assign({}, incident));
    }
  }, [incident, reset]);

  const handleAssessmentClick = async () => {
    setSnackbar({ severity: "info", message: "Saving incident" });
    await fetchSimple("/api/incidents/assessment", "POST", auth.token, null, handlePhaseChange, incident);
    setSnackbar({ severity: "success", message: "Incident saved successfully" });
  };

  return (
    <IncidentViewContainer
      fullPage={fullPage}
      incident={incident}
      hideTitle={hideTitle}
      handleClose={handleClose}
      handleNavigate={handleNavigate}
      incidentFetching={incidentFetching}
    >
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box
          sx={{
            pt: 2,
            pb: 2,
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            overflowY: "auto",
            "& .MuiAccordion-root.MuiPaper-root, & .MuiAccordion-root .MuiAccordionSummary-root.MuiButtonBase-root": { backgroundColor: "background.white" },
            "& .MuiAccordion-root .MuiInputBase-root": { backgroundColor: "background.input" },
            "& .MuiAccordion-root .MuiButtonBase-root": { backgroundColor: "background.input" }
          }}
        >
    <form noValidate>
      <Box>
        <IncidentEventDetails
          control={control}
          setValue={setValue}
          incident={incident}
        />

        <IncidentAdditionalDetails defaultExpanded={false} control={control} incident={incident} />
      </Box>
    </form>
        </Box>

        <Box
          sx={{
            p: 3,
            backgroundColor: fullPage ? "background.white" : "background.mainLight",
            borderTop: fullPage ? 0 : 1,
            borderColor: "divider",
            ...(fullPage ? { px: 3, pb: 3 } : { flex: "0 1 90px", boxShadow: 4, clipPath: "polygon(0% -20%, 100% -20%, 100% 100%, 0% 100%)" }),
          }}
        >
          <Box sx={{ display: "flex" }}>
          {incident.state !== "ignored" ? (
            <Button
              sx={{ marginLeft: "auto" }}
              startIcon={<RuleIcon />}
              color="success"
              variant="contained"
              size="large"
              onClick={handleAssessmentClick}
            >
              Assessment
            </Button>
          ) : (
            <Button
              sx={{ marginLeft: "auto" }}
              startIcon={<UnignoreIcon />}
              color="error"
              variant="contained"
              size="large"
              onClick={handleAssessmentClick}
            >
              Un-Ignore
            </Button>
          )}

          </Box>
        </Box>
      </Box>
    </IncidentViewContainer>
  );
}
