import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Allotment } from "allotment";
import { DataGridPremium, useKeepGroupedColumnsHidden, useGridApiRef } from "@mui/x-data-grid-premium";
import "allotment/dist/style.css";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IncidentsToolbar from "./IncidentsToolbar";
import { isEmptyObject, renderTime } from "./gridUtils";
import NewIncident from "./NewIncident";
import authState from "../auth/authState";
import { Typography } from "@mui/material";

export default function IncidentSplitPanel({ children, incidents, setSelectedIncident, handleIncidentClick, fetching, masterPreferredSize }) {
  const theme = useTheme();
  const apiRef = useGridApiRef();
  const auth = useRecoilValue(authState);
  const isClientRole = auth.user?.role === "client";
  const [pageSize, setPageSize] = useState(20);
  const [densityButtonEl, setDensityButtonEl] = useState(null);
  const [addOpen, setAddOpen] = useState(false);
  const [columnVisibilityModel] = useState({
    // Hide the column used for leaves
    //reference: false,
    clientName: false,
  });

  useEffect(() => {
    if (incidents?.length > 0) {
      apiRef.current.selectRow(incidents[0].id, true);
      setSelectedIncident(incidents[0]);
    } else {
      setSelectedIncident(false);
    }
  }, [incidents, apiRef, setSelectedIncident]);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      columns: {
        columnVisibilityModel: columnVisibilityModel,
      },
      rowGrouping: {
        model: ["clientName"],
      },
    },
  });

  const openAddIncident = () => {
    setAddOpen(true);
  };

  //const handleAnalyseClick = () => {};

  return (
    <Box
      sx={(theme) => {
        return {
          bgcolor: theme.palette.common.white,
          marginTop: theme.spacing(7),
          position: "relative",
          height: "calc(100vh - 56px)",
          [`${theme.breakpoints.up("sm")}`]: {
            height: "calc(100vh - 64px)",
            marginTop: theme.spacing(8),
          },
          "& .MuiDataGrid-row": { cursor: "pointer" },
          "& .MuiDataGrid-row.grouping-row": { color: theme.palette.primary.main, fontWeight: 500, background: theme.palette.background.greyLight },
          "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(3) },
          [`${theme.breakpoints.down("sm")}`]: {
            "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(2) },
          },
        };
      }}
      style={{
        "--focus-border": theme.palette.secondary.main,
      }}
    >
      <Allotment>
        <Allotment.Pane preferredSize={500} minSize={500}>
          <Box sx={{ display: "flex", height: 1, flexDirection: "column", overflowY: "auto", overflowX: "hidden" }}>
            <DataGridPremium
              sx={{ border: 0 }}
              apiRef={apiRef}
              loading={fetching}
              rows={incidents}
              pageSize={pageSize}
              onRowClick={handleIncidentClick}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[20, 50, 100, 500]}
              pagination
              hideFooterSelectedRowCount
              rowGroupingColumnMode="single"
              defaultGroupingExpansionDepth={-1}
              initialState={initialState}
              groupingColDef={{
                leafField: "reference",
                minWidth: 160,
                headerClassName: "left-cell",
                cellClassName: "left-cell",
                colSpan: ({ row }) => (isEmptyObject(row) ? 5 : undefined),
              }}
              columnVisibilityModel={columnVisibilityModel}
              disableRowGrouping={isClientRole}
              getRowClassName={({ row }) => (isEmptyObject(row) ? "grouping-row" : "")}
              slots={{
                toolbar: isClientRole
                  ? () => (
                      <Toolbar
                        sx={{
                          minHeight: 8,
                          maxHeight: 8,
                          backgroundColor: "background.greyMid",
                          borderBottom: 1,
                          borderBottomColor: "border.main",
                        }}
                      >
                        <Typography sx={{ fontWeight: 400 }} noWrap variant="h6">Incidents</Typography>
                      </Toolbar>
                    )
                  : IncidentsToolbar,
              }}
              slotProps={{
                toolbar: { setDensityButtonEl, addIncident: openAddIncident, hideGridSettings: true, isClientRole },
                panel: {
                  anchorEl: densityButtonEl,
                  placement: "bottom-end",
                },
              }}
              columns={[
                {
                  field: "clientName",
                  headerName: "Client",
                  flex: 1,
                },
                {
                  field: "reference",
                  headerName: "Reference",
                  headerClassName: "left-cell",
                  cellClassName: "left-cell",
                  flex: 1,
                },
                {
                  field: "eventTitle",
                  headerName: "Event title",
                  flex: 1,
                },
                {
                  field: "date",
                  headerName: "Date",
                  type: "date",
                  valueGetter: ({ value }) => value && new Date(value),
                  //renderCell: renderDate,
                  flex: 1,
                },
                {
                  field: "time",
                  headerName: "Time",
                  type: "dateTime",
                  valueGetter: ({ value }) => value && new Date(value),
                  renderCell: renderTime,
                  flex: 1,
                },
              ]}
            />
          </Box>
        </Allotment.Pane>

        <Allotment.Pane maxSize={1200} minSize={900}>
          {children}
        </Allotment.Pane>
      </Allotment>
      <NewIncident isClientRole open={addOpen} setClose={setAddOpen} processRowUpdate={() => {}} handlePhaseChange={() => {}} />
    </Box>
  );
}
