import React from 'react'
import { useRecoilValue } from 'recoil';
import permissionsState from "./permissionsState";


export default function PermissionRoute({ children, permissionsRequired }) {
    
    const permissions = useRecoilValue(permissionsState);

    var allowed = false;

   for (var i = 0; i < permissionsRequired.length; i++) {
        if (permissions.granted[permissionsRequired[i]]) {
            allowed = true;
            break;
        }
    }

    return allowed ? children : <div></div>

}
