import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { Allotment } from "allotment";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { DataGridPremium } from "@mui/x-data-grid-premium";
//import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Lock from "@mui/icons-material/LockOutlined";
import Done from "@mui/icons-material/Done";
import Close from "@mui/icons-material/Close";
import UserToolbar from "./UserToolbar";
import UserForm from "./UserForm";
//import ScrollContainer from "../app/ScrollContainer";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import snackbarState from "../app/snackbarState";
import permissionsState from "../auth/permissionsState";

function updateSelectionStats(user, selData) {
  selData.total = selData.total + 1;

  if (user.lockedOut) selData.unlock = selData.unlock + 1;
  else selData.lock = selData.lock + 1;

  // if (user.force2fa)
  //     selData.unEnforce2fa = selData.unEnforce2fa + 1;
  // else
  //     selData.enforce2fa = selData.enforce2fa + 1;

  if (!user.emailConfirmed || !user.passwordSet) selData.resendInvite = selData.resendInvite + 1;
}

export default function UsersPage(props) {
  const auth = useRecoilValue(authState);
  const permissions = useRecoilValue(permissionsState);
  const theme = useTheme();
  //const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  // const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  // const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  //const xxlUp = useMediaQuery(theme.breakpoints.up("xxl"));
  //const xxxlUp = useMediaQuery(theme.breakpoints.up("xxxl"));
  const [, setSnackbar] = useRecoilState(snackbarState);
  const [users, setUsers] = useState([]);
  const [fetching, setFetching] = useState({ fetching: true });
  const [currentRow, setCurrentRow] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectionStats, setSelectionStats] = useState({
    unlock: 0,
    lock: 0,
    enforce2fa: 0,
    unEnforce2fa: 0,
    resendInvite: 0,
    total: 0,
  });

  async function fetchUsers() {
    await fetchSimple("/api/users/list", "GET", auth.token, setFetching, setUsers).finally(() => setFetching(false));
  }

  useEffect(() => {
    async function loadUsers() {
      await fetchSimple("/api/users/list", "GET", auth.token, setFetching, setUsers).finally(() => setFetching(false));
    }
    loadUsers();
  }, [auth.token]);

  function selectionChanged(ids) {
    var selData = {
      unlock: 0,
      lock: 0,
      enforce2fa: 0,
      unEnforce2fa: 0,
      resendInvite: 0,
      total: 0,
    };

    if (ids.length === 0) {
      setSelectionStats(selData);
    } else {
      const selectedIds = new Set(ids);
      const selectedRowData = users.filter((row) => selectedIds.has(row.id));
      for (var i = 0; i < selectedRowData.length; i++) {
        updateSelectionStats(selectedRowData[i], selData);
      }
      setSelectionStats(selData);
    }
    setSelectedIds(ids);
  }

  function insertNewUser(newUser) {
    var newUsers = Object.assign([], users);
    newUsers.push(newUser);
    setUsers(newUsers);
    setCurrentRow(null);
  }

  function openNewUser() {
    setCurrentRow(null);
    setUserModalOpen(true);
  }

  function openEditUser(params, e) {
    e.stopPropagation();
    e.preventDefault();
    setCurrentRow(params.row);
  }

  function handleUserModalClose() {
    setUserModalOpen(false);
    setCurrentRow(null);
  }

  async function resendInvite(user) {
    var payload = { ids: Boolean(user) ? [user.id] : selectedIds };
    setSnackbar({ severity: "success", message: "Started new invite emails" });
    await fetchSimple("/api/users/resend", "POST", auth.token, null, null, payload).finally(() => {
      setSnackbar({ severity: "success", message: "New invite emails sent" });
      if (!Boolean(user)) selectionChanged([]);
    });
  }

  async function lockUser(user) {
    var payload = { ids: Boolean(user) ? [user.id] : selectedIds };
    setSnackbar({ severity: "success", message: "Starting lock process" });
    await fetchSimple("/api/users/lock", "POST", auth.token, null, null, payload).finally(() => {
      if (!Boolean(user)) selectionChanged([]);
      else setCurrentRow(Object.assign({}, user, {lockedOut: true}));
      fetchUsers();
      setSnackbar({ severity: "success", message: "Lock complete" });
    });
  }

  async function unlockUser(user) {
    var payload = { ids: Boolean(user) ? [user.id] : selectedIds };
    setSnackbar({ severity: "success", message: "Starting unlock process" });
    await fetchSimple("/api/users/unlock", "POST", auth.token, null, null, payload).finally(() => {
      if (!Boolean(user)) selectionChanged([]);
      else setCurrentRow(Object.assign({}, user, {lockedOut: false}));
      fetchUsers();
      setSnackbar({ severity: "success", message: "Unlock complete" });
    });
  }

  return (
    <Box
      sx={(theme) => {
        return {
          bgcolor: theme.palette.common.white,
          marginTop: theme.spacing(7),
          position: "relative",
          height: "calc(100vh - 56px)",
          [`${theme.breakpoints.up("sm")}`]: {
            height: "calc(100vh - 64px)",
            marginTop: theme.spacing(8),
          },
          "& .MuiDataGrid-row": { cursor: "pointer" },
          "& .MuiDataGrid-row.grouping-row": { color: theme.palette.primary.main, fontWeight: 500, background: theme.palette.background.greyLight },
          "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(3) },
          [`${theme.breakpoints.down("sm")}`]: {
            "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(2) },
          },
          "& .split-view-view.split-view-view-visible": { overflowY: "auto" },
        };
      }}
      style={{
        "--focus-border": theme.palette.secondary.main,
      }}
    >
      <Allotment>
        <Allotment.Pane preferredSize={"70%"}>
          <DataGridPremium
            sx={{ border: 0 }}
            loading={fetching.fetching}
            rowSelectionModel={selectedIds}
            rows={users}
            getRowClassName={(params) => {
              return params.row.lockedOut ? "user-locked" : "";
            }}
            onRowSelectionModelChange={selectionChanged}
            checkboxSelection={true}
            disableRowSelectionOnClick
            onRowClick={permissions.granted[permissions.all.userCreate] ? openEditUser : null}
            hideFooter={true}
            slots={{
              toolbar: UserToolbar,
            }}
            slotProps={{
              toolbar: {
                selectionStats: selectionStats,
                usersCount: users ? users.length : 0,
                openNewUser: openNewUser,
                resendInvite: resendInvite,
                lockUser: lockUser,
                unlockUser: unlockUser,
              },
            }}
            columns={[
              { field: "firstName", headerName: "First Name", flex: 1 },
              { field: "surname", headerName: "Surname", flex: 1 },
              { field: "clientName", headerName: "Client", flex: 1.5 },
              { field: "email", headerName: "Email", flex: 1.7 },
              {
                field: "emailConfirmed",
                headerName: "Email Confirmed",
                width: 150,
                type: "boolean",
                renderCell: (params) => {
                  return params.value ? (
                    <Done sx={{ color: (theme) => theme.palette.success.dark }} />
                  ) : (
                    <Close sx={{ color: (theme) => theme.palette.text.disabled }} />
                  );
                },
              },
              {
                field: "passwordSet",
                headerName: "Password Set",
                width: 150,
                type: "boolean",
                renderCell: (params) => {
                  return params.value ? (
                    <Done sx={{ color: (theme) => theme.palette.success.dark }} />
                  ) : (
                    <Close sx={{ color: (theme) => theme.palette.text.disabled }} />
                  );
                },
              },
              {
                field: "role",
                headerName: "Role",
                width: 110,
              },
              {
                field: "lockedOut",
                headerName: "Locked",
                width: 130,
                align: "center",
                type: "boolean",
                headerAlign: "center",
                renderCell: (params) => {
                  return params.value ? (
                    <Lock sx={{ color: (theme) => theme.palette.error.dark }} />
                  ) : (
                    <Close sx={{ color: (theme) => theme.palette.text.disabled }} />
                  );
                },
              },
              // {
              //     field: 'twoFactorEnabled',
              //     headerName: '2FA',
              //     type: 'boolean',
              //     width: 110,
              //     renderCell: (params) => {
              //         return params.value ? <Done sx={{ color: (theme) => theme.palette.success.dark }} /> : <Close sx={{color: (theme) => theme.palette.text.disabled}} />
              //     },
              // },
              // {
              //     field: 'force2fa',
              //     headerName: 'Force 2FA',
              //     type: 'boolean',
              //     width: 130,
              //     renderCell: (params) => {
              //         return params.value ? <Done sx={{ color: (theme) => theme.palette.success.dark }} /> : <Close sx={{color: (theme) => theme.palette.text.disabled}} />
              //     },
              // },
              // {
              //   field: "id",
              //   headerName: "Actions",
              //   width: 58,
              //   disableColumnMenu: true,
              //   hideSortIcons: true,
              //   filterable: false,
              //   resizable: false,
              //   sortable: false,
              //   align: "right",
              //   renderCell: (params) => (
              //     <UserMenu params={params} openEditUser={openEditUser} resendInvite={resendInvite} lockUser={lockUser} unlockUser={unlockUser} />
              //   ),
              //   renderHeader: (params) => <span></span>,
              // },
            ]}
          />
        </Allotment.Pane>

        {currentRow && <Allotment.Pane minSize={600} preferredSize={600}>
        <UserForm currentUser={currentRow} setClose={handleUserModalClose} resendInvite={resendInvite} lockUser={lockUser} unlockUser={unlockUser} saveCallback={currentRow ? fetchUsers : insertNewUser} />
        </Allotment.Pane>}
      </Allotment>
      <Dialog open={userModalOpen} onClose={handleUserModalClose} maxWidth="sm" fullWidth>
        <UserForm currentUser={currentRow} setClose={handleUserModalClose} saveCallback={currentRow ? fetchUsers : insertNewUser} />
      </Dialog>
    </Box>
  );
}
