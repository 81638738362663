import React from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function TextInput({ name, variant, label, helper, control, sx, mask, handleChange, size, multiline, handleBlur, minRows, disabled, fieldSx }) {
  return (
    <Box sx={sx}>
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...field }, fieldState }) => {
          return (
            <TextField
              {...field}
              size={size ?? "medium"}
              variant={variant ?? "outlined"}
              label={label}
              disabled={disabled}
              inputRef={ref}
              fullWidth
              color="secondary"
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message || helper || " "}
              multiline={Boolean(multiline)}
              minRows={minRows}
              sx={{
                "& .MuiInputBase-root": { backgroundColor: "background.input" },
                "& .MuiInputBase-root:hover": { backgroundColor: "background.mainLight" },
                "& .MuiInputBase-root.Mui-disabled": { backgroundColor: "background.white" },
                "& .MuiInputBase-root.Mui-disabled:hover": { backgroundColor: "background.white" },
                "& .MuiInputBase-root.Mui-focused": { backgroundColor: "background.main" },
                ...fieldSx
              }}
              InputProps={{
                onChange: (e) => {
                  field.onChange(e);
                  if (handleChange) handleChange();
                },
                onBlur: (e) => {
                  field.onBlur(e);
                  if (handleBlur) handleBlur(e.target.value);
                },
                ...(mask && {
                  inputComponent: mask,
                }),
              }}
            />
          );
        }}
      />
    </Box>
  );
}
