import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { mainMenuWidth } from "./appConstants";

export default function ClippedDrawer({
  variant,
  anchor,
  drawerSize,
  open,
  setOpen,
  title,
  children,
  paperProps,
  hideToolbar,
  toolbarSx,
  hideCloseButton,
  titleComponent
}) {
  const dimensions =
    anchor === "bottom"
      ? {
          height: drawerSize.bottom ? drawerSize.bottom.detail : drawerSize,
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            height: drawerSize.bottom ? drawerSize.bottom.detail : drawerSize,
            ml: { lg: mainMenuWidth / 8 },
          },
        }
      : {
          width: drawerSize.right ? drawerSize.right : drawerSize,
          top: { xs: "56px", sm: "64px" },
          height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
          [`& .MuiDrawer-paper`]: {
            width: drawerSize.right ? drawerSize.right : drawerSize,
            boxSizing: "border-box",
            top: { xs: "56px", sm: "64px" },
            height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
          },
        };

  return (
    <Drawer
      open={Boolean(open)}
      anchor={anchor ?? "right"}
      variant={variant ? variant : "persistent"}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { ...paperProps } }}
      sx={{
        ...dimensions,
        flexShrink: 0,
        overflow: "hidden",
      }}
    >
      {!hideToolbar && (
        <Toolbar
          sx={{
            display: "flex",
            pl: 2,
            bgcolor: "background.white",
            ...toolbarSx,
          }}
        >
          {titleComponent ? titleComponent : <Typography
            variant="h6"
            sx={{
              alignSelf: "center",
              pr: 2,
              fontWeight: "regular"
            }}
          >
            {title}
          </Typography>}
          <Box sx={{ flex: "1" }}></Box>
          {!hideCloseButton && <IconButton sx={{ mr: -1 }} onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>}
        </Toolbar>
      )}
      {children}
    </Drawer>
  );
}