import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ChildrenIcon from "@mui/icons-material/AccountTreeOutlined";
import ScrollContainer from "../app/ScrollContainer";
import { largeDrawerSize, xlDrawerSize } from "../app/appConstants";
import ClippedDrawer from "../app/ClippedDrawer";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import OptionsetsToolbar from "./OptionsetsToolbar";
import OptionsetValuesPanel from "./OptionsetValuesPanel";

export default function OptionsetsPage(props) {
  const auth = useRecoilValue(authState);
  const [optionsets, setOptionsets] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selectedParent, setSelectedParent] = useState(false);

  // async function fetchOptionsets() {
  //   await fetchSimple("/api/optionsets", "GET", auth.token, setFetching, setOptionsets).finally(() => setFetching(false));
  // }

  useEffect(() => {
    async function loadOptionsets() {
      await fetchSimple("/api/optionsets", "GET", auth.token, setFetching, setOptionsets).finally(() => setFetching(false));
    }
    loadOptionsets();
  }, [auth.token, setOptionsets]);

  const handleRowClick = (params) => {
    setSelectedRow(params);
  };

  const setPanelOpen = (open) => {
    setSelectedRow(open);
  };

  const setChildPanelOpen = (open) => {
    setSelectedParent(open);
  };

  const processRowUpdate = (data) => {
    setOptionsets(optionsets.map((row) => (row.optionSetName === data.optionSetName ? data : row)));
    setSelectedRow({ row: data });
  };

  return (
    <ScrollContainer>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={(theme) => {
            return {
              flexGrow: 1,
              bgcolor: theme.palette.common.white,
              "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(3) },
              [`${theme.breakpoints.down("sm")}`]: {
                "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(2) },
              },
            };
          }}
        >
          <DataGridPremium
            sx={{ border: 0 }}
            loading={fetching}
            rows={optionsets}
            hideFooter={true}
            getRowId={(row) => row.optionSetName}
            onRowClick={handleRowClick}
            slots={{
              toolbar: OptionsetsToolbar,
            }}
            slotProps={{
              toolbar: {
                optionsetsCount: optionsets ? optionsets.length : 0,
                //openNewClient: openNewClient,
              },
            }}
            columns={[
              { field: "optionSetName", headerName: "Key", headerClassName: "left-cell", cellClassName: "left-cell", flex: 1 },
              { field: "name", headerName: "Name", flex: 1 },
              { field: "childOptionSetName", headerName: "Child key", headerClassName: "left-cell", cellClassName: "left-cell", flex: 1 },
              { field: "childOptionSetLabel", headerName: "Child name", flex: 1 },
              {
                field: "active",
                headerName: "Active",
                type: "boolean",
                renderCell: (params) => {
                  return params.value ? (
                    <DoneIcon sx={{ color: (theme) => theme.palette.success.main }} />
                  ) : (
                    <CloseIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
                  );
                },
              },
              {
                field: "hasChildren",
                headerName: "Nested",
                type: "boolean",
                renderCell: (params) => {
                  return params.value ? <ChildrenIcon color="action" /> : "";
                },
              },
              // {
              //   field: "id",
              //   headerName: "Actions",
              //   width: 58,
              //   disableColumnMenu: true,
              //   hideSortIcons: true,
              //   filterable: false,
              //   resizable: false,
              //   sortable: false,
              //   align: "right",
              //   renderCell: (params) => (
              //     <ClientsMenu params={params} openEditClient={openEditClient} activateClient={activateClient} disableClient={disableClient} />
              //   ),
              //   renderHeader: (params) => <span></span>,
              // },
            ]}
          />

          {/* <NewEditClient
            open={editClientOpen}
            setClose={handleUserModalClose}
            clientToEdit={clientToEdit}
            saveCallback={clientToEdit ? fetchClients : insertNewClient}
          /> */}
        </Box>
      </Box>
      <ClippedDrawer
        anchor="right"
        variant="temporary"
        drawerSize={selectedParent ? xlDrawerSize : largeDrawerSize}
        open={Boolean(selectedRow)}
        setOpen={setPanelOpen}
        title={"Manage: " + selectedRow?.row?.name}
        paperProps={{ overflowY: "hidden" }}
      >
        {selectedRow && (
          <OptionsetValuesPanel optionset={selectedRow?.row} processRowUpdate={processRowUpdate} setSelectedParent={setSelectedParent} />
        )}
      </ClippedDrawer>
      <ClippedDrawer
        anchor="right"
        variant="temporary"
        drawerSize={largeDrawerSize}
        open={Boolean(selectedParent)}
        setOpen={setChildPanelOpen}
        title={`Manage: ${selectedParent.label} > ${selectedRow?.row?.childOptionSetLabel}`}
        paperProps={{ overflowY: "hidden" }}
      >
        {selectedParent && <OptionsetValuesPanel optionset={selectedRow?.row} processRowUpdate={processRowUpdate} selectedParent={selectedParent} />}
      </ClippedDrawer>
    </ScrollContainer>
  );
}
