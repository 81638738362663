import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';import PageLoader from "../app/PageLoader";


function navigateToReturnUrl(returnUrl, history) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    //window.location.replace(returnUrl);
    history.replace(returnUrl.replace(window.location.origin, ""));
}

function redirectToRegister() {
    redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
}

function redirectToProfile() {
    redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
}

function redirectToApiAuthorizationPath(apiAuthorizationPath) {
    const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser he gets sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl);
}

async function processLoginCallback(history) {
    const url = window.location.href;
    const result = await authService.completeSignIn(url);
    switch (result.status) {
        case AuthenticationResultStatus.Redirect:
            // There should not be any redirects as the only time completeSignIn finishes
            // is when we are doing a redirect sign in flow.
            throw new Error('Should not redirect.');
        case AuthenticationResultStatus.Success:
            await navigateToReturnUrl(getReturnUrl(result.state), history);
            break;
        case AuthenticationResultStatus.Fail:
            console.log(result.message);
            break;
        default:
            throw new Error(`Invalid authentication result status '${result.status}'.`);
    }
}

function getReturnUrl(state) {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
}


async function login(returnUrl, history) {
    const state = { returnUrl };
    const result = await authService.signIn(state);
    switch (result.status) {
        case AuthenticationResultStatus.Redirect:
            break;
        case AuthenticationResultStatus.Success:
            await navigateToReturnUrl(returnUrl, history);
            break;
        case AuthenticationResultStatus.Fail:
            console.log(result.message);
            break;
        default:
            throw new Error(`Invalid status result ${result.status}.`);
    }
}

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export default function Login({action}) {

    const history = useHistory();

    useEffect(() => {
        switch (action) {
            case LoginActions.Login:
                login(getReturnUrl(), history);
                break;
            case LoginActions.LoginCallback:
                processLoginCallback(history);
                break;
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                console.log(error);
                break;
            case LoginActions.Profile:
                redirectToProfile();
                break;
            case LoginActions.Register:
                redirectToRegister();
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }, [action, history]);

    //if (!!message) {
        return  <PageLoader/>
    //} else {
    //    switch (action) {
    //        case LoginActions.Login:
    //            return (<div className={classes.progress}><CircularProgress color="primary" size="48" /></div>);
    //        case LoginActions.LoginCallback:
    //            return (<div className={classes.progress}><CircularProgress color="primary" size="48" /></div>);
    //        case LoginActions.Profile:
    //        case LoginActions.Register:
    //            return (<div className={classes.progress}><CircularProgress color="primary" size="48" /></div>);
    //        default:
    //            throw new Error(`Invalid action '${action}'`);
    //    }
    //}

}
