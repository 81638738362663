import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Popper from "@mui/material/Popper";
import AnnotationChip from "../incidents/AnnotationChip";

export default function AnnotatedInput({ label, placement, hasAnnotation, annotatedComponent, annotationComponent }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const id = open ? "annotation" : undefined;

  return (
    <Box
      sx={{
        "&.MuiBox-root:hover .MuiChip-root": { opacity: 1 },
        "&.MuiBox-root .MuiChip-root": { opacity: hasAnnotation ? 1 : 0 },
        position: "relative",
      }}
    >
      <AnnotationChip
        sx={{
          mr: 2,
          position: "absolute",
          zIndex: 1,
          right: 24,
          top: -12,
          transition: theme => theme.transitions.create("opacity", {
            easing: theme => theme.transitions.easing.easeInOut,
            duration: theme => theme.transitions.duration.enteringScreen,
          }),
        }}
        handleClick={handleToggle}
        hasAnnotation={hasAnnotation}
        anchorRef={anchorRef}
      />

      {annotatedComponent}

      <Popper
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        placement={placement || "right-start"}
        disablePortal={true}
        sx={{ zIndex: 2 }}
        modifiers={[
          {
            name: "flip",
            enabled: false,
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: false,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={6} sx={{ mx: 2, minWidth: 400 }}>
            <DialogTitle sx={{ "&.MuiTypography-root.MuiDialogTitle-root": { fontSize: "1.1rem" }, fontWeight: 400 }}>{label}</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>{annotationComponent}</DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
              <Button sx={{ mr: "auto" }} onClick={handleClose} color="error" variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleClose} color="success" variant="outlined">
                Ok
              </Button>
            </DialogActions>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
