import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import ScrollContainer from "./ScrollContainer"

export default function PageLoader({ children, ...rest }) {
  return (
    <ScrollContainer><Box sx={{
      display:"flex",
      justifyContent:"center",
      p: 8
  }}><CircularProgress color="primary" size={48} disableShrink /></Box></ScrollContainer>
  );
}
