import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import IncidentTabbedView from "./IncidentTabbedView";
import IncidentSplitPanel from "./IncidentSplitPanel";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import removeItemFromHierarchy from "./removeItemFromHierarchy";

export default function IncidentPhasePage({ phase }) {
  const [incidents, setIncidents] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(false);
  const [fetching, setFetching] = useState({ fetching: true });
  const auth = useRecoilValue(authState);

  useEffect(() => {
    async function fetchIncidents() {
      await fetchSimple(`/api/incidents/${phase}`, "GET", auth.token, null, setIncidents).finally(() => setFetching({ fetching: false }));
    }
    fetchIncidents();
  }, [auth.token, phase]);

  const handlePhaseChange = () => {
    removeItemFromHierarchy(incidents, selectedIncident, setIncidents, setSelectedIncident);
  };

  const processRowUpdate = async (updatedItem) => {
    const newIncidents = Object.assign(incidents, {
      [updatedItem.clientName]: incidents[updatedItem.clientName].map((item) => (item.id === updatedItem.id ? updatedItem : item)),
    });
    setIncidents(newIncidents);
  };

  const handleIncidentClick = (params) => {
    setSelectedIncident(params.row)
  };

  return (
    <IncidentSplitPanel
      incidents={incidents}
      setSelectedIncident={setSelectedIncident}
      handleIncidentClick={handleIncidentClick}
      fetching={fetching.fetching}
    >
      {selectedIncident && (
        <IncidentTabbedView
          incidentFetching={fetching.fetching}
          incident={selectedIncident}
          handlePhaseChange={handlePhaseChange}
          processRowUpdate={processRowUpdate}
          phaseView
        />
      )}
    </IncidentSplitPanel>
  );
}
