import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import { yupInputRequired, yupDateInputRequired } from "../formUtils/validationRules";
import IncidentEventDetails from "./IncidentEventDetails";
import IncidentAdditionalDetails from "./IncidentAdditionalDetails";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import permissionsState from "../auth/permissionsState";

const defaultValues = {
  id: "",
  clientId: "",
  name: "",
  reference: "",
  injuryType: "",
  businessDivision: "",
  eventTitle: "",
  incidentMechanism: "",
  eventDetails: "",
  actual: "",
  potential: "",
  date: null,
  time: null,
  location: "",
  locationAdditional: "",
  riskCategoryClient: "",
  eventOwnership: "",
  timeWithinShift: "",
  shiftPattern: "",
  riskCategory: "",
  fatalityRisk: "",
  sif: "",
  controls: [],
};

export default function IncidentDetailsPanel({ incident, processRowUpdate }) {
  const permissions = useRecoilValue(permissionsState);
  const canEdit = !permissions.fetching && permissions.granted[permissions.all.incidentCreate];
  const auth = useRecoilValue(authState);

  const incidentFormSchema = yup.object().shape({
    reference: yupInputRequired,
    eventTitle: yupInputRequired,
    eventDetails: yupInputRequired,
    date: yupDateInputRequired,
    time: yupDateInputRequired,
  });

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(incidentFormSchema),
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (incident) {
      reset(Object.assign({}, incident));
    }
  }, [incident, reset]);

  const updateIncident = async (updatedIncident) => {
    await fetchSimple("/api/incidents", "POST", auth.token, null, null, updatedIncident).then((data) => {
      if (processRowUpdate) processRowUpdate(data);
    });
  };

  return (
    <form noValidate>
      <Box>
        <IncidentEventDetails
          canEdit={canEdit}
          control={control}
          setValue={setValue}
          handleBlur={handleSubmit(updateIncident)}
          handleChange={handleSubmit(updateIncident)}
          incident={incident}
        />

        <IncidentAdditionalDetails canEdit={canEdit} defaultExpanded={false} control={control} handleBlur={handleSubmit(updateIncident)} incident={incident} />
      </Box>
    </form>
  );
}
