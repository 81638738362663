

const removeItemFromHierarchy = (incidents, selectedIncident, setIncidents, setSelectedIncident) => {
  // const keys = Object.keys(incidents);
  // var oKey;
  // var oKeyIdx;
  // var idx = -1;
  // var filteredIncidents;
  // keys.forEach((key, ki) => {
  //   if (idx === -1) {
  //     oKeyIdx = ki;
  //     oKey = key;
  //     filteredIncidents = incidents[key].filter((incident, index) => {
  //       if (incident.id === selectedIncident.id) {
  //         idx = index;
  //         return false;
  //       }
  //       return true;
  //     });
  //   }
  // });

  var filteredIncidents = incidents.filter((incident, index) => {
        return incident.id !== selectedIncident.id;
      });

  if (filteredIncidents.length === 0) {
     setIncidents(false);
     setSelectedIncident(false);
  } else {
     setIncidents(filteredIncidents);
     setSelectedIncident(filteredIncidents[0]);
  }

  // var newIncidents = Object.assign({}, incidents);

  // if (filteredIncidents.length === 0) {
  //   delete newIncidents[oKey];
  //   var list = newIncidents[keys[oKeyIdx === keys.length - 1 ? oKeyIdx - 1 : oKeyIdx + 1]];
  //   setSelectedIncident(list[oKeyIdx === keys.length - 1 ? list.length - 1 : 0]);
  // } else {
  //   newIncidents[oKey] = filteredIncidents;
  //   var newSelected = incidents[oKey].length === idx - 1 ? newIncidents[oKey][idx - 1] : newIncidents[oKey][idx];
  //   setSelectedIncident(newSelected);
  // }

  // setIncidents(newIncidents);
};

export default removeItemFromHierarchy;

