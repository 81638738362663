import React from "react";
import ScrollContainer from "../app/ScrollContainer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import { ApplicationPaths } from "../auth/ApiAuthorizationConstants";

export default function UnauthenticatedLandingPage(props) {
  return (
    <ScrollContainer>
      <Box
        sx={{
          width: 1,
          p: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 1,
          background: 'url("/images/bg.jpg") no-repeat',
          backgroundSize: "100% 100%",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column", height: 1 }}>
          <Box sx={{ display: "flex", flex: 0, pb: 8 }}>
            <img src="/images/logo-inv.png" alt="IA logo" style={{ aspectRatio: "1500/521", height: "100px" }} />
          </Box>
          <Box sx={{ display: "flex", flex: 1 }}>
            <Box sx={{ flex: 1, px: { xs: 3, md: 6, lg: 8 } }}>
              <Typography variant="h3" sx={{ color: "#fff" }}>
                Reducing the risk of serious incidents with deeper learning from unplanned events
              </Typography>
              <Button
                component={Link}
                to={ApplicationPaths.Login}
                sx={{ my: 10, py: 2, px: 6, fontSize: "1.25rem", fontWeight: 600 }}
                color="secondary"
                variant="contained"
                size="large"
                startIcon={<LoginIcon sx={{ fontSize: "32px !important", mr: 2 }} />}
              >
                Sign In
              </Button>
            </Box>
            <Box sx={{ flex: 1, mr: 2, background: 'url("/images/devices.png") no-repeat', backgroundSize: "contain" }}></Box>
          </Box>
        </Box>
      </Box>
    </ScrollContainer>
  );
}
