import YesIcon from "@mui/icons-material/Done";
import NoIcon from "@mui/icons-material/Close";
import MaybeIcon from "@mui/icons-material/QuestionMark";

export const controlEnablemnt = [
  {value: "enabled", label: "Enabled", color: "success", icon: <YesIcon/> },
  {value: "difficult", label: "Difficult", color: "info", icon: <MaybeIcon/> },
  {value: "nonenabled", label: "Non-enabled", color: "warning", icon: <NoIcon/> }
]

export const controlRatingMatrix = [
  [100, 60, 30, 16],
  [70, 40, 20, 12],
  [50, 30, 14, 8],
  [40, 24, 10, 6],
  [30, 20, 8, 4],
  [24, 16, 6, 2],
  [20, 12, 4, 2],
  [16, 8, 4, 2],
  [12, 6, 2, 1],
  [10, 6, 1, 1]
]

export const controlColorRating = {
  "100": "#73b87e",
  "70": "#84bb7b",
  "60": "#94bd77",
  "50": "#a4c073",
  "40": "#b0be6e",
  "30": "#c4c56d",
  "24": "#d4c86a",
  "20": "#f5ce62",
  "16": "#e9b861",
  "14": "#e6ad61",
  "12": "#ecac67",
  "10": "#e9a268",
  "8": "#e79a69",
  "6": "#e5926b",
  "4": "#e2886c",
  "2": "#e0816d",
  "1": "#dd776e",
}

export const counterMeasureTypeOptions = [
  { optionSetName: "counterMeasureType", id: 0, value: "0", label: "Prevent existence of energy" },
  { optionSetName: "counterMeasureType", id: 1, value: "1", label: "Minimise amount of energy" },
  { optionSetName: "counterMeasureType", id: 2, value: "2", label: "Prevent release of energy" },
  { optionSetName: "counterMeasureType", id: 3, value: "3", label: "Control release of energy" },
  { optionSetName: "counterMeasureType", id: 4, value: "4", label: "Separate energy from human or structure" },
  { optionSetName: "counterMeasureType", id: 5, value: "5", label: "Interpose material barrier to stop or lower energy" },
  { optionSetName: "counterMeasureType", id: 6, value: "6", label: "Provide protection for human or structure" },
  { optionSetName: "counterMeasureType", id: 7, value: "7", label: "Modify contact surface to minimise damage" },
  { optionSetName: "counterMeasureType", id: 8, value: "8", label: "Rapid evaluation and treatment response" },
  { optionSetName: "counterMeasureType", id: 9, value: "9", label: "Stabilise with rehabilitation and repair measures" }
]

export const controlSystemTypeOptions = [
  { optionSetName: "controlSystemType", id: 0, value: "0", label: "Permanent physical", description: "Permanent physical control that requires no human action, apart from periodic maintenance" },
  { optionSetName: "controlSystemType", id: 1, value: "1", label: "Exposure-triggered physical", description: "Physical control that is triggered by a particular level of exposure and is automated" },
  { optionSetName: "controlSystemType", id: 2, value: "2", label: "Technical behavioural", description: "Technical control that relies on a particular combination of human action and equipment" },
  { optionSetName: "controlSystemType", id: 3, value: "3", label: "Procedural behavioural", description: "Procedural control that relies solely on human action" },
]