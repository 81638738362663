import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop(props) {

    let location = useLocation();

    useEffect(() => {
        var scrollContainer = document.getElementById("pageScroll");
        if(scrollContainer) scrollContainer.scrollTop = 0;
    }, [location]);

    return props.children;
}
