import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import TextInput from "../formUtils/TextInput";
import RadioInput from "../formUtils/RadioInput";
import ControlledClientsAutocomplete from "../clients/ControlledClientsAutocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/SendOutlined";
import CloseIcon from "@mui/icons-material/Close";
import NextIcon from "@mui/icons-material/ChevronRight";
import PreviousIcon from "@mui/icons-material/ChevronLeft";
import SaveIcon from "@mui/icons-material/Save";
import authState from "../auth/authState";
import { yupInputRequired, yupEmailRequired, yupSelectionRequired } from "../formUtils/validationRules";
import { roles, mb3Sx } from "../app/appConstants";
import snackbarState from "../app/snackbarState";
import fetchSimple from "../services/fetchSimple";
import UserPropertiesAccordion from "./UserPropertiesAccordion";
import permissionsState from "../auth/permissionsState";

const userFormSchema = yup.object().shape({
  email: yupEmailRequired,
  firstName: yupInputRequired,
  surname: yupInputRequired,
  role: yupSelectionRequired,
  client: yup
    .object()
    .nullable()
    .when("role", {
      is: "client",
      then: yup.object().nullable().required("A value is required"),
    }),
});

const defaultValues = {
  email: "",
  firstName: "",
  surname: "",
  role: "",
  client: "",
  permissions: [],
};

export default function UserForm({ setClose, currentUser, resendInvite, lockUser, unlockUser, saveCallback }) {
  const auth = useRecoilValue(authState);
  const permissions = useRecoilValue(permissionsState);
  const [, setSnackbar] = useRecoilState(snackbarState);
  const [fetching, setFetching] = useState({ fetching: false });
  const [resendDisabled, setResendDisabled] = useState(false);
  const [currentPanelIdx, setCurrentPanelIdx] = useState(0);
  //const [userPermissions, setUserPermissions] = useState({ fetching: true });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    //formState: { errors },
  } = useForm({
    resolver: yupResolver(userFormSchema),
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  const { fields, replace } = useFieldArray({
    control,
    name: "permissions",
  });

  const role = watch("role");

  useEffect(() => {
    if (currentUser) {
      reset({
        email: currentUser.email,
        firstName: currentUser.firstName,
        surname: currentUser.surname,
        role: currentUser.role,
        client: currentUser.clientId ? { id: currentUser.clientId, name: currentUser.clientName } : "",
      });

      // get the permissions for the current User
    } else {
      reset(defaultValues);
    }
  }, [currentUser, reset]);

  useEffect(() => {
    // if role is not empty either get new permissions for current user, or get default role permissions
    if (role !== "") {
      async function fetchPermissions() {
        await fetchSimple(`api/permissions/${!!currentUser?.id ? currentUser.id : "default"}/${role}`, "GET", auth.token, null, null).then((perms) =>
          replace(perms)
        );
      }
      fetchPermissions();
    }
  }, [role, auth.token, currentUser, replace]);

  //[Route("api/permissions/{userId}/{role?}")]
  // [Route("api/permissions/default/{role?}")]

  //console.log(userPermissions)

  async function saveUser(data) {
    const permsChecked = data.permissions.filter((p) => p.value);
    const permissionHash = permissions.granted[permissions.all.userPermissions]
      ? permsChecked.reduce((obj, item) => Object.assign(obj, { [item.systemName]: item.value }), {})
      : null;
    const user = Object.assign(data, { clientId: data.client?.id, permissions: permissionHash });

    await fetchSimple("/api/users", currentUser ? "PUT" : "POST", auth.token, setFetching, saveCallback, user)
      .then(() => {
        setSnackbar({ severity: "success", message: currentUser ? "User updated successfully" : "User created successfully. Invite emailed" });
      })
      .catch(() => setSnackbar({ severity: "error", message: "Something went wrong. Please try again" }))
      .finally(() => {
        setFetching({ fetching: false });
        setClose();
      });
  }

  const onSubmitError = () => {
    setCurrentPanelIdx(0);
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentPanelIdx(1);
  };

  const handleTabChange = (event, idx) => {
    setCurrentPanelIdx(idx);
  };

  const handlePanelChange = (idx) => (event, isExpanded) => {
    setCurrentPanelIdx(isExpanded ? idx : false);
  };

  const renderPanel = (title, children, idx) => {
    if (permissions.granted[permissions.all.userPermissions])
      return currentUser ? (
        <UserPropertiesAccordion title={title} children={children} expanded={idx === currentPanelIdx} handleChange={handlePanelChange(idx)} />
      ) : (
        <Box sx={{ p: 3, pt: 4 }} hidden={currentPanelIdx !== idx}>
          {children}
        </Box>
      );
    return children;
  };

  const renderPermissionCheckbox = (i) => {
    return (
      <Box key={fields[i].id}>
        <Controller
          name={`permissions.${i}.value`}
          control={control}
          render={({ field }) => {
            return (
              <FormControlLabel
                control={<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} color="secondary" />}
                label={fields[i].name}
              />
            );
          }}
        />
      </Box>
    );
  };

  const renderPermissions = () => {
    var permissionsList = [];
    var lastHeading = "";

    for (var i = 0; i < fields.length; i++) {
      var currHeading = fields[i].systemName.substr(0, fields[i].systemName.indexOf("."));
      if (lastHeading !== currHeading) {
        permissionsList.push(
          <Typography key={currHeading} component="p" sx={{ color: "text.secondary", mb: 1, mt: lastHeading === "" ? 0 : 3 }} variant="body1">
            {currHeading.charAt(0).toUpperCase() + currHeading.slice(1)}
          </Typography>
        );
        lastHeading = currHeading;
      }
      permissionsList.push(renderPermissionCheckbox(i));
    }

    return permissionsList;
  };

  const toggleLocked = () => {
    if (currentUser.lockedOut) unlockUser(currentUser);
    else lockUser(currentUser);
  };

  const resendApplyTimeout = () => {
    setResendDisabled(true);
    setTimeout(() => setResendDisabled(false), 5000);
    resendInvite();
  };

  return (
    <form onSubmit={handleSubmit(saveUser, onSubmitError)} noValidate>
      <Toolbar>
        <DialogTitle variant="h6" sx={{ pl: 0, flex: 1 }}>
          {currentUser ? "Edit " + currentUser.email : "Add User"}
        </DialogTitle>
        <IconButton edge="start" color="inherit" onClick={setClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>

      {currentUser && (permissions.granted[permissions.all.userLock] || !currentUser.emailConfirmed) && (
        <Box sx={{ display: "flex", px: 3, py: 2, backgroundColor: "background.greyLight" }}>
          {permissions.granted[permissions.all.userLock] && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography color={currentUser.lockedOut ? "error.main" : "text.secondary"}>Locked</Typography>
              <Switch checked={!currentUser.lockedOut} onChange={toggleLocked} color="secondary" />
              <Typography color={!currentUser.lockedOut ? "success.main" : "text.secondary"}>Active</Typography>
            </Stack>
          )}
          {!currentUser.emailConfirmed && (
            <Button sx={{ ml: "auto" }} onClick={resendApplyTimeout} startIcon={<SendIcon />} color="secondary" disabled={resendDisabled}>
              Resend Invite
            </Button>
          )}
        </Box>
      )}

      {!currentUser && (
        <Tabs variant="fullWidth" value={currentPanelIdx} onChange={handleTabChange}>
          <Tab label="User Details" />
          <Tab label="User Permissions" disabled={role === ""} />
        </Tabs>
      )}

      {renderPanel(
        "User Details",
        <Box>
          <TextInput name="email" label="Email address" control={control} sx={mb3Sx} />
          <TextInput name="firstName" label="First name" control={control} sx={mb3Sx} />
          <TextInput name="surname" label="Surname" control={control} sx={mb3Sx} />
          <RadioInput optionSet={roles} control={control} />
          {role === "client" && (
            <Box sx={{ pt: 2 }}>
              <ControlledClientsAutocomplete name="client" control={control} />
            </Box>
          )}
        </Box>,
        0
      )}

      {permissions.granted[permissions.all.userPermissions] && renderPanel("User Permissions", <Box>{renderPermissions()}</Box>, 1)}

      <DialogActions sx={{ p: 3 }}>
        {(!permissions.granted[permissions.all.userPermissions] || currentPanelIdx === 1) && (
          <Button sx={{ mr: "auto" }} onClick={() => setCurrentPanelIdx(0)} variant="outlined" startIcon={<PreviousIcon />} color="secondary">
            Previous
          </Button>
        )}

        <Button onClick={setClose} sx={{ mr: 2 }}>
          Cancel
        </Button>

        {!permissions.granted[permissions.all.userPermissions] || currentPanelIdx === 1 ? (
          <Button disabled={fetching.fetching} type="submit" startIcon={<SaveIcon />} variant="contained" color="secondary">
            Save
          </Button>
        ) : (
          <Button onClick={handleNextClick} disabled={role === ""} variant="outlined" endIcon={<NextIcon />} color="secondary">
            Next
          </Button>
        )}
      </DialogActions>
    </form>
  );
}
