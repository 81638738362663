import React, { Fragment } from 'react';
import { useRecoilValue } from "recoil";
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreVert from '@mui/icons-material/MoreVert';
import permissionsState from "../auth/permissionsState";



export default function ClientsMenu({ params, openEditClient, disableClient, activateClient }) {

    const permissions = useRecoilValue(permissionsState);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleClose();
        openEditClient(params.row);
    };

    const handleDisable = () => {
        handleClose();
        disableClient(params.row);
    };

    const handleActivate = () => {
        handleClose();
        activateClient(params.row);
    };


    return ((permissions.granted[permissions.all.clientCreate] || permissions.granted[permissions.all.clientDisable]) ? <Fragment>
        <IconButton onClick={handleMenu}>
            <MoreVert />
        </IconButton>
        <Menu
            id={`menu-user-actions-${params.id}`}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={open}
            onClose={handleClose}
        >
            {permissions.granted[permissions.all.clientCreate] && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
            {permissions.granted[permissions.all.clientDisable] ? params.row.active ? <MenuItem onClick={handleDisable}>Disable</MenuItem> : <MenuItem onClick={handleActivate}>Activate</MenuItem> : null}
        </Menu>
    </Fragment> : null
    );
}