import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import fetchSimple from "../services/fetchSimple";
import authState from "../auth/authState";
import selectedClientState from "./selectedClientState";

export default function ClientsAutocomplete({ sx }) {
  const auth = useRecoilValue(authState);
  const [selectedClient, setSelectedClient] = useRecoilState(selectedClientState);
  const [clients, setClients] = useState([]);
  const [, setFetching] = useState({ fetching: true });

  useEffect(() => {
    async function fetchClients() {
      await fetchSimple("/api/clients", "GET", auth.token, setFetching, setClients).finally(() => setFetching(false));
    }
    fetchClients();
  }, [auth.token, setClients]);

  return (
    <Autocomplete
      value={selectedClient}
      sx={sx}
      disablePortal
      options={clients}
      getOptionLabel={(option) => (Boolean(option.name) ? option.name : "Select client")}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          color="secondary"
          sx={{
                "& .MuiInputBase-root": { backgroundColor: "background.input" },
                "& .MuiInputBase-root:hover": { backgroundColor: "background.mainLight" },
                "& .MuiInputBase-root.Mui-focused": { backgroundColor: "background.main" },
          }}
          placeholder="Select client"
          variant="outlined"
          hiddenLabel
          size="small"
        />
      )}
      onChange={(_, client) => setSelectedClient(client)}
    />
  );
}
