import React from "react";
import { lighten } from "@mui/material";
import Chip from "@mui/material/Chip";
import NoteIcon from "@mui/icons-material/StickyNote2Outlined";

export default function AnnotationChip({ handleClick, hasAnnotation, anchorRef, sx }) {

  return (
      <Chip
        sx={(theme) => {
          return {
            ...sx,
            borderRadius: 2,
            "& .MuiChip-label": { p: 0 },
            "& .MuiChip-icon": { mx: 0.5 },
            color: hasAnnotation ? theme.palette.note.main : theme.palette.primary.main, 
            borderColor: hasAnnotation ? lighten(theme.palette.note.main, 0.5) : theme.palette.primary.main, 
            backgroundColor: hasAnnotation ? lighten(theme.palette.note.main, 0.95) :  lighten(theme.palette.primary.main, 0.9),
            "&.MuiChip-root:hover": {
              borderColor: theme.palette.secondary.main,
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.main, 0.9),
            },
          };
        }}
        ref={anchorRef}
        icon={<NoteIcon />}
        onClick={handleClick}
        color="primary"
        label={null}
        variant="outlined"
        size="small"
      ></Chip>
  );
}
