import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextInput from "../formUtils/TextInput";
import CheckboxInput from "../formUtils/CheckboxInput";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import authState from "../auth/authState";
import { yupInputRequired } from "../formUtils/validationRules";
import snackbarState from "../app/snackbarState";
import fetchSimple from "../services/fetchSimple";

const newUserFormSchema = yup.object().shape({
  name: yupInputRequired
});

const defaultValues = {
  name: "",
  active: true,
  disableParticipantInheritanceFromTenant: false
};

export default function NewEditClient({ open, setClose, clientToEdit, saveCallback }) {
  const auth = useRecoilValue(authState);
  const [, setSnackbar] = useRecoilState(snackbarState);
  const [fetching, setFetching] = useState({ fetching: false });

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
  } = useForm({
    resolver: yupResolver(newUserFormSchema),
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if(clientToEdit) {
      reset({
        id: clientToEdit.id,
        name: clientToEdit.name,
        active: clientToEdit.active,
        disableParticipantInheritanceFromTenant: clientToEdit.disableParticipantInheritanceFromTenant,
        modified:  clientToEdit.modified,
        created:  clientToEdit.created
      });
    };
  }, [clientToEdit, reset]);

  useEffect(() => {
    if(open && setFocus) setTimeout(() => setFocus("name"), 500);
  }, [open, setFocus]);

  const handleClose = () => {
    reset(defaultValues);
    setClose();
  };

  async function saveUser(data) {

    await fetchSimple("/api/clients", clientToEdit ? "PUT" : "POST", auth.token, setFetching, saveCallback, data)
      .then(() => {
        setSnackbar({ severity: "success", message: "Client updated successfully" });
      })
      .catch(() => setSnackbar({ severity: "error", message: "Something went wrong. Please try again" }))
      .finally(() => { 
        setFetching({ fetching: false }); 
        handleClose();
      });

  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth keepMounted>
      <form onSubmit={handleSubmit(saveUser)} noValidate>
        <Toolbar>
          <DialogTitle variant="h6" sx={{ pl: 0, flex: 1 }}>
            {clientToEdit ? "Edit " + clientToEdit.name : "Add Client"}
          </DialogTitle>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent>
          <TextInput name="name" label="Client name" control={control} />
          <CheckboxInput name="disableParticipantInheritanceFromTenant" label="Disable Participant Inheritance from Tenant" control={control} />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button disabled={fetching.fetching} type="submit" startIcon={<SaveIcon />} variant="contained" color="secondary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
