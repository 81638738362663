import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { controlColorRating } from "./controlConstants";

export default function ControlRating({ controlRating, sx, improved }) {

  return (
    <Box sx={{ float: "right", maxWidth: 112, ml: 1 }}>
      <Box sx={{ ml:1, position: "relative", display: "inline-flex", ...sx }}>
        <CircularProgress sx={{ color: "background.greyMid" }} size={80} variant="determinate" value={100} />
        {controlRating > 0 && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              sx={{ color: controlColorRating[controlRating.toString()] }}
              size={80}
              variant="determinate"
              value={controlRating}
            />
          </Box>
        )}
        {controlRating > 0 && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ color: controlColorRating[controlRating.toString()], fontWeight: 300 }} variant="h5" component="div">
              {controlRating}
            </Typography>
          </Box>
        )}
      </Box>
      <Typography sx={{ fontSize: "0.7rem" }} component="p" color="text.disabled" variant="button">
        Control Rating
      </Typography>
    </Box>
  )
}