import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ToggleButtonInput from "../formUtils/ToggleButtonInput";
import SelectInput from "../formUtils/SelectInput";
import TextInput from "../formUtils/TextInput";
import AnnotatedInput from "../formUtils/AnnotatedInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CognitiveIcon from "@mui/icons-material/PsychologyOutlined";
import OperationalIcon from "@mui/icons-material/EngineeringOutlined";
import OrganisationIcon from "@mui/icons-material/BusinessOutlined";
import AddIcon from "@mui/icons-material/Add";
import ControlRating from "./ControlRating";
import { mb025Sx, mb3Sx, mb2Sx, incidentFields } from "../app/appConstants";
import { controlRatingMatrix, controlEnablemnt, controlSystemTypeOptions, counterMeasureTypeOptions } from "./controlConstants";
import useOptionSets from "../optionsets/useOptionSets";
// import CountermeasureSelect from "./CountermeasureSelect";
// import ControlTypeSelect from "./ControlTypeSelect";

export default function ControlAnalysis({
  idx,
  analysis,
  riskCategory,
  control,
  watch,
  setValue,
  controlsExpanded,
  setControlsExpanded,
  confirmDelete,
  fullPage,
  canAnalyse,
}) {
  const optionSets = useOptionSets();
  const [fieldStates, setFieldStates] = useState({});
  const [controlRating, setControlRating] = useState(0);

  // const errorType = watch(`controls.${idx}.errorType`);
  // const cogHaz1 = watch(`controls.${idx}.cogHaz1`);
  // const cogHaz2 = watch(`controls.${idx}.cogHaz2`);
  // const operationalFactor1 = watch(`controls.${idx}.operationalFactor1`);
  // const operationalFactor2 = watch(`controls.${idx}.operationalFactor2`);
  // const orgFactor1 = watch(`controls.${idx}.orgFactor1`);
  // const orgFactor2 = watch(`controls.${idx}.orgFactor2`);

  // const controlSystemType = watch(`controls.${idx}.controlSystemType`);
  // const counterMeasureType = watch(`controls.${idx}.counterMeasureType`);

  const watchedControl = watch(`controls.${idx}`);

  // const errorType = watch(`controls.${idx}.errorType`);
  // const cogHaz1 = watch(`controls.${idx}.cogHaz1`);
  // const cogHaz2 = watch(`controls.${idx}.cogHaz2`);
  // const operationalFactor1 = watch(`controls.${idx}.operationalFactor1`);
  // const operationalFactor2 = watch(`controls.${idx}.operationalFactor2`);
  // const orgFactor1 = watch(`controls.${idx}.orgFactor1`);
  // const orgFactor2 = watch(`controls.${idx}.orgFactor2`);

  //  const controlSystemType = watch(`controls.${idx}.controlSystemType`);
  //  const counterMeasureType = watch(`controls.${idx}.counterMeasureType`);

  useEffect(() => {
    const controlSystemIdx = parseInt(watchedControl.controlSystemType);
    const counterMeasureIdx = parseInt(watchedControl.counterMeasureType);
    if (!isNaN(controlSystemIdx) && !isNaN(counterMeasureIdx)) {
      setControlRating(controlRatingMatrix[watchedControl.counterMeasureType][watchedControl.controlSystemType]);
    } else {
      setControlRating(0);
    }
  }, [watchedControl.controlSystemType, watchedControl.counterMeasureType]);

  useEffect(() => {
    if (analysis) {
      var initialFieldStates = {};
      for (var i = 1; i < 3; i++) {
        if (Boolean(analysis["cogHaz" + i]) && Boolean(analysis["cogHaz" + (i + 1)])) initialFieldStates["cogHaz" + i] = analysis["cogHaz" + i];
        if (Boolean(analysis["operationalFactor" + i]) && Boolean(analysis["operationalFactor" + (i + 1)]))
          initialFieldStates["operationalFactor" + i] = analysis["operationalFactor" + i];
        if (Boolean(analysis["orgFactor" + i]) && Boolean(analysis["orgFactor" + (i + 1)]))
          initialFieldStates["orgFactor" + i] = analysis["orgFactor" + i];
      }
      setFieldStates(initialFieldStates);
    }
  }, [analysis]);

  const removeFactor = (name, prevNum, fullName) => {
    var newStates = Object.assign({}, fieldStates);
    delete newStates[name.slice(0, -1) + prevNum.toString()];
    setFieldStates(newStates);
    setValue(fullName, "");
  };

  const renderFactorHead = (label, icon) => (
    <Box sx={{ display: "flex" }}>
      {icon}
      <Typography sx={{ pl: 1, fontSize: "1rem" }} color="text.secondary" variant="subtitle2">
        {label}s
      </Typography>
    </Box>
  );

  const renderFactor = (num, name, optionset, remove, label) => (
    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
      <Box sx={{ pr: 1, width: 34, display: "flex", justifyContent: "flex-end" }}>
        <Typography sx={{ lineHeight: "34px" }}>{num}</Typography>
      </Box>
      <Box sx={{ flex: 1 }}>
        <AnnotatedInput
          label={`${label} ${num} notes`}
          placement={"left-start"}
          hasAnnotation={!!watchedControl[`${name}Notes`]}
          annotatedComponent={
            <SelectInput
              disabled={!canAnalyse}
              variant="standard"
              name={`controls.${idx}.${name}`}
              optionSet={optionset}
              label={null}
              control={control}
              sx={mb025Sx}
              helper={true}
              selectSx={{
                "&.MuiInputBase-root": { backgroundColor: (theme) => theme.palette.background.input, mt: 0 },
                "&.MuiInputBase-root:hover": { backgroundColor: (theme) => theme.palette.background.main },
                "&.MuiInputBase-root.Mui-disabled": { backgroundColor: "background.white" },
                "&.MuiInputBase-root.Mui-disabled:hover": { backgroundColor: (theme) => theme.palette.background.white },
              }}
            />
          }
          annotationComponent={
            <TextInput
              disabled={!canAnalyse}
              name={`controls.${idx}.${name}Notes`}
              label={`Enter notes`}
              control={control}
              multiline
              minRows={8}
              helper={true}
            />
          }
        />
      </Box>
      {remove && canAnalyse ? (
        <IconButton onClick={() => removeFactor(name, num - 1, `controls.${idx}.${name}`)} size="small">
          <RemoveIcon />
        </IconButton>
      ) : (
        <Box sx={{ width: 34 }}></Box>
      )}
    </Box>
  );

  const renderAddButton = (factor1Val, factor1Name, factor2Val, factor2Name) => {
    return !(Boolean(fieldStates[factor1Name]) && Boolean(fieldStates[factor2Name])) && canAnalyse ? (
      <Box sx={{ display: "flex", mt: 1 }}>
        <Button
          sx={{ ml: "auto", mr: "34px" }}
          size="small"
          variant="outlined"
          endIcon={<AddIcon />}
          disabled={!Boolean(factor1Val) || (Boolean(fieldStates[factor1Name]) && Boolean(factor1Val) && !Boolean(factor2Val))}
          onClick={() => setFieldStates(Object.assign({}, fieldStates, { [factor1Name]: factor1Val, [factor2Name]: factor2Val }))}
        >
          Add
        </Button>
      </Box>
    ) : (
      <Box sx={{ height: 24 }}></Box>
    );
  };

  return (
    <Box>
      <Divider />
      <Accordion
        expanded={controlsExpanded[`control${idx}`]}
        onChange={(e, expanded) => setControlsExpanded(Object.assign({}, controlsExpanded, { [`control${idx}`]: expanded }))}
        elevation={0}
      >
        <AccordionSummary sx={{ "& .MuiAccordionSummary-content": { alignItems: "center" } }} expandIcon={<ExpandMoreIcon />}>
          <Typography color="text.secondary" sx={{ pl: 1, flex: 1, fontWeight: 500, fontSize: "1.1rem" }}>
            Control {idx + 1}
          </Typography>
          {canAnalyse && (
            <Button sx={{ mr: 2 }} size="small" startIcon={<DeleteIcon />} onClick={(e) => confirmDelete(e, idx)}>
              Delete
            </Button>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ px: 3, pt: 1, pb: 3 }}>
          <Box
            sx={(theme) => {
              return { ...(fullPage ? { [theme.breakpoints.up("md")]: { display: "flex" } } : {}) };
            }}
          >
            <Box
              sx={(theme) => {
                return { ...(fullPage ? { [theme.breakpoints.up("md")]: { width: "50%", maxWidth: 0.5, pr: 2 } } : {}) };
              }}
            >
              <AnnotatedInput
                label={`${incidentFields["controlsAnalysis"].label} notes`}
                placement={"left-start"}
                hasAnnotation={!!watchedControl["controlNotes"]}
                annotatedComponent={
                  <SelectInput
                    disabled={!canAnalyse}
                    name={`controls.${idx}.control`}
                    optionSet={optionSets["controlsAnalysis"][riskCategory]}
                    label={incidentFields["controlsAnalysis"].label}
                    control={control}
                    sx={fullPage ? mb3Sx : mb2Sx}
                    helper={true}
                    size="small"
                  />
                }
                annotationComponent={
                  <TextInput
                    disabled={!canAnalyse}
                    name={`controls.${idx}.controlNotes`}
                    label={`Enter notes`}
                    control={control}
                    sx={{ mt: 2, ...mb2Sx }}
                    multiline
                    minRows={8}
                    helper={true}
                  />
                }
              />

              <AnnotatedInput
                label={`${incidentFields["errorType"].label} notes`}
                placement={"left-start"}
                hasAnnotation={!!watchedControl["errorTypeNotes"]}
                annotatedComponent={
                  <SelectInput
                    disabled={!canAnalyse}
                    name={`controls.${idx}.errorType`}
                    optionSet={optionSets["errorType"]}
                    label={incidentFields["errorType"].label}
                    control={control}
                    sx={fullPage ? mb3Sx : mb2Sx}
                    helper={true}
                    size="small"
                  />
                }
                annotationComponent={
                  <TextInput
                    disabled={!canAnalyse}
                    name={`controls.${idx}.errorTypeNotes`}
                    label={`Enter notes`}
                    control={control}
                    sx={{ mt: 2, ...mb2Sx }}
                    multiline
                    minRows={8}
                    helper={true}
                  />
                }
              />

              <AnnotatedInput
                label={`${incidentFields["controlEnablement"].label} notes`}
                placement={"left-start"}
                hasAnnotation={!!watchedControl["controlEnablementNotes"]}
                annotatedComponent={
                  <ToggleButtonInput
                    disabled={!canAnalyse}
                    name={`controls.${idx}.controlEnablement`}
                    label={incidentFields["controlEnablement"].label}
                    options={controlEnablemnt}
                    control={control}
                    sx={mb2Sx}
                    helper={true}
                    size="small"
                  />
                }
                annotationComponent={
                  <TextInput
                    disabled={!canAnalyse}
                    name={`controls.${idx}.controlEnablementNotes`}
                    label={`Enter notes`}
                    control={control}
                    sx={{ mt: 2, ...mb2Sx }}
                    multiline
                    minRows={8}
                    helper={true}
                  />
                }
              />

              <Box>
                <ControlRating controlRating={controlRating} />

                <Box sx={{ mr: 15 }}>
                  <SelectInput
                    disabled={!canAnalyse}
                    name={`controls.${idx}.controlSystemType`}
                    control={control}
                    label={incidentFields["controlSystemType"].label}
                    optionSet={controlSystemTypeOptions}
                    sx={fullPage ? mb3Sx : mb2Sx}
                    helper={true}
                    size="small"
                  />
                  <SelectInput
                    disabled={!canAnalyse}
                    name={`controls.${idx}.counterMeasureType`}
                    control={control}
                    label={incidentFields["counterMeasureType"].label}
                    optionSet={counterMeasureTypeOptions}
                    sx={mb2Sx}
                    size="small"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={(theme) => {
                return { ...(fullPage ? { [theme.breakpoints.up("md")]: { width: "50%", maxWidth: 0.5, pl: 2 } } : {}) };
              }}
            >
              {optionSets["cognitiveHazard"][watchedControl.errorType] && (
                <Box sx={{ mb: 1 }}>
                  {renderFactorHead(incidentFields["cognitiveHazard"].label, <CognitiveIcon color="action" />)}

                  {renderFactor(
                    1,
                    "cogHaz1",
                    optionSets["cognitiveHazard"][watchedControl.errorType],
                    false,
                    incidentFields["cognitiveHazard"].label
                  )}

                  {Boolean(fieldStates.cogHaz1) &&
                    renderFactor(
                      2,
                      "cogHaz2",
                      optionSets["cognitiveHazard"][watchedControl.errorType],
                      !fieldStates.cogHaz2,
                      incidentFields["cognitiveHazard"].label
                    )}

                  {Boolean(fieldStates.cogHaz2) &&
                    renderFactor(
                      3,
                      "cogHaz3",
                      optionSets["cognitiveHazard"][watchedControl.errorType],
                      true,
                      incidentFields["cognitiveHazard"].label
                    )}

                  {renderAddButton(watchedControl.cogHaz1, "cogHaz1", watchedControl.cogHaz2, "cogHaz2")}
                </Box>
              )}

              <Box sx={{ mb: 1 }}>
                {renderFactorHead(incidentFields["operationalFactor"].label, <OperationalIcon color="action" />)}

                {renderFactor(1, "operationalFactor1", optionSets["operationalFactor"], false, incidentFields["operationalFactor"].label)}

                {Boolean(fieldStates.operationalFactor1) &&
                  renderFactor(
                    2,
                    "operationalFactor2",
                    optionSets["operationalFactor"],
                    !fieldStates.operationalFactor2,
                    incidentFields["operationalFactor"].label
                  )}

                {Boolean(fieldStates.operationalFactor2) &&
                  renderFactor(3, "operationalFactor3", optionSets["operationalFactor"], true, incidentFields["operationalFactor"].label)}

                {renderAddButton(watchedControl.operationalFactor1, "operationalFactor1", watchedControl.operationalFactor2, "operationalFactor2")}
              </Box>

              <Box>
                {renderFactorHead(incidentFields["organisationalFactor"].label, <OrganisationIcon color="action" />)}

                {renderFactor(1, "orgFactor1", optionSets["organisationalFactor"], false, incidentFields["organisationalFactor"].label)}

                {Boolean(fieldStates.orgFactor1) &&
                  renderFactor(
                    2,
                    "orgFactor2",
                    optionSets["organisationalFactor"],
                    !fieldStates.orgFactor2,
                    incidentFields["organisationalFactor"].label
                  )}

                {Boolean(fieldStates.orgFactor2) &&
                  renderFactor(3, "orgFactor3", optionSets["organisationalFactor"], true, incidentFields["organisationalFactor"].label)}

                {renderAddButton(watchedControl.orgFactor1, "orgFactor1", watchedControl.orgFactor2, "orgFactor2")}
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
