import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Routes from "./Routes.js";
import AppBar from "./app/AppBar";
import MainMenu from "./app/MainMenu";
import authService from "./auth/AuthorizeService";
import authState from "./auth/authState";
import permissionsState from "./auth/permissionsState";
import snackbarState from "./app/snackbarState";
import helpState from "./help/helpState";
import fetchSimple from "./services/fetchSimple";
import { mainMenuWidth, detailDrawerSize } from "./app/appConstants";
import Loader from "./app/Loader.js";
import MasterDetailPanel from "./app/MasterDetailPanel.js";
import HelpDrawer from "./help/HelpDrawer.js";

export default function App(props) {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [mainMenuOpen, setMainMenuOpen] = useState(lgUp);
  const [auth, setAuth] = useRecoilState(authState);
  const [permissions, setPermissions] = useRecoilState(permissionsState);
  const [snackbar, setSnackbar] = useRecoilState(snackbarState);
  const help = useRecoilValue(helpState);

  useEffect(() => {
    async function populateRecoilState() {
      const [isAuthenticated, usr, token] = await Promise.all([authService.isAuthenticated(), authService.getUser(), authService.getAccessToken()]);
      setAuth({
        authenticated: isAuthenticated,
        user: usr,
        token: token,
        fetching: false,
      });
    }

    App.subscription = authService.subscribe(() => populateRecoilState());
    populateRecoilState();
    return function cleanup() {
      authService.unsubscribe(App.subscription);
    };
  }, [setAuth]);

  useEffect(() => {
    function fetchPermissions() {
      fetchSimple("/api/permissions", "GET", auth.token, null, setPermissions);
    }
    fetchPermissions();
  }, [auth, setPermissions]);

  const toggleMainMenu = () => {
    setMainMenuOpen(!mainMenuOpen);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar(false);
  };

  return auth.fetching ? (
    <Loader />
  ) : (
    <Box
      sx={{
        bgcolor: "background.greyMid",
        position: "relative",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <AppBar
        toggleMainMenu={toggleMainMenu}
        authenticated={auth.authenticated}
        showTenantSelection={auth.user?.role === "sysadmin"}
        mainMenuOpen={auth.authenticated && mainMenuOpen}
        lgUp={lgUp}
      />

      <Box
        sx={(theme) => {
          return {
            transition: () =>
              theme.transitions.create(["marginLeft"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            marginLeft: 0,
            ...(auth.authenticated &&
              mainMenuOpen && {
                [`${theme.breakpoints.up("lg")}`]: { marginLeft: `${mainMenuWidth}px` },
                transition: (theme) =>
                  theme.transitions.create(["marginLeft"], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
              }),
          };
        }}
      >
        <MasterDetailPanel detailsOpen={!!help} drawerSize={detailDrawerSize}>
          <Routes />
        </MasterDetailPanel>
        <HelpDrawer drawerSize={detailDrawerSize} />
      </Box>

      <Snackbar
        open={Boolean(snackbar)}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert elevation={6} onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {auth.authenticated && permissions.granted && <MainMenu toggleMainMenu={toggleMainMenu} mainMenuOpen={mainMenuOpen} />}
    </Box>
  );
}
