import React from "react";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function IncidentInfoAccordion({ title, children, defaultExpanded }) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      elevation={0}
      sx={(theme) => {
        return {
          border: 0,
          bgcolor: "background.greyLight",
          //borderTop: `1px solid ${theme.palette.divider}`,
          borderBottom: `1px solid ${theme.palette.divider}`,
          "&:last-child": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
          "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 48,
          },
          "& .MuiDivider-root": {
            borderColor: "background.greyLight",
          },
        };
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        sx={{
          bgcolor: "background.greyLight",
          "& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content": {
            my: 1,
            ml: 1,
            fontWeight: 500,
          },
          borderBottom: 0,
          // "& .MuiAccordionSummary-content p": {
          //   color: (theme) => theme.palette.primary.main,
          //   fontWeight: 500,
          // },
        }}
      >
        <Typography color="text.secondary" variant="overline" sx={{ lineHeight: 1.5, fontWeight: 500, fontSize: "0.9rem" }}>
          {title}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails
        sx={{
          p: 3
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
