import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { useDrag, useDrop } from "react-dnd";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import DragIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CreateChildrenIcon from "@mui/icons-material/CreateNewFolderOutlined";
import EditChildrenIcon from "@mui/icons-material/PlaylistAdd";
import TextInput from "../formUtils/TextInput";
import { ItemTypes } from "./ItemTypes.js";

export const OptionSetValueItem = ({ id, optionSetValue, index, moveItem, isNested, control, handleDelete, setSelectedParent }) => {
  const dragRef = useRef(null);
  const previewRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.ITEM,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  //const opacity = isDragging ? 0 : 1;
  drag(dragRef);
  drop(preview(previewRef));

  //
  //{text}
  //</div>

  return (
    <Paper
      ref={previewRef}
      data-handler-id={handlerId}
      variant="outlined"
      sx={{
        backgroundColor: isDragging ? "background.greenLight" : optionSetValue.isSubhead ? "background.mainLight" : "background.white",
        mx: 2,
        my: 1,
      }}
    >
      <ListItem
        value={optionSetValue.id}
        sx={{ pr: isNested ? 10 : 6 }}
        secondaryAction={
          <Box>
            {(optionSetValue.isSubhead || optionSetValue.canDelete) && (
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                <DeleteIcon />
              </IconButton>
            )}
            {!optionSetValue.isSubhead && (
              <Controller
                name={`optionsetValues.${index}.active`}
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    color="secondary"
                    size="small"
                    sx={{ ml: 1 }}
                    edge="end"
                  />
                )}
              />
            )}
            {!optionSetValue.isSubhead && isNested && (
              <IconButton edge="end" aria-label="delete" sx={{ ml: 1 }} onClick={() => setSelectedParent(optionSetValue)}>
                {optionSetValue.hasChildren ? <EditChildrenIcon /> : <CreateChildrenIcon />}
              </IconButton>
            )}
          </Box>
        }
      >
        <ListItemIcon ref={dragRef} sx={{ cursor: "grab", minWidth: optionSetValue.isSubhead ? 40 : 56 }}>
          <DragIcon />
        </ListItemIcon>
        <ListItemText disableTypography sx={{ my: 0, mr: 1, mb: "-3px" }}>
          <TextInput
            name={`optionsetValues.${index}.label`}
            control={control}
            helper={true}
            size="medium"
            variant="standard"
            sx={{
              "& .MuiFormControl-root .MuiInputBase-root": {
                ...(optionSetValue.isSubhead ? { fontWeight: 500, color: "primary.main" } : {}),
                backgroundColor: "transparent",
              },
              "& .MuiFormControl-root .MuiInputBase-root:hover": {
                backgroundColor: (theme) => theme.palette.background.main,
              },
              "& .MuiFormControl-root .MuiInputBase-root:before": {
                borderColor: "transparent",
              },
              "& .MuiFormControl-root .MuiInputBase-root:hover:before": {
                borderColor: "inherit",
              },
            }}
          />
        </ListItemText>
      </ListItem>
    </Paper>
  );
};
