import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import localforage from "localforage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Fade from "@mui/material/Fade";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CloseIcon from "@mui/icons-material/Close";
import AnalysisIcon from "@mui/icons-material/Insights";
import TextInput from "../formUtils/TextInput";
import SelectInput from "../formUtils/SelectInput";
import RadioInput from "../formUtils/RadioInput";
import ToggleButtonInput from "../formUtils/ToggleButtonInput";
import {
  yupInputRequired,
  yupSelectionRequired,
  yupSelectionRequiredIfOtherFieldFalse,
  yupRequiredIfboolAndString,
} from "../formUtils/validationRules";
import { mb1Sx, mb2Sx, incidentFields, sifOptions } from "../app/appConstants";
import IncidentDetailsPanel from "./IncidentDetailsPanel";
import useOptionSets from "../optionsets/useOptionSets";
import snackbarState from "../app/snackbarState";
import Label from "../app/Label";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import IncidentInfo from "./IncidentInfo";
import permissionsState from "../auth/permissionsState";

const questionnaireDescriptions = {
  76: "Does the incident specifically involve an attack/bite/sting by an animal, reptile, insect of a species/breed/size capable of inflicting SIF harm? (Includes venomous stings/bites to a person with an allergic reaction with no epi-pen or anti-venom available.)",
  94: "Does the incident specifically involve a violent attack or threat of violent attack by a person(s) with a weapon, or without a weapon but sufficient force to require hospitalisation? (Includes the perception of threat of a violent attack toward a team member)",
  107: "Does the incident specifically involve a person's head entering a small space not designed for people to work in and/or has poor ventilation and in which a hazardous atmosphere can quickly develop or engulfment is possible?",
  118: (
    <Box>
      <Typography sx={{ mb: 2 }} color="text.secondary" variant="body2">
        {"Does the incident specifically involve any of the following?"}
      </Typography>
      <ul>
        <li>{"Contact with live electrical equipment"}</li>
        <li>{"Coming within 3m of high voltage electrical infrastructure"}</li>
      </ul>
    </Box>
  ),
  133: "Does the incident specifically involve any symptoms reported of dehydration, heat rash, heat cramps, fainting, heat exhaustion, or heat stroke?",
  143: "Does the incident specifically involve an object that falls from height under its own weight and comes to rest in a location that could be occupied by a person with sufficient mass and speed (refer to dropped object calculator) or sharp point capable of piercing? FxMxA - test using dropped object calculator. Includes load securement exposing a person to a dropped load.",
  164: "Does the incident specifically involve fire with the potential for rapid propagation without adequate detection or suppression measures to cause SIF harm?",
  180: (
    <Box>
      <Typography sx={{ mb: 2 }} color="text.secondary" variant="body2">
        {"Does the incident specifically involve any of the following?"}
      </Typography>
      <ul>
        <li>{"Exposure to harmful chemical, radiological or biological agents"}</li>
        <li>{"Exposure to hazardous substance of sufficient quantity, temperature, pressure, radiation, or toxicity could cause SIF harm"}</li>
        <li>{"Exposure to infectious substance, asbestos or legionella of sufficient concentration to cause SIF harm"}</li>
      </ul>
      <Typography sx={{ mb: 2 }} color="text.secondary" variant="body2">
        {"*note these last three are not generally acute impacts and should only be referenced if they are core risks to operations"}
      </Typography>
    </Box>
  ),
  193: "Does the incident specifically involve entanglement, crushing and cutting risks associated with contact with moving components of stationary equipment, or where guarding has been defeated or bypassed? This includes kickback or mishandling of a powered cutting device.",
  208: "Does the incident specifically involve being pinched between, caught between, in line of fire of, or struck by a moving object with a release of significant mass, energy, or velocity to cause SIF harm - usually in a horizontal plane (see Falling Objects for most vertical plane examples)? This includes Load Shifting Equipment and and human contact, rollover, collision, sideswipe or pedestrian contact with sufficient speed to cause SIF harm",
  229: "Does the incident specifically involve intended entry into out of depth water without flotation device or unintended entry into water with sufficient depth to cause drowning if individual is incapacitated?",
  239: "Does the incident specifically involve working under a suspended load or an uncontrolled crane movement, lifting, moving, aligning heavy loads with blocks, rigging, cables or a load securement failure (including dropped loads and working under suspended Loads)?",
  257: "Does the incident specifically involve a breakdown of critical aspects of control or isolation resulting in an unexpected release of or inadvertent contact with energy e.g. hydraulic, pneumatic, mechanical or other energy sources?",
  283: (
    <Box>
      <Typography sx={{ mb: 2 }} color="text.secondary" variant="body2">
        {"Does the incident specifically involve any of the following?"}
      </Typography>
      <ul>
        <li>{"Falling >1.2m"}</li>
        <li>{"Falling from a shorter height onto an impaling object"}</li>
        <li>{"Falling backwards (falling backwards can be on same level ground)"}</li>
        <li>{"Falling within 2m of an unbarricaded 1.2m drop"}</li>
        <li>{"Any activation of a Personal Fall Arrest System (PFAS)"}</li>
      </ul>
    </Box>
  ),
  602: "Does the incident specifically involve a collision between 2 or more rail vehicles with railway workers/contractors onboard or a collision between a rail vehicle and another vehicle (ie at level crossing) and a combined collision speed > 25kmh?",
  614: "Does the incident specifically involve a case when a rail vehicle has entered an occupied section of track without safe working authority or encounters a track blocked by a vehicle (ie at level crossing) and there is a credible risk of collision (as per collision definition)?",
  629: "Does the incident specifically involve any rail vehicle (including locomotive or track machine) with railway workers/contractors onboard derails at >25kmh OR any derailment onto an adjacent mainline/running line OR into a location where railway workers/contractors typically occupy?",
  307: "Does the incident specifically involve a motor vehicle airbag deployment? Any deployment of airbag signifies a collision of greater than 25kmh which is the usual trigger impact",
  331: "Does the incident specifically involve contact >40kmh or where the vehicle leaves the confines of its lane to avoid the animal?",
  347: "Does the incident specifically involve contact >25kph or 10kph for LSE (includes combined impact of head-on between two opposing vehicles, or front of vehicle contacts another object or vehicle)",
  371: "Does the incident specifically involve contact >25kph or 10kph for LSE (includes: any 2 vehicles moving in same direction touching sides, including mirrors; any 2 vehicles moving in opposite directions touching sides, including mirrors; any 2 vehicles impacting at an angle to each other)?",
  392: "Does the incident specifically involve contact with a person at any speed and in any situation?",
  423: "Does the incident specifically involve an object that is hit by or hits the vehicle with sufficient force that the passenger compartment is potentially or actually intruded?",
  443: "Does the incident specifically involve any unplanned movement of open or contained load that could result in loss of load or shift in centre of gravity resulting in handling difficulty?",
  455: "Does the incident specifically involve any loss of control of the vehicle from its intended path?",
  478: "Does the incident specifically involve any event where one or more wheels of a vehicle is not in contact with road surface?",
  498: "Does the incident specifically involve being forced off roadway, avoidance action, or wheels dropping off shoulder?",
  518: "Does the incident specifically involve an unbraked vehicle on uneven ground that is not under control and begins to move from its position?",
  531: "Does the incident specifically involve any event where more than two or more wheels on the same side of a moving vehicle substantially lose contact with road surface?",
  550: "Does the incident specifically involve an event >25kph or 10kph for LSE?",
  561: "Does the incident specifically involve disconnection from towing vehicle and/or loss of control of trailer?",
  579: "Does the incident specifically involve crossing into oncoming lane when unsafe to do so, or with no reason?",
  854: "Non Critical SKIP ONE IF",
};

const defaultValues = {
  id: "",
  clientId: "",
  name: "",
  reference: "",
  injuryType: "",
  businessDivision: "",
  eventTitle: "",
  incidentMechanism: "",
  eventDetails: "",
  actual: "",
  potential: "",
  date: null,
  time: null,
  location: "",
  locationAdditional: "",
  riskCategoryClient: "",
  eventOwnership: "",
  timeWithinShift: "",
  shiftPattern: "",
  riskCategory: "",
  fatalityRisk: "",
  sif: "",
  advancedEntry: false,
  riskCategoryQ: "",
  riskSubCategoryQ: "",
  highRiskQ: "",
  oneIfQ: "",
  oneIfDescQ: "",
  probabilityTestQ: "",
  situationalTestQ: "",
};

const incidentFormSchema = yup.object().shape({
  riskCategory: yupSelectionRequired,
  sif: yupInputRequired,
  riskCategoryQ: yupSelectionRequiredIfOtherFieldFalse("advancedEntry"),
  riskSubCategoryQ: yupRequiredIfboolAndString("advancedEntry", false, "riskCategoryQ", "0", yupSelectionRequired),
  highRiskQ: yupSelectionRequiredIfOtherFieldFalse("advancedEntry"),
  oneIfQ: yupRequiredIfboolAndString("advancedEntry", false, "highRiskQ", "yes", yupSelectionRequired),
  oneIfDescQ: yupRequiredIfboolAndString("advancedEntry", false, "oneIfQ", "yes", yupInputRequired),
  probabilityTestQ: yupRequiredIfboolAndString("advancedEntry", false, "oneIfQ", "yes", yupSelectionRequired),
  situationalTestQ: yupRequiredIfboolAndString("advancedEntry", false, "probabilityTestQ", "2", yupSelectionRequired),
});

export default function IncidentAssessment({ sx, incident, handlePhaseChange, processRowUpdate, handleClose, handleNavigate, fullPage, phaseView }) {
  const permissions = useRecoilValue(permissionsState);
  const canAssess = !permissions.fetching && permissions.granted[permissions.all.incidentAssess] && incident.state === "assessment";
  const optionSets = useOptionSets();
  const [, setSnackbar] = useRecoilState(snackbarState);
  const [questionnaireCategories, setQuestionnaireCategories] = useState(false);
  const [questionnaireSubCategories, setQuestionnaireSubCategories] = useState(false);
  const auth = useRecoilValue(authState);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useForm({
    resolver: yupResolver(incidentFormSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const riskCategoryQ = watch("riskCategoryQ");
  const riskSubCategoryQ = watch("riskSubCategoryQ");
  const highRiskQ = watch("highRiskQ");
  const oneIfQ = watch("oneIfQ");
  const probabilityTestQ = watch("probabilityTestQ");
  const situationalTestQ = watch("situationalTestQ");
  const riskCategory = watch("riskCategory");
  const sif = watch("sif");
  const advancedEntry = watch("advancedEntry");

  //const allValues = watch();

  useEffect(() => {
    localforage.getItem("entryType").then((val) => { if(val) setValue("advancedEntry", val.advanced); });
  }, [setValue]);

  useEffect(() => {
    if (incident) {
      reset(Object.assign({}, incident));
    }
  }, [incident, reset]);

  useEffect(() => {
    if (!!optionSets["riskCategory"]) {
      var noMotor = true;
      const cats = [];
      const subCats = [];
      optionSets["riskCategory"].forEach((c) => {
        if (c.label.startsWith("MV ")) {
          if (noMotor) {
            cats.push({ id: 0, label: "Motor Vehicle Use", value: "0", optionSetName: c.optionSetName + "Q" });
            noMotor = false;
          }
          subCats.push(Object.assign({}, c, { optionSetName: "riskSubCategoryQ" }));
        } else cats.push(Object.assign({}, c, { optionSetName: c.optionSetName + "Q" }));
      });
      setQuestionnaireCategories(cats);
      setQuestionnaireSubCategories(subCats);
    }
  }, [optionSets]);

  useEffect(() => {
    if (
      riskCategoryQ === 854 ||
      highRiskQ === "no" ||
      oneIfQ === "no" ||
      probabilityTestQ === 0 ||
      probabilityTestQ === 1 ||
      situationalTestQ === "no"
    ) {
      setValue("sif", "no");
    } else if (situationalTestQ === "yes" || probabilityTestQ === 3 || probabilityTestQ === 4) {
      setValue("sif", "yes");
    } else {
      setValue("sif", "");
    }
  }, [riskCategoryQ, highRiskQ, oneIfQ, probabilityTestQ, situationalTestQ, setValue]);

  useEffect(() => {
    setValue("riskCategory", riskCategoryQ === 0 ? riskSubCategoryQ : riskCategoryQ);
  }, [riskCategoryQ, riskSubCategoryQ, setValue]);

  const handleAnalyseClick = async (updatedIncident) => {
    setSnackbar({ severity: "info", message: "Marking incident for analysis" });
    await fetchSimple(`/api/incidents/flag-analysis`, "POST", auth.token, null, handlePhaseChange, updatedIncident);
    setSnackbar({ severity: "success", message: "Incident updated successfully" });
  };

  const handleIgnoreClick = async (updatedIncident) => {
    setSnackbar({ severity: "info", message: "Ignoring incident" });
    await fetchSimple(`/api/incidents/ignore-assessment`, "POST", auth.token, null, handlePhaseChange, updatedIncident);
    setSnackbar({ severity: "success", message: "Incident updated successfully" });
  };

  //   const handleSaveClick = async () => {
  // console.log(allValues);
  //   };

  const handleRiskCategoryChange = () => {
    setValue("riskSubCategoryQ", "");
    handleSubRiskCategoryChange();
  };

  const handleSubRiskCategoryChange = () => {
    setValue("highRiskQ", "");
    handleHighRiskChange();
  };

  const handleHighRiskChange = () => {
    setValue("oneIfQ", "");
    handleOneIfChange();
  };

  const handleOneIfChange = () => {
    setValue("oneIfDescQ", "");
    setValue("probabilityTestQ", "");
    handleProbabilityChange();
  };

  const handleProbabilityChange = () => {
    setValue("situationalTestQ", "");
  };

  const handleEntryTypeChange = (advanced) => {
    localforage.setItem("entryType", { advanced: advanced });
    setValue("advancedEntry", advanced);
  };

  return (
    <Box
      sx={{
        flex: 1,
        height: 1,
        width: 1,
        overflowY: "auto",
        borderLeft: 0,
        borderColor: "palette.divider",
        backgroundColor: "background.white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <ContentToolbar title="Incident Analysis" /> */}

      <Box sx={{ flex: "1 1 auto", overflowY: "auto", flexWrap: "wrap" }}>
        <Box sx={{ p: 3, flexBasis: "100%" }}>
          <IncidentInfo incident={incident} multiColumn />
        </Box>
        <Box>
          <Box
            sx={(theme) => {
              return { [theme.breakpoints.up("md")]: { display: "flex" } };
            }}
          >
            <Box
              sx={(theme) => {
                return { [theme.breakpoints.up("md")]: { py: 2, width: "50%", maxWidth: 0.5, backgroundColor: "background.greyLight" } };
              }}
            >
              <IncidentDetailsPanel incident={incident} processRowUpdate={processRowUpdate} />
            </Box>
            <Box
              sx={(theme) => {
                return { [theme.breakpoints.up("md")]: { py: 2, width: "50%", maxWidth: 0.5 } };
              }}
            >
              <Typography color="text.primary" variant="overline" sx={{ pl: 3, lineHeight: "48px", fontWeight: 500, fontSize: "0.9rem" }}>
                Incident Assesssment
              </Typography>

              {incident.state !== "assessment" ? (
                <Box sx={{ px: 3, py: 3 }}>
                      <Typography color="text.secondary" variant="overline" sx={{ lineHeight: 1.5 }}>
                        {incidentFields["riskCategory"]?.label}
                      </Typography>
                      <Typography variant="h6" sx={{ fontSize: "0.875rem", mb: 3 }}>
                        {!optionSets.fetching && optionSets.itemsById[incident.riskCategory.toString()]?.label}
                      </Typography>

                  {incident.sif === "no" ? (
                    <Fade in={true}>
                      <Box>
                        <Label sx={{ mb: 2, color: "text.secondary", fontWeight: 500 }}>Determination</Label>
                        <Alert severity="warning">
                          <AlertTitle>SIFp No</AlertTitle>
                          This event is unlikely to have serious or fatal risk potential
                        </Alert>
                      </Box>
                    </Fade>
                  ) : (
                    <Fade in={true}>
                      <Box>
                        <Label sx={{ mb: 2, color: "text.secondary", fontWeight: 500 }}>Determination</Label>
                        <Alert severity="success">
                          <AlertTitle>SIFp Yes</AlertTitle>
                          This event is very likely to have serious or fatal risk potential
                        </Alert>
                      </Box>
                    </Fade>
                  )}
                </Box>
              ) : (
                <form noValidate>
                  {advancedEntry ? (
                    <Box sx={{ p: 3 }}>
                      {!optionSets.fetching && (
                        <SelectInput
                          disabled={!canAssess}
                          optionSet={optionSets["riskCategory"]}
                          label={incidentFields["riskCategory"]?.label}
                          control={control}
                          sx={mb1Sx}
                        />
                      )}

                      <ToggleButtonInput name="sif" label={incidentFields["sif"].label} disabled={!canAssess} options={sifOptions} control={control} sx={mb1Sx} />

                      <Typography variant="body2" color="text.secondary">
                        Unsure of the SIPp status?{" "}
                        <Link sx={{ cursor: "pointer" }} href onClick={() => handleEntryTypeChange(false)}>
                          Take the questionnaire
                        </Link>
                      </Typography>
                    </Box>
                  ) : (
                    !!questionnaireCategories && (
                      <Box sx={{ p: 3 }}>
                        <Box>
                          <Label sx={{ mb: 2, color: "text.primary", fontWeight: 500 }}>1. Risk Category</Label>
                          <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="body2">
                            Does the event fall into one of these operational risk categories?
                          </Typography>
                          {!optionSets.fetching && (
                            <SelectInput
                              disabled={!canAssess}
                              optionSet={questionnaireCategories}
                              label={incidentFields["riskCategory"]?.label}
                              control={control}
                              sx={mb1Sx}
                              handleChange={handleRiskCategoryChange}
                            />
                          )}
                          {riskCategoryQ === 0 && (
                            <Fade in={true}>
                              <Box>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="body2">
                                  What type of Motor Vehicle incident?
                                </Typography>
                                <SelectInput
                                  optionSet={questionnaireSubCategories}
                                  label={"Motor vehicle incident type"}
                                  control={control}
                                  sx={mb1Sx}
                                  handleChange={handleSubRiskCategoryChange}
                                />
                              </Box>
                            </Fade>
                          )}
                          {((!!riskCategoryQ && riskCategoryQ !== 854) || (riskCategoryQ === 0 && riskSubCategoryQ)) && (
                            <Fade in={true}>
                              <Box>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="body2">
                                  {riskCategoryQ === 0
                                    ? questionnaireDescriptions[riskSubCategoryQ?.toString()]
                                    : questionnaireDescriptions[riskCategoryQ?.toString()]}
                                </Typography>
                                <RadioInput
                                  optionSet={[
                                    { optionSetLabel: "Yes no", optionSetName: "highRiskQ", value: "yes", label: "Yes" },
                                    { optionSetLabel: "Yes no", optionSetName: "highRiskQ", value: "no", label: "No" },
                                  ]}
                                  control={control}
                                  sx={mb1Sx}
                                  hideLabel
                                  handleChange={handleHighRiskChange}
                                />
                              </Box>
                            </Fade>
                          )}
                          {highRiskQ === "yes" && (
                            <Fade in={true}>
                              <Box>
                                <Label sx={{ mb: 2, color: "text.primary", fontWeight: 500 }}>2. One if test</Label>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="body2">
                                  {"If just one thing changed, could this event have resulted in a fatality or serious injury?"}
                                </Typography>
                                <RadioInput
                                  optionSet={[
                                    { optionSetLabel: "Yes no", optionSetName: "oneIfQ", value: "yes", label: "Yes" },
                                    { optionSetLabel: "Yes no", optionSetName: "oneIfQ", value: "no", label: "No" },
                                  ]}
                                  control={control}
                                  sx={mb1Sx}
                                  hideLabel
                                  handleChange={handleOneIfChange}
                                />
                              </Box>
                            </Fade>
                          )}
                          {oneIfQ === "yes" && (
                            <Fade in={true}>
                              <Box>
                                <Label sx={{ mb: 2, color: "text.primary", fontWeight: 500 }}>3. Probability test</Label>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="body2">
                                  {"What one thing would need to have changed?"}
                                </Typography>
                                <TextInput name="oneIfDescQ" label={"Describe the one thing"} multiline control={control} sx={mb1Sx} />
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="body2">
                                  How likely is that possibility?
                                </Typography>
                                <SelectInput
                                  optionSet={[
                                    {
                                      id: 4,
                                      optionSetLabel: "Likelihood",
                                      optionSetName: "probabilityTestQ",
                                      value: "4",
                                      label: "Highly probable (almost assured of occurring in the short term)",
                                    },
                                    {
                                      id: 3,
                                      optionSetLabel: "Likelihood",
                                      optionSetName: "probabilityTestQ",
                                      value: "3",
                                      label: "1 in 10 chance (likely in the short term)",
                                    },
                                    {
                                      id: 2,
                                      optionSetLabel: "Likelihood",
                                      optionSetName: "probabilityTestQ",
                                      value: "2",
                                      label: "1 in 100 chance (possible in the medium term)",
                                    },
                                    {
                                      id: 1,
                                      optionSetLabel: "Likelihood",
                                      optionSetName: "probabilityTestQ",
                                      value: "1",
                                      label: "1 in 1000 chance (possible, but very unlikely in the short to medium term)",
                                    },
                                    {
                                      id: 0,
                                      optionSetLabel: "Likelihood",
                                      optionSetName: "probabilityTestQ",
                                      value: "0",
                                      label: "A stretch of the imagination (very unlikely, like being struck by lightning)",
                                    },
                                  ]}
                                  label={"Likelihood"}
                                  control={control}
                                  sx={mb2Sx}
                                  handleChange={handleProbabilityChange}
                                />
                              </Box>
                            </Fade>
                          )}
                          {probabilityTestQ === 2 && (
                            <Fade in={true}>
                              <Box>
                                <Label sx={{ mb: 2, color: "text.primary", fontWeight: 500 }}>4. Situational test</Label>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="body2">
                                  {"Would a frontline operator be likely to readily agree this event had serious or fatal potential?"}
                                </Typography>
                                <RadioInput
                                  optionSet={[
                                    { optionSetLabel: "Yes no", optionSetName: "situationalTestQ", value: "yes", label: "Yes" },
                                    { optionSetLabel: "Yes no", optionSetName: "situationalTestQ", value: "no", label: "No" },
                                  ]}
                                  control={control}
                                  sx={mb1Sx}
                                  hideLabel
                                />
                              </Box>
                            </Fade>
                          )}
                          {(riskCategoryQ === 854 ||
                            highRiskQ === "no" ||
                            oneIfQ === "no" ||
                            probabilityTestQ === 0 ||
                            probabilityTestQ === 1 ||
                            situationalTestQ === "no") && (
                            <Fade in={true}>
                              <Box>
                                <Label sx={{ mb: 2, color: "text.primary", fontWeight: 500 }}>Determination</Label>
                                <Alert severity="warning">
                                  <AlertTitle>SIFp No</AlertTitle>
                                  This event is unlikely to have serious or fatal risk potential
                                </Alert>
                              </Box>
                            </Fade>
                          )}
                          {(situationalTestQ === "yes" || probabilityTestQ === 3 || probabilityTestQ === 4) && (
                            <Fade in={true}>
                              <Box>
                                <Label sx={{ mb: 2, color: "text.primary", fontWeight: 500 }}>Determination</Label>
                                <Alert severity="success">
                                  <AlertTitle>SIFp Yes</AlertTitle>
                                  This event is very likely to have serious or fatal risk potential
                                </Alert>
                              </Box>
                            </Fade>
                          )}
                          {!sif && (
                            <Typography sx={{ mt: 2 }} variant="body2" color="text.disabled">
                              Certain of the assessment?{" "}
                              <Link sx={{ cursor: "pointer" }} href onClick={() => handleEntryTypeChange(true)}>
                                Enter SIFp directly
                              </Link>
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )
                  )}
                </form>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {fullPage && <Divider />}

      {canAssess && (
        <Box
          sx={{
            p: 3,
            backgroundColor: fullPage ? "background.white" : "background.mainLight",
            borderTop: fullPage ? 0 : 1,
            borderColor: "divider",
            ...(fullPage ? { px: 3, pb: 3 } : { flex: "0 1 90px", boxShadow: 4, clipPath: "polygon(0% -20%, 100% -20%, 100% 100%, 0% 100%)" }),
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Button
              disabled={isSubmitting}
              startIcon={<CloseIcon />}
              color="error"
              variant="contained"
              size="large"
              onClick={handleSubmit(handleIgnoreClick)}
            >
              Ignore
            </Button>
            ,
            <Button
              sx={{ ml: "auto" }}
              disabled={isSubmitting || !isDirty || sif === "" || riskCategory === ""}
              startIcon={<AnalysisIcon />}
              color="success"
              variant="contained"
              size="large"
              onClick={handleSubmit(handleAnalyseClick)}
            >
              {!!phaseView ? "Flag for analysis" : "Analyse"}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
// {/* <Button sx={{ml: "auto", backgroundColor:"background.white"}} disabled={isSubmitting || !isDirty} startIcon={<SaveIcon />} color="primary" variant="outlined" size="large" onClick={handleSaveClick}>
//                 {"Save"}
//               </Button>, */}
