import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader() {
  return (
    <Box sx={{
      display:"flex",
      justifyContent:"center",
      p: 8
  }}><CircularProgress color="primary" disableShrink /></Box>
  );
}
