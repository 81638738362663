import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextInput from "../formUtils/TextInput";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import authState from "../auth/authState";
import { yupInputRequired } from "../formUtils/validationRules";
import snackbarState from "../app/snackbarState";
import fetchSimple from "../services/fetchSimple";
import { mb3Sx } from "../app/appConstants";

const newUserFormSchema = yup.object().shape({
  name: yupInputRequired,
  systemName: yupInputRequired
});

const defaultValues = {
  name: "",
  systemName: "",
  isNew: true
};

export default function NewEditTenant({ open, setClose, tenantToEdit, saveCallback }) {
  const auth = useRecoilValue(authState);
  const [, setSnackbar] = useRecoilState(snackbarState);
  const [fetching, setFetching] = useState({ fetching: false });

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    watch
  } = useForm({
    resolver: yupResolver(newUserFormSchema),
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if(tenantToEdit) {
      reset({
        id: tenantToEdit.id,
        name: tenantToEdit.name,
        systemName: tenantToEdit.systemName,
        isNew:  false
      });
    };
  }, [tenantToEdit, reset]);

  useEffect(() => {
    if(open && setFocus) setTimeout(() => setFocus("name"), 500);
  }, [open, setFocus]);

  const handleClose = () => {
    reset(defaultValues);
    setClose();
  };

  async function saveTenant(data) {

    await fetchSimple("/api/tenants", tenantToEdit ? "PUT" : "POST", auth.token, setFetching, saveCallback, data)
      .then(() => {
        setSnackbar({ severity: "success", message: "Tenant updated successfully" });
      })
      .catch(() => setSnackbar({ severity: "error", message: "Something went wrong. Please try again" }))
      .finally(() => { 
        setFetching({ fetching: false }); 
        handleClose();
      });

  }

  const isNew = watch("isNew");

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth keepMounted>
      <form onSubmit={handleSubmit(saveTenant)} noValidate>
        <Toolbar>
          <DialogTitle variant="h6" sx={{ pl: 0, flex: 1 }}>
            {tenantToEdit ? "Edit " + tenantToEdit.name : "Add Tenant"}
          </DialogTitle>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent>
          <TextInput disabled={!isNew} sx={mb3Sx} name="systemName" label="Tenant key" control={control} helper="Lower case, no whitespace, words separated by hyphens. Example: my-tenant"/>
          <TextInput name="name" label="Tenant name" control={control} />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button disabled={fetching.fetching} type="submit" startIcon={<SaveIcon />} variant="contained" color="secondary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
