import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useDropzone } from "react-dropzone";
import { v4 as guid } from "uuid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGridPremium, GridRowModes, GridActionsCellItem } from "@mui/x-data-grid-premium";
import Submit from "@mui/icons-material/Done";
import Delete from "@mui/icons-material/DeleteOutlined";
import ScrollContainer from "../app/ScrollContainer";
import IncidentEntryToolbar from "./IncidentEntryToolbar";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import snackbarState from "../app/snackbarState";
import selectedClientState from "../clients/selectedClientState";
import TimePickerInputCell from "./TimePickerInputCell";
import { renderTime } from "./gridUtils";
import { acceptedFileTypes, maxUploadSize } from "../app/appConstants";
import { Typography } from "@mui/material";
import permissionsState from "../auth/permissionsState";

function updateSelectionStats(incident, selData) {
  selData.total = selData.total + 1;

  selData.deleteIds.push(incident.id);
  selData.delete = selData.delete + 1;

  // if (user.force2fa)
  //     selData.unEnforce2fa = selData.unEnforce2fa + 1;
  // else
  //     selData.enforce2fa = selData.enforce2fa + 1;

  if (incident.reference && Boolean(incident.date) && Boolean(incident.time) && incident.eventDetails && incident.eventTitle) {
    selData.submit = selData.submit + 1;
    selData.submitIds.push(incident.id);
  }
}

export default function IncidentEntryPage(props) {
  const permissions = useRecoilValue(permissionsState);
  const [incidents, setIncidents] = useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [fetching, setFetching] = useState({ fetching: false });
  const [pageSize, setPageSize] = React.useState(20);
  const [, setSnackbar] = useRecoilState(snackbarState);
  const auth = useRecoilValue(authState);
  const isClientRole = auth.user?.role === "client";
  const selectedClient = useRecoilValue(selectedClientState);
  const [densityButtonEl, setDensityButtonEl] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectionStats, setSelectionStats] = useState({
    delete: 0,
    submit: 0,
    total: 0,
  });

  useEffect(() => {
    async function fetchIncidents() {
      await fetchSimple(`/api/incidents/draft${selectedClient ? "/" + selectedClient.id : ""}`, "GET", auth.token, setFetching, setIncidents).finally(
        () => setFetching({ fetching: false })
      );
    }
    if (selectedClient || isClientRole) {
      fetchIncidents();
    } else {
      setIncidents([]);
    }
  }, [auth.token, selectedClient, isClientRole]);

  function selectionChanged(ids) {
    var selData = {
      delete: 0,
      deleteIds: [],
      submit: 0,
      submitIds: [],
      total: 0,
    };

    if (ids.length === 0) {
      setSelectionStats(selData);
    } else {
      const selectedIds = new Set(ids);
      const selectedRowData = incidents.filter((row) => selectedIds.has(row.id));
      for (var i = 0; i < selectedRowData.length; i++) {
        updateSelectionStats(selectedRowData[i], selData);
      }
      setSelectionStats(selData);
    }
    setSelectedIds(ids);
  }

  const addIncident = () => {
    const id = guid();
    setIncidents((oldRows) => [
      ...oldRows,
      {
        id,
        clientId: isClientRole ? "" : selectedClient.id,
        name: "",
        reference: "",
        injuryType: "",
        businessDivision: "",
        eventTitle: "",
        incidentMechanism: "",
        eventDetails: "",
        actual: "",
        potential: "",
        date: null,
        time: null,
        location: "",
        locationAdditional: "",
        riskCategoryClient: "",
        eventOwnership: "",
        timeWithinShift: "",
        shiftPattern: "",
        riskCategory: "",
        fatalityRisk: "",
        state: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  const processRowUpdateError = () => {
    setSnackbar({ severity: "error", message: "An error occured saving the incident" });
  };

  const processRowUpdate = async (newRow) => {
    // post the new row
    var rowPost = Object.assign({}, newRow);
    rowPost.clientId = isClientRole ? undefined : newRow.clientId;
    return await fetchSimple("/api/incidents/draft", "POST", auth.token, setFetching, null, rowPost).then((data) => {
      setFetching({ fetching: false });
      const updatedRow = { ...data, isNew: false };
      if (updatedRow.id) setIncidents(incidents.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    });
  };

  async function deleteIncident(incident) {
    var payload = { ids: Boolean(incident) ? [incident.id] : selectionStats.deleteIds };
    setSnackbar({ severity: "info", message: "Deleting incidents" });
    await fetchSimple("/api/incidents/delete-drafts", "POST", auth.token, null, null, payload).then((data) => {
      if (!Boolean(incident)) selectionChanged([]);
      setIncidents(incidents.filter((row) => !data[row.id]));
      setSnackbar({ severity: "success", message: "Incidents deleted successfully" });
    });
  }

  async function submitIncident(incident) {
    var payload = { ids: Boolean(incident) ? [incident.id] : selectionStats.submitIds };
    setSnackbar({ severity: "info", message: "Submitting incidents for assessment" });
    await fetchSimple("/api/incidents/request-assessment", "POST", auth.token, null, null, payload).then((data) => {
      if (!Boolean(incident)) selectionChanged([]);
      setIncidents(incidents.filter((row) => !data[row.id]));
      setSnackbar({ severity: "success", message: "Incidents submitted successfully" });
    });
  }

  const renderTimePicker = (params) => <TimePickerInputCell {...params} />;

  async function completeUpload(appFile) {
    await fetchSimple(`/api/incidents/draft${selectedClient ? "/" + selectedClient.id : ""}`, "GET", auth.token, setFetching, setIncidents).finally(
      () => setFetching({ fetching: false })
    );
  }

  function errorUpload(msg) {
    setSnackbar({ severity: "error", message: msg.constructor === Array ? msg[0].errors[0].message : msg });
    setFetching({ fetching: false });
  }

  async function postFile(file) {
    const formData = new FormData();
    formData.append("file", file.file);
    var req = new XMLHttpRequest();
    // req.upload.addEventListener("progress", (event) =>
    //   progressHandler(event, file, idx)
    // );
    req.onreadystatechange = function (aEvt) {
      if (req.readyState === 4) {
        if (req.status === 200) completeUpload(JSON.parse(req.responseText));
        else if (req.status === 401) errorUpload("Unauthorised. Please log out, then log back in");
        else errorUpload(`An unexpected error occured uploading ${file.file.name}. Please try again`);
      }
    };
    req.open("POST", `/api/incidents/upload/${selectedClient.id}`);
    req.setRequestHeader("Authorization", `Bearer ${auth.token}`);
    req.send(formData);
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) setFetching({ fetching: true });

    acceptedFiles.forEach((file) => {
      var fileData = {
        fileName: file.name,
        progress: 0,
        file: file,
        isNew: true,
      };
      postFile(fileData);
    });

    if (rejectedFiles.length > 0) errorUpload(rejectedFiles);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop,
    accept: acceptedFileTypes,
    maxSize: maxUploadSize,
  });

  return (
    <ScrollContainer>
      <Box {...getRootProps()} sx={{ display: "flex", height: "100%" }}>
        {isDragActive && (
          <Box sx={{ backgroundColor: "#ffffffcc", zIndex: 10, position: "absolute", width: 1, height: 1, display: "flex" }}>
            <Box
              sx={{ m: 4, borderColor: "secondary.main", borderStyle: "dashed", borderWidth: 4, width: 1, display: "flex", justifyContent: "center" }}
            >
              <Typography sx={{ margin: "auto", p: 12, color: "secondary.main", backgroundColor: "background.white", fontSize: "3rem" }}>
                Drop file here
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={(theme) => {
            return {
              flexGrow: 1,
              bgcolor: theme.palette.common.white,
              // "& .MuiDataGrid-row > .MuiDataGrid-checkboxInput": { paddingLeft: theme.spacing(3) },
              // [`${theme.breakpoints.down("sm")}`]: {
              //   "& .MuiDataGrid-row > .MuiDataGrid-checkboxInput": { paddingLeft: theme.spacing(2) },
              // },
              "& .MuiDataGrid-overlay": selectedClient ? null : { fontSize: 24 },
              "& .required-cell": {
                backgroundColor: "#f9cfd5",
                color: "#1a3e72",
                fontWeight: "600",
              },
            };
          }}
        >
          <DataGridPremium
            sx={{ border: 0 }}
            loading={fetching.fetching}
            rows={incidents}
            editMode="row"
            pageSize={pageSize}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={processRowUpdateError}
            rowsPerPageOptions={[20, 50, 100, 500]}
            checkboxSelection={true}
            disableSelectionOnClick
            //isRowSelectable={(params) => params.row.state === "draft"}
            selectionModel={selectedIds}
            onRowSelectionModelChange={selectionChanged}
            pagination
            disableColumnMenu
            disableIgnoreModificationsIfProcessingProps
            slots={{
              toolbar: IncidentEntryToolbar,
            }}
            slotProps={{
              toolbar: {
                isClientRole,
                addIncident,
                selectionStats,
                deleteIncident,
                submitIncident,
                setDensityButtonEl,
                open,
              },
              panel: {
                anchorEl: densityButtonEl,
                placement: "bottom-end",
              },
            }}
            experimentalFeatures={{ newEditingApi: true }}
            localeText={{
              noRowsLabel: selectedClient || isClientRole ? "No incidents" : "Select a client to get started",
            }}
            columns={[
              {
                field: "reference",
                headerName: "Reference",
                editable: true,
                minWidth: 100,
                cellClassName: (params) => (params.value ? null : "required-cell"),
              },
              {
                field: "eventTitle",
                headerName: "Event title",
                editable: true,
                minWidth: 100,
                cellClassName: (params) => (params.value ? null : "required-cell"),
              }, //optSet
              {
                field: "eventDetails",
                headerName: "Event details",
                editable: true,
                minWidth: 110,
                cellClassName: (params) => (params.value ? null : "required-cell"),
              },
              {
                field: "date",
                headerName: "Event Date",
                editable: true,
                type: "date",
                minWidth: 120,
                cellClassName: (params) => (params.value ? null : "required-cell"),
                valueGetter: ({ value }) => value && new Date(value),
                //renderCell: renderDate,
              }, // date
              {
                field: "time",
                headerName: "Event Time",
                editable: true,
                type: "dateTime",
                minWidth: 140,
                cellClassName: (params) => (params.value ? null : "required-cell"),
                valueGetter: ({ value }) => value && new Date(value),
                renderEditCell: renderTimePicker,
                renderCell: renderTime,
              }, // date
              {
                field: "injuryType",
                headerName: "Injury type",
                editable: true,
                minWidth: 100,
                flex: 0.8,
              }, //optSet
              {
                field: "businessDivision",
                headerName: "Business division",
                editable: true,
                minWidth: 100,
              }, //optSet
              {
                field: "incidentMechanism",
                headerName: "Incident Mechanism",
                editable: true,
                minWidth: 150,
                flex: 1,
              }, //optSet
              { field: "actual", headerName: "Actual", editable: true, minWidth: 100 }, //optSet
              {
                field: "potential",
                headerName: "Potential",
                editable: true,
                minWidth: 100,
              }, //optSet
              { field: "location", headerName: "Location 1", editable: true, minWidth: 100 },
              { field: "locationAdditional", headerName: "Location 2", editable: true, minWidth: 100 },
              {
                field: "riskCategoryClient",
                headerName: "Client risk category",
                editable: true,
                minWidth: 160,
                flex: 1,
              }, //optSet
              {
                field: "eventOwnership",
                headerName: "Event ownership",
                editable: true,
                minWidth: 140,
                flex: 0.8,
              }, //optSet
              { field: "timeWithinShift", headerName: "Time within shift", editable: true, minWidth: 140 },
              { field: "shiftPattern", headerName: "Shift pattern", editable: true, minWidth: 110 },
              // {
              //   field: "riskCategory",
              //   headerName: "Risk category",
              //   type: "singleSelect",
              //   valueOptions: optionSets["riskCategory"],
              //   valueFormatter: ({value}) => optionSetValueFormatter(value),
              //   editable: true,
              //   minWidth: 110,
              //   flex: 1,
              // }, //optSet
              // {
              //   field: "fatalityRisk",
              //   headerName: "Fatality risk",
              //   type: "singleSelect",
              //   valueOptions: optionSets["fatalityRisk"],
              //   valueFormatter: ({value}) => optionSetValueFormatter(value),
              //   editable: true,
              //   minWidth: 100,
              // }, //optSet
              {
                field: "id",
                headerName: "Actions",
                width: 160,
                disableColumnMenu: true,
                hideSortIcons: true,
                filterable: false,
                resizable: false,
                sortable: false,
                align: "right",
                type: "actions",
                renderHeader: (params) => <span></span>,
                getActions: (params) => {
                  const actions = [];
                  if (
                    params.row.reference &&
                    Boolean(params.row.date) &&
                    Boolean(params.row.time) &&
                    params.row.eventDetails &&
                    params.row.eventTitle &&
                    permissions.granted[permissions.all.incidentCreate]
                  ) {
                    //actions.push(<GridActionsCellItem icon={<Submit color="success" />} label="Submit" onClick={() => submitIncident(params)} />);
                    actions.push(
                      <Button
                        disableElevation
                        sx={{ px: 1, borderRadius: "15px", maxHeight: "30px" }}
                        startIcon={<Submit />}
                        color="secondary"
                        size="small"
                        variant="contained"
                        label="Import"
                        onClick={() => submitIncident(params)}
                      >
                        Submit
                      </Button>
                    );
                  }
                  actions.push(<GridActionsCellItem icon={<Delete />} label="Delete" onClick={() => deleteIncident(params)} />);
                  return actions;
                },
              },
            ]}
          />

          <input {...getInputProps()} />
          {/* <NewEditClient
            open={editClientOpen}
            setClose={handleUserModalClose}
            clientToEdit={clientToEdit}
            saveCallback={clientToEdit ? fetchClients : insertNewClient}
          /> */}
        </Box>
      </Box>
    </ScrollContainer>
  );
}
