import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { lighten } from "@mui/system/legacy/colorManipulator";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid-premium";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import permissionsState from "../auth/permissionsState";

export default function UserToolbar(props) {
  const permissions = useRecoilValue(permissionsState);
  const { selectionStats, usersCount, openNewUser, resendInvite, lockUser, unlockUser } = props;

  return (
    <Toolbar
      sx={{
        minHeight: 8,
        maxHeight: 8,
        borderBottom: 1,
        borderBottomColor: "border.main",
        backgroundColor: (theme) => {
          return selectionStats.total > 0 ? lighten(theme.palette.secondary.main, 0.9) : theme.palette.background.greyMid;
        },
      }}
    >
      <Typography variant="h6">{selectionStats.total > 0 && `${selectionStats.total} Users Selected`}</Typography>
      {selectionStats.total > 0 ? (
        <Fragment>
          {selectionStats.unlock > 0 && permissions.granted[permissions.all.userLock] && (
            <Badge color="primary" badgeContent={selectionStats.unlock}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="Unlock"
                sx={{ ml: 2, bgcolor: (theme) => theme.palette.common.white }}
                onClick={() => unlockUser()}
              >
                Unlock
              </Button>
            </Badge>
          )}
          {selectionStats.lock > 0 && permissions.granted[permissions.all.userLock] && (
            <Badge color="primary" badgeContent={selectionStats.lock}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="Lock"
                sx={{ ml: 2, bgcolor: (theme) => theme.palette.common.white }}
                onClick={() => lockUser()}
              >
                Lock
              </Button>
            </Badge>
          )}
          {/*selectionStats.enforce2fa > 0 && (
            <Badge color="primary" badgeContent={selectionStats.enforce2fa}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="Enforce 2FA"
                sx={{ ml: 2, bgcolor: (theme) => theme.palette.common.white }}
              >
                Enforce 2FA
              </Button>
            </Badge>
          )*/}
          {/*selectionStats.unEnforce2fa > 0 && (
            <Badge color="primary" badgeContent={selectionStats.unEnforce2fa}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="Unenforce 2FA"
                sx={{ ml: 2, bgcolor: (theme) => theme.palette.common.white }}
              >
                UnEnforce 2FA
              </Button>
            </Badge>
          )*/}
          {selectionStats.resendInvite > 0 && permissions.granted[permissions.all.userCreate] && (
            <Badge color="primary" badgeContent={selectionStats.resendInvite}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="Resend Invite"
                sx={{ ml: 2, bgcolor: (theme) => theme.palette.common.white }}
                onClick={() => resendInvite()}
              >
                Resend Invite
              </Button>
            </Badge>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton sx={{ ml: 2 }} />
          <GridToolbarDensitySelector sx={{ ml: 2 }} />
        </Fragment>
      )}
      <Typography sx={{ ml: "auto" }}>{`${usersCount} Total Users`}</Typography>
      {permissions.granted[permissions.all.userCreate] && (
        <Button variant="contained" aria-label="Add User" color="secondary" sx={{ ml: 2 }} onClick={openNewUser}>
          <Add sx={{ mr: 1 }} />
          Add User
        </Button>
      )}
    </Toolbar>
  );
}
