import React from "react";
import { Route, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AuthorizeRoute from "./auth/AuthorizeRoute";
// import TenantRoute from "./auth/TenantRoute";
// import AdminRoute from "./auth/AdminRoute";
import PermissionRoute from "./auth/PermissionRoute";
import SysAdminRoute from "./auth/SysAdminRoute";
import ApiAuthorizationRoutes from "./auth/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./auth/ApiAuthorizationConstants";
import UnauthenticatedLandingPage from "./anonymous/UnauthenticatedLandingPage";
import IncidentPage from "./incidents/IncidentPage";
import IncidentsPage from "./incidents/IncidentsPage";
import IncidentEntryPage from "./incidents/IncidentEntryPage";
import IncidentPhasePage from "./incidents/IncidentPhasePage";
import ClientsPage from "./clients/ClientsPage";
import TenantsPage from "./tenants/TenantsPage";
import OptionsetsPage from "./optionsets/OptionsetsPage";
import PageLoader from "./app/PageLoader";
import UsersPage from "./users/UsersPage";
import HelpPage from "./help/HelpPage";
import authState from "./auth/authState";
import permissionsState from "./auth/permissionsState";

export default function Routes(props) {
  const auth = useRecoilValue(authState);
  const permissions = useRecoilValue(permissionsState);

  const getWorkspaceRoutes = () => {
    if (auth.fetching) {
      return (
        <Route exact path="/">
          <PageLoader />
        </Route>
      );
    }
    if (auth.authenticated) {
      return (
        <AuthorizeRoute exact path="/">
          <IncidentsPage {...props} />
        </AuthorizeRoute>
      );
    } else {
      return (
        <Route exact path="/">
          <UnauthenticatedLandingPage {...props} />
        </Route>
      );
    }
  };

  return (
    <Switch>
      {getWorkspaceRoutes()}

      <AuthorizeRoute exact path="/help">
        <HelpPage {...props} />
      </AuthorizeRoute>

      <AuthorizeRoute exact path="/incident/:id">
        <IncidentPage {...props} />
      </AuthorizeRoute>

      {permissions.granted && (
        <AuthorizeRoute exact path="/import">
          <PermissionRoute permissionsRequired={[permissions.all.incidentBulkUpload]}>
            <IncidentEntryPage {...props} />
          </PermissionRoute>
        </AuthorizeRoute>
      )}

      {/* <AuthorizeRoute exact path="/assessment">
          <IncidentReviewPage {...props} />
        </AuthorizeRoute> */}

      {permissions.granted && (
        <AuthorizeRoute exact path="/assessment">
          <PermissionRoute permissionsRequired={[permissions.all.incidentAssess]}>
            <IncidentPhasePage {...props} phase={"assessment"} />
          </PermissionRoute>
        </AuthorizeRoute>
      )}

      {permissions.granted && (
        <AuthorizeRoute exact path="/analysis">
          <PermissionRoute permissionsRequired={[permissions.all.incidentAnalyse]}>
            <IncidentPhasePage {...props} phase={"analysis"} />
          </PermissionRoute>
        </AuthorizeRoute>
      )}

      {permissions.granted && (
        <AuthorizeRoute exact path="/actions">
          <PermissionRoute permissionsRequired={[permissions.all.incidentAction]}>
            <IncidentPhasePage {...props} phase={"actions"} />
          </PermissionRoute>
        </AuthorizeRoute>
      )}

      {permissions.granted && (
        <AuthorizeRoute exact path="/approval">
          <PermissionRoute permissionsRequired={[permissions.all.incidentApprove]}>
            <IncidentPhasePage {...props} phase={"approval"} />
          </PermissionRoute>
        </AuthorizeRoute>
      )}

      {permissions.granted && (
        <AuthorizeRoute exact path="/clients">
          <PermissionRoute permissionsRequired={[permissions.all.clientView]}>
            <ClientsPage {...props} />
          </PermissionRoute>
        </AuthorizeRoute>
      )}

      {permissions.granted && (
        <AuthorizeRoute exact path="/admin/users">
          <PermissionRoute permissionsRequired={[permissions.all.userView]}>
            <UsersPage {...props} />
          </PermissionRoute>
        </AuthorizeRoute>
      )}

      {permissions.granted && (
        <AuthorizeRoute exact path="/admin/optionsets">
          <PermissionRoute permissionsRequired={[permissions.all.optionsetView]}>
            <OptionsetsPage {...props} />
          </PermissionRoute>
        </AuthorizeRoute>
      )}

      <AuthorizeRoute exact path="/admin/tenants">
        <SysAdminRoute>
          <TenantsPage {...props} />
        </SysAdminRoute>
      </AuthorizeRoute>

      <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
    </Switch>
  );
}
