import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Box from "@mui/material/Box";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import ScrollContainer from "../app/ScrollContainer";
import TenantsToolbar from "./TenantsToolbar";
import NewEditTenant from "./NewEditTenant";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
//import snackbarState from "../app/snackbarState";

export default function TenantsPage(props) {
  const auth = useRecoilValue(authState);
  //const [, setSnackbar] = useRecoilState(snackbarState);
  const [tenants, setTenants] = useState([]);
  const [fetching, setFetching] = useState({ fetching: true });
  const [editTenantOpen, setEditTenantOpen] = useState(false);
  const [tenantToEdit, setTenantToEdit] = useState(null);

  async function fetchTenants() {
    await fetchSimple("/api/tenants", "GET", auth.token, setFetching, setTenants).finally(() => setFetching(false));
  }

  useEffect(() => {
    async function loadTenants() {
      await fetchSimple("/api/tenants", "GET", auth.token, setFetching, setTenants).finally(() => setFetching(false));
    }
    loadTenants();
  }, [auth.token, setTenants]);

  function insertNewTenant(newTenant) {
    var newTenants = Object.assign([], tenants);
    newTenants.push(newTenant);
    setTenants(newTenants);
  }

  function openNewTenant() {
    setEditTenantOpen(true);
  }

  function openEditTenant(row) {
    setTenantToEdit(row?.row);
    setEditTenantOpen(true);
  }

  function handleUserModalClose() {
    setEditTenantOpen(false);
    setTenantToEdit(null);
  }

  // async function disableTenant(tenant) {
  //   setSnackbar({ severity: "info", message: "Disabling tenant" });
  //   await fetchSimple(`/api/tenants/disable`, "POST", auth.token, null, null, tenant).finally(() => {
  //     fetchTenants();
  //     setSnackbar({ severity: "success", message: "Disablement complete" });
  //   });
  // }

  // async function activateTenant(tenant) {
  //   setSnackbar({ severity: "info", message: "Activating tenant" });
  //   await fetchSimple(`/api/tenants/activate`, "POST", auth.token, null, null, tenant).finally(() => {
  //     fetchTenants();
  //     setSnackbar({ severity: "success", message: "Tenant successfully activated" });
  //   });
  // }

  return (
    <ScrollContainer>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={(theme) => {
            return {
              flexGrow: 1,
              bgcolor: theme.palette.common.white,
              "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(3) },
              [`${theme.breakpoints.down("sm")}`]: {
                "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(2) },
              },
            };
          }}
        >
          <DataGridPremium
            sx={{ border: 0 }}
            loading={fetching.fetching}
            rows={tenants}
            getRowId={(row) => row.systemName}
            onRowClick={openEditTenant}
            hideFooter={true}
            components={{
              Toolbar: TenantsToolbar,
            }}
            componentsProps={{
              toolbar: {
                tenantsCount: tenants ? tenants.length : 0,
                openNewTenant: openNewTenant,
              },
            }}
            columns={[
              { field: "systemName", headerName: "Key", headerClassName: "left-cell", cellClassName: "left-cell", flex: 1 },
              { field: "name", headerName: "Name", flex: 1 },
              // {
              //   field: "active",
              //   headerName: "Active",
              //   type: "boolean",
              //   renderCell: (params) => {
              //     return params.value ? (
              //       <Done sx={{ color: (theme) => theme.palette.success.main }} />
              //     ) : (
              //       <Close sx={{ color: (theme) => theme.palette.text.disabled }} />
              //     );
              //   },
              // },
              // {
              //   field: "id",
              //   headerName: "Actions",
              //   width: 58,
              //   disableColumnMenu: true,
              //   hideSortIcons: true,
              //   filterable: false,
              //   resizable: false,
              //   sortable: false,
              //   align: "right",
              //   renderCell: (params) => (
              //     <TenantsMenu params={params} openEditTenant={openEditTenant}  />
              //   ),
              //   renderHeader: (params) => <span></span>,
              // },
            ]}
          />

          <NewEditTenant
            open={editTenantOpen}
            setClose={handleUserModalClose}
            tenantToEdit={tenantToEdit}
            saveCallback={tenantToEdit ? fetchTenants : insertNewTenant}
          />
        </Box>
      </Box>
    </ScrollContainer>
  );
}
