import React from "react";
import { useRecoilValue } from "recoil";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid-premium";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import permissionsState from "../auth/permissionsState";

export default function ClientsToolbar({ clientsCount, openNewClient }) {
  const permissions = useRecoilValue(permissionsState);
  return (
    <Toolbar
      sx={{
        minHeight: 8,
        maxHeight: 8,
        backgroundColor: "background.greyMid",
        borderBottom: 1,
        borderBottomColor: "border.main"
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton sx={{ ml: 2 }} />
      <GridToolbarDensitySelector sx={{ ml: 2 }} />

      <Typography sx={{ ml: "auto" }}>{`${clientsCount} Total Clients`}</Typography>
      {permissions.granted[permissions.all.clientCreate] && <Button variant="contained" aria-label="Add Client" color="secondary" sx={{ ml: 2 }} onClick={openNewClient}>
        <Add sx={{ mr: 1 }} />
        Add Client
      </Button>}
    </Toolbar>
  );
}
