import * as yup from "yup";

export const yupBitMaskSelectionRequired = yup.number().typeError("A selection is required").required("A selection is required");

export const yupPositiveRequired = yup.number().min(1, "A value is required");

export const yupNonNegativeRequired = yup.number().min(0, "A value is required");

//export const yupBooleanRequired = yup.boolean().nullable(true);

export const yupSwitchableBitMaskSelectionRequired = yup.number().min(1, "A selection is required").nullable(true);

export const yupInputRequired = yup.string().typeError("A value is required").required("Cannot be empty");

export const yupDateInputRequired = yup.date().typeError("A value is required").required("Cannot be empty").nullable();

export const yupObjectRequired = yup
  .object()
  .nullable()
  //.typeError("A value is required")
  .required("Cannot be empty");

export const yupSelectionRequired = yup.string().typeError("A selection is required").required("A selection is required");

export const yupBooleanRequired = yup.boolean().typeError("A selection is required").required("A selection is required");

export const yupBooleanRequiredIfOtherFieldNotNull = (otherFieldName) =>
  yup
    .boolean()
    .nullable()
    .when(otherFieldName, {
      is: (val) => val !== null,
      then: yupBooleanRequired,
    });

export const yupInputRequiredIfOtherFieldTrue = (otherFieldName) =>
  yup
    .string()
    .nullable()
    .when(otherFieldName, {
      is: (val) => {
        return val;
      },
      then: yupInputRequired,
    });

export const yupInputRequiredIfOtherFieldFalse = (otherFieldName) =>
  yup
    .string()
    .nullable()
    .when(otherFieldName, {
      is: (val) => {
        return val === false;
      },
      then: yupInputRequired,
    });

export const yupInputRequiredIfOtherFieldTrueAndHasChild = (otherFieldName, childFieldName) =>
  yup
    .string()
    .nullable()
    .when([otherFieldName, childFieldName], {
      is: (otherVal, childVal) => {
        return otherVal && !!childVal;
      },
      then: yupInputRequired,
    });

export const yupSelectionRequiredIfOtherFieldTrue = (otherFieldName) =>
  yup
    .string()
    .nullable()
    .when(otherFieldName, {
      is: (val) => {
        return val;
      },
      then: yupSelectionRequired,
    });

export const yupSelectionRequiredIfOtherFieldFalse = (otherFieldName) =>
  yup
    .string()
    .nullable()
    .when(otherFieldName, {
      is: (val) => {
        return val === false;
      },
      then: yupSelectionRequired,
    });

  export const yupRequiredIfboolAndString = (boolName, boolVal, stringName, stringVal, requiredFunc) =>
  yup
    .string()
    .nullable()
    .when([boolName, stringName], {
      is: (b, s) => {
        return !!b === boolVal && s === stringVal;
      },
      then: requiredFunc,
    });

export const yupEmailRequired = yup.string().email().typeError("A value is required").required("Cannot be empty");

export const yupNumberLessThan = (fieldName, fieldLabel) =>
  yup.number().typeError("A value is required").min(1, "A value is required").lessThan(yup.ref(fieldName), `Must be less than ${fieldLabel}`);

export const yupNumberMoreThan = (fieldName, fieldLabel) =>
  yup.number().typeError("A value is required").min(1, "A value is required").moreThan(yup.ref(fieldName), `Must be more than ${fieldLabel}`);
