export default function fetchSimple(
  apiRoute,
  method,
  token,
  setFetching,
  callback,
  payload
) {
  if(setFetching) setFetching({ fetching: true });

  var options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  if (payload) options.body = JSON.stringify(payload);

  return fetch(apiRoute, options)
    .then((results) => {
      try {
        return results.json();
      } catch (err) {
        console.log(err);
        return;
      }
    })
    .then((data) => {
      if(callback) { 
        callback(data);
      }
      return data;
    });
}
