import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import fetchSimple from "../services/fetchSimple";
import authState from "../auth/authState";
import Loader from "../app/Loader";
import IncidentViewContainer from "./IncidentViewContainer";
import IncidentDetailsPanel from "./IncidentDetailsPanel";
import IncidentAssessment from "./IncidentAssessment";
import IncidentAnalysis from "./IncidentAnalysis";
import IncidentActions from "./IncidentActions";
import IncidentApproval from "./IncidentApproval";
import { incidentStateTabs, analysisStates } from "../app/appConstants";

export default function IncidentTabbedView({
  incident,
  incidentFetching,
  processRowUpdate,
  handlePhaseChange,
  handleClose,
  handleNavigate,
  fullPage,
  hideTitle,
  phaseView,
}) {
  const auth = useRecoilValue(authState);
  const [visibleTab, setVisibleTab] = useState(incidentStateTabs[incident.state]);
  const [analysis, setAnalysis] = useState(false);
  const [analysisFetching, setAnalysisFetching] = useState(false);
  const phaseIndex = analysisStates.indexOf(incident.state);

  useEffect(() => {
    const fetchAnalysis = async (incidentId) => {
      setAnalysisFetching(true);
      await fetchSimple(`/api/analysis/${incidentId}`, "GET", auth.token, null, null, null).then((data) => {
        setAnalysis(Object.assign({}, { riskCategory: incident.riskCategory, id: incident.id, controls: data }));
        setAnalysisFetching(false);
      });
    };
    if (incident && incident.id && phaseIndex !== -1) {
      fetchAnalysis(incident.id);
    } else {
      setAnalysis(false);
    }
  }, [auth.token, incident, phaseIndex]);

  useEffect(() => {
    setVisibleTab(incidentStateTabs[incident.state]);
  }, [incident.state]);

  const handleTabChange = (e, newValue) => {
    setVisibleTab(newValue);
  };

  const renderPanel = (tabIdx, children) => (
    <Box
      hidden={visibleTab !== tabIdx}
      sx={(theme) => {
        return {
          ...(fullPage
            ? {}
            : {
                height: "calc(100vh - 172px)",
                [`${theme.breakpoints.up("sm")}`]: {
                  height: "calc(100vh - 188px)",
                },
              }),
        };
      }}
    >
      {children}
    </Box>
  );

  return (
    <IncidentViewContainer
      fullPage={fullPage}
      incident={incident}
      hideTitle={hideTitle}
      handleClose={handleClose}
      handleNavigate={handleNavigate}
      incidentFetching={incidentFetching}
    >
      <Tabs sx={{ minHeight: 60 }} value={visibleTab} onChange={handleTabChange}>
        <Tab sx={{ px: 3, py: 2.5 }} label="Summary" />
        <Tab sx={{ px: 3, py: 2.5 }} label="Assessment" />
        <Tab disabled={!analysis} sx={{ px: 3, py: 2.5 }} label="Analysis" />
        <Tab disabled={!analysis || phaseIndex < 1} sx={{ px: 3, py: 2.5 }} label="Actions" />
        <Tab disabled={!analysis || phaseIndex < 2} sx={{ px: 3, py: 2.5 }} label="Approval" />
      </Tabs>
      {renderPanel(0, <IncidentDetailsPanel incident={incident} />)}
      {renderPanel(
        1,
        <IncidentAssessment
          fullPage={fullPage}
          incident={incident}
          handlePhaseChange={handlePhaseChange}
          processRowUpdate={processRowUpdate}
          phaseView={phaseView}
        />
      )}
      {analysis &&
        renderPanel(
          2,
          analysisFetching ? (
            <Loader />
          ) : (
            <IncidentAnalysis
              fullPage={fullPage}
              incident={incident}
              analysis={analysis}
              handlePhaseChange={handlePhaseChange}
              processRowUpdate={processRowUpdate}
              phaseView={phaseView}
            />
          )
        )}
      {analysis &&
        renderPanel(
          3,
          analysisFetching ? (
            <Loader />
          ) : (
            <IncidentActions
              fullPage={fullPage}
              incident={incident}
              analysis={analysis}
              handlePhaseChange={handlePhaseChange}
              processRowUpdate={processRowUpdate}
              phaseView={phaseView}
            />
          )
        )}
      {analysis &&
        renderPanel(
          4,
          analysisFetching ? (
            <Loader />
          ) : (
            <IncidentApproval
              fullPage={fullPage}
              incident={incident}
              analysis={analysis}
              handlePhaseChange={handlePhaseChange}
              processRowUpdate={processRowUpdate}
              phaseView={phaseView}
            />
          )
        )}
    </IncidentViewContainer>
  );
}
