import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IncidentInfoAccordion from "./IncidentInfoAccordion";
import TextInput from "../formUtils/TextInput";
import { mb025Sx, incidentFields } from "../app/appConstants";
import Label from "../app/Label";

export default function IncidentAdditionalDetails({ control, handleBlur, defaultExpanded, incident, canEdit }) {
  return (
    <IncidentInfoAccordion defaultExpanded={defaultExpanded ?? true} title="Additional Details">
      {canEdit ? (
        <TextInput
          name="injuryType"
          label={incidentFields["injuryType"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["injuryType"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.injuryType}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="businessDivision"
          label={incidentFields["businessDivision"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["businessDivision"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.businessDivision}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="incidentMechanism"
          label={incidentFields["incidentMechanism"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["incidentMechanism"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.incidentMechanism}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="actual"
          label={incidentFields["actual"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["actual"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.actual}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="potential"
          label={incidentFields["potential"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["potential"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.potential}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="location"
          label={incidentFields["location"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["location"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.location}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="locationAdditional"
          label={incidentFields["locationAdditional"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["locationAdditional"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.locationAdditional}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="riskCategoryClient"
          label={incidentFields["riskCategoryClient"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["riskCategoryClient"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.riskCategoryClient}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="eventOwnership"
          label={incidentFields["eventOwnership"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["eventOwnership"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.eventOwnership}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="timeWithinShift"
          label={incidentFields["timeWithinShift"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["timeWithinShift"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.timeWithinShift}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="shiftPattern"
          label={incidentFields["shiftPattern"].label}
          control={control}
          sx={mb025Sx}
          handleBlur={handleBlur}
          size="small"
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["shiftPattern"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.shiftPattern}</Typography>
        </Box>
      )}
    </IncidentInfoAccordion>
  );
}
