import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ClientIcon from "@mui/icons-material/Business";
import CreatorEmailIcon from "@mui/icons-material/ContactMailOutlined";
import { renderDateTime } from "../formatUtils";
import Label from "../app/Label";

export default function IncidentInfo({ incident, multiColumn }) {
  return (
    <Box sx={{display:"flex", flexDirection: multiColumn ? "row" : "column"}}>
      <Box sx={{ width: multiColumn ? 0.5: 1}}>
        <Label>Client</Label>
        <Box sx={{ mb: 1, display: "flex" }}>
          <ClientIcon />
          <Typography sx={{ mb: 1, fontWeight: 400, ml: 1 }}>{incident.clientName}</Typography>
        </Box>

        <Label>Creator</Label>
        <Box sx={{ display: "flex" }}>
          <CreatorEmailIcon />
          <Typography sx={{ mb: 1, fontWeight: 400, ml: 1 }}>{incident.creatorEmail}</Typography>
        </Box>
      </Box>
      <Box sx={{ width: multiColumn ? 0.5: 1, pl: multiColumn? 3: 0}}>
        <Box>
          <Label>Created</Label>
          <Typography sx={{ mb: 1, fontWeight: 400 }}>{renderDateTime(incident.created)}</Typography>
        </Box>
        <Box>
          <Label>Modified</Label>
          <Typography sx={{ fontWeight: 400 }}>{renderDateTime(incident.modified)}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
