import React, { useState } from "react";
import { lighten } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import AnnotationChip from "./AnnotationChip";

export default function Annotation({ value }) {
  const [open, setOpen] = useState(false);

  const handleToggleAnnotation = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ flexBasis: open ? "100%" : 0 }}>
      {!open && <AnnotationChip handleClick={handleToggleAnnotation} hasAnnotation={true} />}
      {open && (
        <Grow in={open}>
          <Paper
            elevation={6}
            sx={(theme) => {
              return {
                mt: 1,
                ml: 6,
                mb: 4,
                py: 2,
                pl: 3,
                pr: 1,
                color: theme.palette.note.main,
                borderColor: lighten(theme.palette.note.main, 0.5),
                backgroundColor: lighten(theme.palette.note.main, 0.95),
              };
            }}
          >
            <IconButton sx={{ float: "right", ml: 3 }} onClick={handleToggleAnnotation}>
              <CloseIcon />
            </IconButton>
            <p>{value}</p>
          </Paper>
        </Grow>
      )}
    </Box>
  );
}
