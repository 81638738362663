import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextInput from "../formUtils/TextInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CognitiveIcon from "@mui/icons-material/PsychologyOutlined";
import OperationalIcon from "@mui/icons-material/EngineeringOutlined";
import OrganisationIcon from "@mui/icons-material/BusinessOutlined";
import YesIcon from "@mui/icons-material/Done";
import NoIcon from "@mui/icons-material/Close";
import UndoIcon from "@mui/icons-material/Undo";
import NotesIcon from "@mui/icons-material/ChatOutlined";
import HideNotesIcon from "@mui/icons-material/SpeakerNotesOffOutlined";
import { mb2Sx, incidentFields } from "../app/appConstants";
import Label from "../app/Label";
import useOptionSets from "../optionsets/useOptionSets";
import ControlRating from "./ControlRating";
import { controlRatingMatrix, controlEnablemnt, controlSystemTypeOptions, counterMeasureTypeOptions } from "./controlConstants";
// import CountermeasureSelect from "./CountermeasureSelect";
// import ControlTypeSelect from "./ControlTypeSelect";

export default function ControlApproval({
  idx,
  analysis,
  trigger,
  control,
  watch,
  setValue,
  controlsExpanded,
  setControlsExpanded,
  fullPage,
  canApprove,
}) {
  const optionSets = useOptionSets();
  const [noteStates, setNoteStates] = useState({});
  const [controlRating, setControlRating] = useState(0);
  const [improvedControlRating, setImprovedControlRating] = useState(0);

  // const errorType = watch(analysis.errorType`);

  const controlValues = watch(`controls.${idx}`);
  const controlSystemTypeImproved = watch(`controls.${idx}.controlSystemTypeImproved`);
  const counterMeasureTypeImproved = watch(`controls.${idx}.counterMeasureTypeImproved`);

  useEffect(() => {
    const controlSystemIdx = parseInt(controlSystemTypeImproved);
    const counterMeasureIdx = parseInt(counterMeasureTypeImproved);
    if (!isNaN(controlSystemIdx) && !isNaN(counterMeasureIdx)) {
      setImprovedControlRating(controlRatingMatrix[counterMeasureTypeImproved][controlSystemTypeImproved]);
    } else {
      setImprovedControlRating(0);
    }
  }, [controlSystemTypeImproved, counterMeasureTypeImproved]);

  useEffect(() => {
    const controlSystemIdx = parseInt(analysis.controlSystemType);
    const counterMeasureIdx = parseInt(analysis.counterMeasureType);
    if (!isNaN(controlSystemIdx) && !isNaN(counterMeasureIdx)) {
      setControlRating(controlRatingMatrix[analysis.counterMeasureType.toString()][analysis.controlSystemType.toString()]);
    }
  }, [analysis]);

  const renderFactorHead = (label, icon) => (
    <Box sx={{ display: "flex", width: 1 }}>
      <Box sx={{ width: 0.5, px: 3, py: 0, display: "flex", backgroundColor: "background.greyLight" }}>
        {icon}
        <Typography sx={{ pl: 1 }} color="text.secondary" variant="overline">
          {label}s
        </Typography>
      </Box>
      <Box sx={{ width: 0.5, px: 3, py: 0 }}>
        <Typography color="text.secondary" variant="overline">
          {`Actions to address ${label}s`}
        </Typography>
      </Box>
    </Box>
  );

  const renderFactor = (name, num, isCogHaz) => {
    const showNotes = !!noteStates[`${name}ActionQANotes`] || !!controlValues[`${name}ActionQANotes`];
    return (
      <Box sx={{ display: "flex", width: 1, flexWrap: "wrap" }}>
        <Box sx={{ width: 0.5, px: 3, pt: 1, display: "flex", backgroundColor: "background.greyLight" }}>
          <Typography sx={{ mb: 1, ml: 4, fontWeight: 500 }}>
            {num}. {optionSets.itemsById[analysis[name]].label}
          </Typography>
        </Box>
        <Box sx={{ width: 0.5, px: 3, pt: 1 }}>
          <Box sx={{ width: 1, display: "flex", flexWrap: "wrap", alignItems: "flex-start" }}>
            {analysis[`${name}Action`] === true && <Typography sx={{ flex: 1, fontWeight: 500 }}>{analysis[`${name}ActionImprovement`]}</Typography>}
            {analysis[`${name}Action`] !== true && (
              <Typography sx={{ flex: 1, pt: 1 }} variant="body2" color="text.disabled">
                No actions specified
              </Typography>
            )}
            <Controller
              name={`controls.${idx}.${name}ActionQAState`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FormControl sx={{ ml: showNotes ? "auto" : 1 }} error={Boolean(fieldState.error)} component="fieldset">
                    {field.value === null || field.value === "" ? canApprove ? (
                      <Box sx={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap", justifyContent: "flex-end", maxWidth: 160 }}>
                        {!controlValues[`${name}ActionQANotes`] && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              setNoteStates(Object.assign({}, noteStates, { [`${name}ActionQANotes`]: !noteStates[`${name}ActionQANotes`] }))
                            }
                          >
                            {noteStates[`${name}ActionQANotes`] ? <HideNotesIcon /> : <NotesIcon />}
                          </IconButton>
                        )}
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => {
                            setNoteStates(Object.assign({}, noteStates, { [`${name}ActionQANotes`]: true }));
                            field.onChange(false);
                            trigger(`controls.${idx}.${name}ActionQANotes`);
                          }}
                          sx={{ ml: 1 }}
                        >
                          <NoIcon />
                        </IconButton>
                        <IconButton
                          color="success"
                          size="small"
                          onClick={() => {
                            field.onChange(true);
                            trigger(`controls.${idx}.${name}ActionQANotes`);
                          }}
                          sx={{ ml: 1 }}
                        >
                          <YesIcon />
                        </IconButton>
                        <Box sx={{ flexBasis: "100%", display: "flex", justifyContent: "flex-end" }}>
                          <FormHelperText sx={{ my: 1 }} error id={`controls.${idx}.${name}ActionQAState-helper-text`}>
                            {fieldState.error?.message || true}
                          </FormHelperText>
                        </Box>
                      </Box>
                    ) : (<Alert sx={{ ml: 1, py: 0 }} severity="info">Pending</Alert>) : (
                      <Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
                        {canApprove && <IconButton
                          color="primary"
                          onClick={(e) => {
                            field.onChange(null);
                            trigger(`controls.${idx}.${name}ActionQANotes`);
                          }}
                          size="small"
                        >
                          <UndoIcon />
                        </IconButton>}
                        {controlValues[`${name}ActionQAState`] && !controlValues[`${name}ActionQANotes`] && canApprove && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              setNoteStates(Object.assign({}, noteStates, { [`${name}ActionQANotes`]: !noteStates[`${name}ActionQANotes`] }))
                            }
                            sx={{ ml: 1 }}
                          >
                            {noteStates[`${name}ActionQANotes`] ? <HideNotesIcon /> : <NotesIcon />}
                          </IconButton>
                        )}
                        <Alert sx={{ ml: 1, py: 0 }} severity={controlValues[`${name}ActionQAState`] ? "success" : "error"}>
                          {`${controlValues[`${name}ActionQAState`] ? "approved" : "rejected"}`}
                        </Alert>
                      </Box>
                    )}
                  </FormControl>
                );
              }}
            />

            {showNotes && (
              <TextInput
                disabled={!canApprove}
                name={`controls.${idx}.${name}ActionQANotes`}
                label={`Approval notes`}
                control={control}
                sx={{ flexBasis: "100%", mb: 1 }}
                multiline
                size="small"
                helper={true}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Divider />
      <Accordion
        expanded={controlsExpanded[`control${idx}`]}
        onChange={(e, expanded) => setControlsExpanded(Object.assign({}, controlsExpanded, { [`control${idx}`]: expanded }))}
        elevation={0}
      >
        <AccordionSummary sx={{ "& .MuiAccordionSummary-content": { alignItems: "center" } }} expandIcon={<ExpandMoreIcon />}>
          <Typography color="text.secondary" sx={{ pl: 1, flex: 1, fontWeight: 500, fontSize: "1.1rem" }}>
            Control {idx + 1}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Box sx={{ display: "flex", width: 1 }}>
            <Box sx={{ width: 0.5, px: 3, pt: 2, pb: 3, backgroundColor: "background.greyLight" }}>
              <Box>
                <Typography
                  component="p"
                  color="text.secondary"
                  variant="overline"
                  sx={{ lineHeight: 1.5, fontWeight: 500, fontSize: "0.9rem", mb: 2 }}
                >
                  Control Analysis
                </Typography>
                <Label>{incidentFields["controlsAnalysis"].label}</Label>
                <Typography sx={{ mb: 1, fontWeight: 500 }}>{optionSets.itemsById[analysis.control.toString()]?.label}</Typography>

                <Label>{incidentFields["errorType"].label}</Label>
                <Typography sx={{ mb: 1, fontWeight: 500 }}>{optionSets.itemsById[analysis.errorType.toString()]?.label}</Typography>

                <Label>{incidentFields["controlEnablement"].label}</Label>
                <Typography sx={{ mb: 1.5, fontWeight: 500 }}>
                  {controlEnablemnt.find((i) => i.value === analysis.controlEnablement).label}
                </Typography>

                <Box sx={{ "&:after": { content: '""', display: "table", clear: "both" } }}>
                  <ControlRating sx={{ mt: 1 }} controlRating={controlRating} />

                  <Box sx={{ mr: 15 }}>
                    <Label>{incidentFields["controlSystemType"].label}</Label>
                    <Typography sx={{ mb: 1, fontWeight: 500 }}>
                      {controlSystemTypeOptions.find((i) => i.id === analysis.controlSystemType)?.label}
                    </Typography>

                    <Label>{incidentFields["counterMeasureType"].label}</Label>
                    <Typography sx={{ mb: 1, fontWeight: 500 }}>
                      {counterMeasureTypeOptions.find((i) => i.id === analysis.counterMeasureType)?.label}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: 0.5, px: 3, pt: 2, pb: 3 }}>
              <Typography
                component="p"
                color="text.secondary"
                variant="overline"
                sx={{ flex: 1, lineHeight: 1.5, fontWeight: 500, fontSize: "0.9rem", mb: 2 }}
              >
                Control Improvement
              </Typography>
              {analysis.controlAction === true ? (
                <Box>
                  <Label>{"First Action"}</Label>
                  <Typography sx={{ mb: 1, fontWeight: 500 }}>{analysis.controlActionImprovement1}</Typography>

                  {!!analysis.controlActionImprovement2 && [
                    <Label>{"Second Action"}</Label>,
                    <Typography sx={{ mb: 1, fontWeight: 500 }}>{analysis.controlActionImprovement2}</Typography>,
                  ]}

                  {!!analysis.controlActionImprovement3 && [
                    <Label>{"Third Action"}</Label>,
                    <Typography sx={{ mb: 1.5, fontWeight: 500 }}>{analysis.controlActionImprovement3}</Typography>,
                  ]}

                  <Box sx={{ "&:after": { content: '""', display: "table", clear: "both" } }}>
                    <ControlRating sx={{ mt: 1 }} controlRating={improvedControlRating} />

                    <Box sx={{ mr: 15 }}>
                      <Label>{incidentFields["controlSystemTypeImproved"].label}</Label>
                      <Typography sx={{ mb: 1, fontWeight: 500 }}>
                        {controlSystemTypeOptions.find((i) => i.id === analysis.controlSystemTypeImproved)?.label}
                      </Typography>

                      <Label>{incidentFields["counterMeasureTypeImproved"].label}</Label>
                      <Typography sx={{ mb: 1, fontWeight: 500 }}>
                        {counterMeasureTypeOptions.find((i) => i.id === analysis.counterMeasureTypeImproved)?.label}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "flex-start", pt: 0.5 }}>
                  <Typography sx={{ pt: 1 }} variant="body2" color="text.disabled">
                    No control improvement specified
                  </Typography>
                </Box>
              )}
              <Box sx={{ mt: 1 }}>
                <TextInput
                  disabled={!canApprove}
                  name={`controls.${idx}.controlQANotes`}
                  label={`Approval notes`}
                  control={control}
                  sx={{ mt: 2, ...mb2Sx }}
                  multiline
                  size="small"
                  helper={true}
                />
                <Controller
                  name={`controls.${idx}.controlQAState`}
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl error={Boolean(fieldState.error)} component="fieldset" fullWidth>
                        {field.value === null || field.value === "" ? canApprove ? (
                          <Box sx={{ width: 1, display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}>
                            <Button
                              color="error"
                              variant="outlined"
                              onClick={() => {
                                field.onChange(false);
                                trigger(`controls.${idx}.controlQANotes`);
                              }}
                              startIcon={<NoIcon />}
                              sx={{ ml: "auto" }}
                            >
                              Reject
                            </Button>
                            <Button
                              color="success"
                              variant="outlined"
                              onClick={() => {
                                field.onChange(true);
                                trigger(`controls.${idx}.controlQANotes`);
                              }}
                              startIcon={<YesIcon />}
                              sx={{ ml: 1 }}
                            >
                              Approve
                            </Button>
                            <Box sx={{ flexBasis: "100%", display: "flex", justifyContent: "flex-end" }}>
                              <FormHelperText sx={{ mt: 1 }} error id={`controls.${idx}.controlQANotes-helper-text`}>
                                {fieldState.error?.message || true}
                              </FormHelperText>
                            </Box>
                          </Box>
                        ) : (<Alert sx={{ ml: "auto", py: 0 }} severity="info">Pending</Alert>) : (
                          <Box sx={{ display: "flex", alignItems: "center", pt: 0.5 }}>
                            {canApprove &&<Button
                              startIcon={<UndoIcon />}
                              sx={{ mr: 2, ml: "auto" }}
                              onClick={(e) => {
                                field.onChange(null);
                                trigger(`controls.${idx}.controlQANotes`);
                              }}
                              size="small"
                            >
                              Undo
                            </Button>}
                            <Alert sx={{ py: 0, ml: canApprove ? 0 : "auto" }} severity={controlValues.controlQAState ? "success" : "error"}>
                              {`Control ${controlValues.controlQAState ? "approved" : "rejected"}`}
                            </Alert>
                          </Box>
                        )}
                      </FormControl>
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>

          {optionSets["cognitiveHazard"][analysis.errorType] && (
            <Box>
              {!!analysis.cogHaz1 && renderFactorHead(incidentFields["cognitiveHazard"].label, <CognitiveIcon color="disabled" />)}

              {!!analysis.cogHaz1 && renderFactor("cogHaz1", 1, true)}
              {!!analysis.cogHaz2 && renderFactor("cogHaz2", 2, true)}
              {!!analysis.cogHaz3 && renderFactor("cogHaz3", 3, true)}
            </Box>
          )}

          {!!analysis.operationalFactor1 && renderFactorHead(incidentFields["operationalFactor"].label, <OperationalIcon color="disabled" />)}

          {!!analysis.operationalFactor1 && renderFactor("operationalFactor1", 1)}
          {!!analysis.operationalFactor2 && renderFactor("operationalFactor2", 2)}
          {!!analysis.operationalFactor3 && renderFactor("operationalFactor3", 3)}

          {!!analysis.orgFactor1 && renderFactorHead(incidentFields["organisationalFactor"].label, <OrganisationIcon color="disabled" />)}

          {!!analysis.orgFactor1 && renderFactor("orgFactor1", 1)}
          {!!analysis.orgFactor2 && renderFactor("orgFactor2", 2)}
          {!!analysis.orgFactor3 && renderFactor("orgFactor3", 3)}

          <Box sx={{ display: "flex", width: 1 }}>
            <Box sx={{ width: 0.5, pl: 3, pr: 2, pt: 3, backgroundColor: "background.greyLight" }}></Box>
            <Box sx={{ width: 0.5, pl: 2, pr: 3, py: 0 }}></Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
