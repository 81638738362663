import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function renderToggle(field, fieldState, handleChange, size, options, fieldSx, disabled) {
  return (
    <ToggleButtonGroup
      sx={{
        flex: 1,
        display: "flex",
        "& .MuiButtonBase-root": { backgroundColor: (theme) => theme.palette.background.input },
        "& .MuiButtonBase-root:hover": { backgroundColor: (theme) => theme.palette.background.main },
        "& .MuiInputBase-root.Mui-disabled": { backgroundColor: "background.white" },
        "& .MuiInputBase-root.Mui-disabled:hover": { backgroundColor: "background.white" },
        "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped.MuiButtonBase-root": { borderColor: fieldState.error ? "error.main" : "grey.400" },
        "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped.MuiButtonBase-root:hover": {
          borderColor: fieldState.error ? "error.main" : "text.primary",
        },
        ...fieldSx
      }}
      disabled={disabled}
      value={field.value}
      exclusive
      onChange={(e, newValue) => {
        field.onChange(e, newValue);
        if (handleChange) handleChange(newValue);
      }}
      size={size ?? "medium"}
    >
      {options.map((opt, i) => (
        <ToggleButton key={i} sx={{ flex: 1 }} value={opt.value} color={opt.color ?? "standard"}>
          {opt.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
