import React from 'react'
import { useRecoilValue } from 'recoil';
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authState from "./authState";


export default function AuthorizeRoute({ children, path, ...rest } ) {

    const auth = useRecoilValue(authState)

    var link = document.createElement("a");
    link.href = rest.location.pathname;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;

    return auth.authenticated ? <Route {...rest}>{children}</Route> : <Route {...rest}><Redirect to={redirectUrl} /></Route>

}
