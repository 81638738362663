import { atom } from 'recoil';

const authState = atom({
    key: 'authState', 
    default: {
      authenticated: null,
      user: null,
      token: null,
      fetching: true
    }, 
  });

  export default authState;