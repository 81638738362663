import { atom } from 'recoil';

const permissionsState = atom({
    key: 'permissionsState', 
    default: {
      fetching: true,
      all: null,
      granted: null
    }, 
  });

  export default permissionsState;