import React from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import renderToggle from "./renderToggle";

export default function ToggleButtonInput({ name, helper, control, sx, handleChange, size, options, label, fieldSx, disabled }) {

  return (
    <Box sx={sx}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <FormControl sx={sx} error={Boolean(fieldState.error)} component="fieldset" fullWidth>
              {label ? (
                <Box sx={{display: "flex"}}>
                  <Typography variant="body2" color="text.secondary" sx={{ lineHeight: "39px", minWidth: 90 }}>
                    {label}
                  </Typography>
                  {renderToggle(field, fieldState, handleChange, size, options, fieldSx, disabled)}
                </Box>
              ) : (
                renderToggle(field, fieldState, handleChange, size, options, fieldSx, disabled)
              )}
              <FormHelperText id={`${name}-helper-text`}>{fieldState.error?.message || helper || " "}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </Box>
  );
}
