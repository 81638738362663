import React, { useEffect, useState, useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { v4 as guid } from "uuid";
//import update from "immutability-helper";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import AddHeaderIcon from "@mui/icons-material/Title";
import AddOptionIcon from "@mui/icons-material/PlaylistAdd";
import { yupInputRequired } from "../formUtils/validationRules";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import TextInput from "../formUtils/TextInput";
import Loader from "../app/Loader";
import { OptionSetValueItem } from "./OptionSetValueItem";
import snackbarState from "../app/snackbarState";

const defaultValues = {
  name: "",
  optionsetValues: [],
};

export default function OptionsetValuesPanel({ optionset, processRowUpdate, setSelectedParent, selectedParent }) {
  const auth = useRecoilValue(authState);
  const [, setSnackbar] = useRecoilState(snackbarState);
  //const [optionsetValues, setOptionsetValues] = useState([]);
  const [fetching, setFetching] = useState({ fetching: true });

  // async function fetchOptionsets() {
  //   await fetchSimple("/api/optionsets", "GET", auth.token, setFetching, setOptionsets).finally(() => setFetching(false));
  // }

  const optionsetsFormSchema = yup.object().shape({
    name: yupInputRequired,
    optionsetValues: yup.array().of(
      yup.object().shape({
        label: yupInputRequired,
      })
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm({
    resolver: yupResolver(optionsetsFormSchema),
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  const { fields, prepend, remove, move } = useFieldArray({
    control,
    keyName: "valueId",
    name: "optionsetValues",
  });

  useEffect(() => {
    async function loadOptionsets() {
      await fetchSimple(
        Boolean(selectedParent)
          ? `/api/optionsetvalues/${optionset.childOptionSetName}/${selectedParent.id}`
          : `/api/optionsetvalues/${optionset.optionSetName}`,
        "GET",
        auth.token,
        setFetching,
        null
      )
        .then((data) => {
          reset(
            Object.assign(
              {},
              {
                name: Boolean(selectedParent) ? optionset.childOptionSetLabel : optionset.name,
                optionsetValues: data.flatMap((opt, idx) => {
                  if (opt.subheading && (idx === 0 || data[idx - 1].subheading !== opt.subheading)) {
                    return [{ isSubhead: true, subheading: opt.subheading, id: opt.subheading, label: opt.subheading }, opt];
                  }
                  return opt;
                }),
              }
            )
          );
        })
        .finally(() => setFetching({ fetching: false }));
    }
    if (optionset) loadOptionsets();
  }, [auth.token, optionset, reset, selectedParent]);

  const moveItem = useCallback((dragIndex, hoverIndex) => move(hoverIndex, dragIndex), [move]);

  const deleteItem = useCallback((idx) => remove(idx), [remove]);

  const renderItem = useCallback(
    (optionSetValue, index) => {
      return (
        <OptionSetValueItem
          key={optionSetValue.id}
          index={index}
          id={optionSetValue.id}
          optionSetValue={optionSetValue}
          moveItem={moveItem}
          control={control}
          isNested={Boolean(selectedParent) ? false : optionset.hasChildren}
          setSelectedParent={setSelectedParent}
          handleDelete={deleteItem}
        />
      );
    },
    [moveItem, control, optionset.hasChildren, deleteItem, setSelectedParent, selectedParent]
  );

  const saveOptionset = async (formData) => {
    setSnackbar({ severity: "info", message: "Saving Optionset" });
    //loop through optionset
    const payload = {
      name: formData.name,
      optionsetValues: [],
    };
    var currentSubHead = "";
    formData.optionsetValues.forEach((o, i) => {
      if (o.isSubhead && o.label !== currentSubHead) {
        currentSubHead = o.label;
      } else {
        const osv = Object.assign({}, o);
        osv.subheading = currentSubHead;
        osv.order = i;
        if (osv.isNew) osv.id = -1;
        payload.optionsetValues.push(osv);
      }
    });

    await fetchSimple(
      Boolean(selectedParent)
        ? `/api/optionsetvalues/${optionset.childOptionSetName}/${selectedParent.id}`
        : `/api/optionsetvalues/${optionset.optionSetName}`,
      "POST",
      auth.token,
      setFetching,
      null,
      payload
    )
      .then((data) => {
        reset(
          Object.assign(
            {},
            {
              name: formData.name,
              optionsetValues: data.flatMap((opt, idx) => {
                if (opt.subheading && (idx === 0 || data[idx - 1].subheading !== opt.subheading)) {
                  return [{ isSubhead: true, subheading: opt.subheading, id: opt.subheading, label: opt.subheading }, opt];
                }
                return opt;
              }),
            }
          )
        );
      })
      .finally(() => setFetching({ fetching: false }));

    processRowUpdate(Object.assign({}, optionset, Boolean(selectedParent) ? { childOptionSetName: formData.name } : { name: formData.name }));

    setSnackbar({ severity: "success", message: "Optionset saved successfully" });
  };

  const addItem = (isSubhead) => {
    const idVal = guid();
    prepend({
      isSubhead: isSubhead,
      subheading: "",
      id: idVal,
      parentId: Boolean(selectedParent) ? selectedParent.id : null,
      value: idVal,
      label: "",
      active: true,
      hasChildren: false,
      isNew: true,
      optionSetName: Boolean(selectedParent) ? optionset.childOptionSetName : optionset.optionSetName,
    });
  };

  return (
    <form noValidate style={{ height: "calc(100% - 64px)", display: "flex", flexDirection: "column" }}>
      {!fetching.fetching && (
        <Box>
          <Box sx={{ px: 2, pt: 2, pb: 1 }}>
            <TextInput name="name" label="Name" control={control} size="medium" variant="outlined" />
          </Box>
          <Divider />
          <Box sx={{ display: "flex", backgroundColor: "background.greyLight", pb: 1, pt: 2, pl: 3, pr: 5 }}>
            <Typography color="text.secondary" sx={{ flex: 1, fontWeight: 500, fontSize: "1.1rem" }}>
              Values
            </Typography>
            <Box>
              <Button onClick={() => addItem(true)} sx={{ mr: 1 }} color="primary" size="small" startIcon={<AddHeaderIcon />}>
                Add Category
              </Button>
              <Button onClick={() => addItem(false)} color="secondary" size="small" startIcon={<AddOptionIcon />}>
                Add Option
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={{ flex: "1 1 auto", overflowY: "auto", px: 1, pb: 6, backgroundColor: "background.greyLight" }}>
        {fetching.fetching ? <Loader /> : fields.map((optionSetValue, idx) => renderItem(optionSetValue, idx))}
      </Box>
      <Box
        sx={{
          p: 3,
          borderTop: 1,
          borderColor: "divider",
          flex: "0 1 90px",
          boxShadow: 4,
          clipPath: "polygon(0% -20%, 100% -20%, 100% 100%, 0% 100%)",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Button
            sx={{ ml: "auto" }}
            startIcon={<SaveIcon />}
            disabled={fetching.fetching || fields.length === 0 || isSubmitting || !isDirty}
            color="success"
            variant="contained"
            size="large"
            onClick={handleSubmit(saveOptionset)}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </form>
  );
}
