import React from "react";
import { Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

export default function SelectInput({ optionSet, control, sx, helper, size, label, name, handleChange, selectSx, variant, handleBeforeChange, disabled }) {
  
  if(!optionSet) return null;

  const { optionSetName } = optionSet[0];

  return (
    <Controller
      name={name ?? optionSetName}
      control={control}
      aria-label={label}
      defaultValue=""
      render={({ field, fieldState }) => (
        <FormControl variant={variant ?? "outlined"} sx={sx} error={fieldState.error} fullWidth>
          <InputLabel id={`${optionSetName}-select-label`} size={size ?? "normal"}>
            {label}
          </InputLabel>
          <Select
            labelId={`${optionSetName}-select-label`}
            id={`${optionSetName}-select`}
            label={label}
              disabled={disabled}
            color="secondary"
            displayEmpty
            size={size ?? "medium"}
            sx={
              selectSx ?? {
                "&.MuiInputBase-root": { backgroundColor: "background.input" },
                "&.MuiInputBase-root:hover": { backgroundColor: "background.mainLight" },
                "&.MuiInputBase-root.Mui-disabled": { backgroundColor: "background.white" },
                "&.MuiInputBase-root.Mui-disabled:hover": { backgroundColor: "background.white" },
                "&.MuiInputBase-root.Mui-focused": { backgroundColor: "background.main" }
              }
            }
            {...field}
            onChange={(e, e1) => {
              if (handleBeforeChange) handleBeforeChange(field.value);
              field.onChange(e);
              if (handleChange) handleChange(e.target.value);
            }}
          >
            {optionSet.map((opt, idx) => {
              if (opt.subheading && (idx === 0 || optionSet[idx - 1].subheading !== opt.subheading)) {
                return [
                  <ListSubheader sx={{fontWeight:500, color: "primary.main", backgroundColor: "background.greyLight"}}>{opt.subheading}</ListSubheader>,
                  <MenuItem key={idx} value={opt.id}>
                    {opt.label}
                  </MenuItem>,
                ];
              }
              return (
                <MenuItem key={idx} value={opt.id}>
                  <ListItemText
                    primary={<Typography noWrap>{opt.label}</Typography>}
                    secondary={!!opt.description ? <Typography variant="body2" color="text.secondary" noWrap>{opt.description}</Typography> : null}
                  />
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText id={`${optionSetName}-helper-text`}>{fieldState.error?.message || helper || " "}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
