import React from "react";
import { useRecoilState } from "recoil";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import helpState from "./helpState";

export default function HelpIndex({ children, ...rest }) {
  const [, setHelp] = useRecoilState(helpState);

  return (
    <Box>
    <Box sx={{ pt: 3, px: 3 }}><Typography color="text.secondary" variant="overline" sx={{ fontWeight: 500, fontSize: "0.9rem" }}>Contents</Typography></Box>

      <List dense>
        <ListSubheader sx={{pl:6}}>Entry & Management</ListSubheader>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText sx={{ color: "primary.main" }} inset primary="Bulk Import" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Incidents" />
          </ListItemButton>
        </ListItem>
        <ListSubheader sx={{pl:6}}>Assessment</ListSubheader>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="SIFp" />
          </ListItemButton>
        </ListItem>
        <ListSubheader sx={{pl:6}}>Analysis</ListSubheader>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Risk Controls" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Control Enablement" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Error Types" onClick={() => setHelp({page: "Control Enablement", section: "Error Types" })} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Operational Factors" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Organisational Factors" />
          </ListItemButton>
        </ListItem>
        <ListSubheader sx={{pl:6}}>Actions</ListSubheader>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Control Improvement" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Operational Factors" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Organisational Factors" />
          </ListItemButton>
        </ListItem>
        <ListSubheader sx={{pl:6}}>Approval & Review</ListSubheader>
        <ListItem disablePadding>
          <ListItemButton color="primary">
            <ListItemText sx={{ color: "primary.main" }} inset primary="Approval" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
