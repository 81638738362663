import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { lighten } from "@mui/material/styles";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid-premium";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import Upload from "@mui/icons-material/Upload";
import Download from "@mui/icons-material/Download";
import Submit from "@mui/icons-material/DoneAll";
import Delete from "@mui/icons-material/DeleteOutlined";
import ClientsAutocomplete from "../clients/ClientsAutocomplete";
import selectedClientState from "../clients/selectedClientState";
import permissionsState from "../auth/permissionsState";

export default function IncidentEntryToolbar({ addIncident, selectionStats, deleteIncident, submitIncident, setDensityButtonEl, open, isClientRole }) {
  
  const permissions = useRecoilValue(permissionsState);
  const selectedClient = useRecoilValue(selectedClientState);

  return (
    <Toolbar
      sx={{
        minHeight: 8,
        maxHeight: 8,
        borderBottom: 1,
        borderBottomColor: "border.main",
        backgroundColor: (theme) => {
          return selectionStats.total > 0 ? lighten(theme.palette.secondary.main, 0.9) : theme.palette.background.greyMid;
        },
      }}
    >
      <Typography variant="h6" sx={{mr: selectionStats.total > 0 ? 3 : 0}}>{selectionStats.total > 0 && `${selectionStats.total} Incidents Selected`}</Typography>
      {selectionStats.total === 0 && !isClientRole && (
        <ClientsAutocomplete
          sx={{
            mr: 3,
            minWidth: 300,
            "& .MuiFormControl-root .MuiInputBase-root": {
                backgroundColor: "background.white",
                pl: 1
              },
            "& .MuiFormControl-root .MuiFilledInput-root input": { pl: 0 },
            "& .MuiFormControl-root .MuiFilledInput-root.Mui-focused": { backgroundColor: "background.main" },
            "& .MuiFormControl-root .MuiFilledInput-root:hover": { backgroundColor: "background.mainLight" },
          }}
        />
      )}
      <Button
        disabled={!selectedClient && !isClientRole}
        variant="outlined"
        aria-label="Add"
        color="secondary"
        sx={{ mr: 2, background: "common.white" }}
        onClick={addIncident}
      >
        <Add sx={{ mr: 1 }} />
        Add Incident
      </Button>
      <Button disabled={!selectedClient && !isClientRole} variant="contained" aria-label="Upload" color="secondary" sx={{ mr: 2 }} onClick={open}>
        <Upload sx={{ mr: 1 }} />
        Upload
      </Button>
      <Button aria-label="Template" color="primary" sx={{ mr: "auto" }} href="/docs/IAImportTemplate.csv">
        <Download sx={{ mr: 1 }} />
        Template
      </Button>
      {selectionStats.total > 0 ? (
        <Fragment>
          {selectionStats.delete > 0 && (
            <Badge color="secondary" badgeContent={selectionStats.delete}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="Delete"
                sx={{ ml: 2, bgcolor: "common.white" }}
                onClick={() => deleteIncident()}
                startIcon={<Delete />}
              >
                Delete
              </Button>
            </Badge>
          )}
          {selectionStats.submit > 0 && permissions.granted[permissions.all.incidentCreate] && (
            <Badge color="secondary" badgeContent={selectionStats.submit}>
              <Button
                variant="contained"
                color="primary"
                aria-label="Complete Import"
                sx={{ ml: 2 }}
                onClick={() => submitIncident()}
                startIcon={<Submit />}
              >
                Complete Import
              </Button>
            </Badge>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton sx={{ ml: 2 }} />
          <GridToolbarDensitySelector ref={setDensityButtonEl} sx={{ ml: 2 }} />
        </Fragment>
      )}
    </Toolbar>
  );
}
