import React, { useEffect, useState } from "react";
//import { Allotment } from "allotment";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { DataGridPremium, useKeepGroupedColumnsHidden, useGridApiRef } from "@mui/x-data-grid-premium";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IncidentsToolbar from "./IncidentsToolbar";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import fetchFileDownload from "../services/fetchFileDownload";
import selectedClientState from "../clients/selectedClientState";
import { isEmptyObject, renderStatus, renderTime } from "./gridUtils";
//import IncidentTabbedView from "./IncidentTabbedView";
// import IncidentAnalysisDetails from "./IncidentAnalysisDetails";
//import IncidentRestrictedView from "./IncidentRestrictedView";
import NewIncident from "./NewIncident";
//import { incidentStatePhaseMap } from "../app/appConstants";

export default function IncidentsPage(props) {
  const history = useHistory();
  const apiRef = useGridApiRef();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  // const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  // const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const xxlUp = useMediaQuery(theme.breakpoints.up("xxl"));
  //const xxxlUp = useMediaQuery(theme.breakpoints.up("xxxl"));
  const [incidents, setIncidents] = useState([]);
  const [allIncidents, setAllIncidents] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [fetching, setFetching] = useState({ fetching: false });
  const [exporting, setExporting] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const selectedClient = useRecoilValue(selectedClientState);
  const auth = useRecoilValue(authState);
  const isClientRole = auth.user?.role === "client";
  const [densityButtonEl, setDensityButtonEl] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide the column used for leaves
    //reference: !!selectedClient,
    clientName: false,
    actions: xxlUp,
    eventDetails: mdUp,
  });

  useEffect(() => {
    async function fetchIncidents() {
      await fetchSimple(`/api/incidents`, "GET", auth.token, setFetching, setAllIncidents).finally(() => setFetching(false));
    }
    fetchIncidents();
  }, [auth.token]);

  useEffect(() => {
    if (!selectedClient) {
      setIncidents(allIncidents);
    } else {
      setIncidents(allIncidents.filter((i) => i.clientId === selectedClient.id));
    }
    setColumnVisibilityModel({
      //reference: !!selectedClient,
      clientName: false,
      //actions: xxlUp,
      eventDetails: mdUp,
    });
  }, [allIncidents, selectedClient, xxlUp, mdUp]);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      columns: {
        columnVisibilityModel: columnVisibilityModel,
      },
      rowGrouping: {
        model: ["clientName"],
      },
    },
  });

  const exportIncidents = () => {
    setExporting(true);
    fetchFileDownload(`/api/incidents/export/${!!selectedClient ? selectedClient.id : ""}`, auth.user.token, "IncidentExport.csv", () =>
      setExporting(false)
    );
  };

  const handleIncidentClick = (params) => {
    if (!isEmptyObject(params.row)) {
      // if (xxlUp) {
      //   params.row.date = new Date(params.row.date);
      //   params.row.time = new Date(params.row.time);
      //   setSelectedIncident(params.row);
      //   setColumnVisibilityModel(Object.assign({}, columnVisibilityModel, { eventDetails: false, date: xxxlUp, time: xxxlUp }));
      // } else {
        handleNavigateIncident(params.row);
      // }
    }
  };

  const handleNavigateIncident = (row) => {
    history.push(`/incident/${row.id}`);
  };

  // const handleDetailsNavigate = () => {
  //   handleNavigateIncident(selectedIncident);
  // };

  // const handleClose = () => {
  //   setColumnVisibilityModel(Object.assign({}, columnVisibilityModel, { eventDetails: true, date: true, time: true }));
  //   setSelectedIncident(false);
  // };

  const processRowUpdate = (data, isUnselected) => {
    setIncidents(incidents.map((row) => (row.id === data.id ? data : row)));
    setAllIncidents(incidents.map((row) => (row.id === data.id ? data : row)));
    if (!isUnselected) setSelectedIncident(data);
  };

  const openAddIncident = () => {
    setAddOpen(true);
  };

  const insertNewIncident = (newIncident) => {
    setAllIncidents((oldRows) => [...oldRows, newIncident]);
    if (!selectedClient) {
      setIncidents(allIncidents);
    } else {
      setIncidents(allIncidents.filter((i) => i.clientId === selectedClient.id));
    }
  };

  //const isDraftOrIgnored = selectedIncident?.state === "ignored" || selectedIncident?.state === "draft";

  return (
    <Box
      sx={(theme) => {
        return {
          bgcolor: theme.palette.common.white,
          marginTop: theme.spacing(7),
          position: "relative",
          height: "calc(100vh - 56px)",
          [`${theme.breakpoints.up("sm")}`]: {
            height: "calc(100vh - 64px)",
            marginTop: theme.spacing(8),
          },
          "& .MuiDataGrid-row": { cursor: "pointer" },
          "& .MuiDataGrid-row.grouping-row": { color: theme.palette.primary.main, fontWeight: 500, background: theme.palette.background.greyLight },
          "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(3) },
          [`${theme.breakpoints.down("sm")}`]: {
            "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(2) },
          },
        };
      }}
      style={{
        "--focus-border": theme.palette.secondary.main,
      }}
    >
      {/* <Allotment>
        <Allotment.Pane minSize={selectedIncident & xxxlUp ? 810 : selectedIncident & xxlUp ? 530 : "100%"} priority={1}> */}
          <DataGridPremium
            sx={{ border: 0 }}
            apiRef={apiRef}
            loading={fetching.fetching}
            rows={incidents}
            pageSize={pageSize}
            onRowClick={handleIncidentClick}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100, 500]}
            pagination
            disableColumnMenu
            rowGroupingColumnMode="single"
            defaultGroupingExpansionDepth={-1}
            initialState={initialState}
            groupingColDef={{
              leafField: selectedClient ? null : "reference",
              minWidth: 160,
              headerClassName: "left-cell",
              cellClassName: "left-cell",
              colSpan: ({ row }) => (isEmptyObject(row) ? 5 : undefined),
            }}
            columnVisibilityModel={columnVisibilityModel}
            disableRowGrouping={Boolean(selectedClient) || isClientRole}
            getRowClassName={({ row }) => (isEmptyObject(row) ? "grouping-row" : "")}
            slots={{
              toolbar: IncidentsToolbar,
            }}
            slotProps={{
              toolbar: {
                setDensityButtonEl,
                addIncident: openAddIncident,
                exportIncidents,
                exporting,
                detailOpen: Boolean(selectedIncident),
                hideGridSettings: selectedIncident && xxlUp,
                isClientRole: isClientRole
              },
              panel: {
                anchorEl: densityButtonEl,
                placement: "bottom-end",
              },
            }}
            columns={[
              {
                field: "clientName",
                headerName: "Client",
              },
              {
                field: "reference",
                headerName: "Reference",
                minWidth: 160,
                headerClassName: "left-cell",
                cellClassName: "left-cell",
              },
              {
                field: "eventTitle",
                headerName: "Event title",
                minWidth: 150,
                flex: 0.25,
              },
              {
                field: "eventDetails",
                headerName: "Event details",
                flex: 1,
              },
              {
                field: "date",
                headerName: "Date",
                type: "date",
                minWidth: 140,
                valueGetter: ({ value }) => value && new Date(value)
                //renderCell: renderDate,
              },
              {
                field: "time",
                headerName: "Time",
                type: "dateTime",
                minWidth: 140,
                valueGetter: ({ value }) => value && new Date(value),
                valueFormatter: renderTime,
              },
              {
                field: "state",
                headerName: "Status",
                minWidth: 140,
                renderCell: renderStatus,
              },
              // {
              //   field: "actions",
              //   headerName: "",
              //   width: 40,
              //   disableColumnMenu: true,
              //   hideSortIcons: true,
              //   filterable: false,
              //   resizable: false,
              //   sortable: false,
              //   align: "right",
              //   type: "actions",
              //   renderHeader: (params) => <span></span>,
              //   getActions: (params) => {
              //     return isEmptyObject(params.row)
              //       ? []
              //       : [<GridActionsCellItem icon={<OpenIcon />} label="Quick View" onClick={() => handleNavigateIncident(params)} />];
              //   },
              // },
            ]}
          />
        {/* </Allotment.Pane>

        {selectedIncident && xxlUp && (
          <Allotment.Pane maxSize={isDraftOrIgnored ? 600 : 1200} minSize={isDraftOrIgnored ? 600 : 1200} preferredSize={isDraftOrIgnored ? 600 : 1200}>
            {isDraftOrIgnored ? (
              <IncidentRestrictedView
                fetching={fetching}
                incident={selectedIncident}
                handlePhaseChange={processRowUpdate}
                handleClose={handleClose}
                handleNavigate={handleDetailsNavigate}
              />
            ) : (
              <IncidentTabbedView
                fetching={fetching}
                incident={selectedIncident}
                handlePhaseChange={processRowUpdate}
                processRowUpdate={processRowUpdate}
                handleClose={handleClose}
                handleNavigate={handleDetailsNavigate}
              />
            )}
          </Allotment.Pane>
        )}
      </Allotment> */}
      <NewIncident
        isClientRole={isClientRole}
        open={addOpen}
        setClose={setAddOpen}
        insertNewIncident={insertNewIncident}
        processRowUpdate={processRowUpdate}
        handlePhaseChange={processRowUpdate}
      />
    </Box>
  );
}
