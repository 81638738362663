import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OpenIcon from "@mui/icons-material/OpenInNew";
import Loader from "../app/Loader";

export default function IncidentViewContainer({ children, fullPage, incident, hideTitle, handleClose, handleNavigate, incidentFetching }) {
  return incidentFetching ? (
    <Loader />
  ) : (
<Box sx={{ height: 1 }}>
      {fullPage ? (
        <Breadcrumbs aria-label="breadcrumb" sx={{"& li:last-of-type": { flex: 1, minWidth: 0}}}>
          <Link component={RouterLink} to={"/"} underline="hover" color="inherit" href="/">
            <Typography sx={{ my: 3, color: "primary.main", fontWeight: 400 }} variant="h6">
              Incidents
            </Typography>
          </Link>
          <Typography
            sx={{ my: 3, color: "text.primary", fontWeight: 400, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
            noWrap
            variant="h6"
          >{`${incident.reference ? incident.reference : "[REF]"} - ${incident.eventTitle ? incident.eventTitle : "[TITLE]"}`}</Typography>
        </Breadcrumbs>
      ) : (
        !hideTitle && (
          <Toolbar
            sx={{
              display: "flex",
              backgroundColor: "background.greyMid",
              borderBottom: 1,
              borderBottomColor: "border.main",
            }}
          >
            <Typography sx={{ fontWeight: 400 }} noWrap variant="h6">{`${incident.reference ? incident.reference : "[REF]"} - ${incident.eventTitle ? incident.eventTitle : "[TITLE]"}`}</Typography>
            <Box sx={{ flex: 1 }}></Box>
            {!!handleNavigate && (
              <IconButton sx={{ mr: -1 }} onClick={() => handleNavigate()}>
                <OpenIcon />
              </IconButton>
            )}
            {!!handleClose && (
              <IconButton sx={{ ml: 2, mr: -1 }} onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>
        )
      )}
      <Paper
        sx={(theme) => {
          return {
            ...(fullPage
              ? {}
              : {
                  height: "calc(100vh - 112px)",
                  [`${theme.breakpoints.up("sm")}`]: {
                    height: "calc(100vh - 128px)",
                  },
                }),
          };
        }}
      >
        {children}
      </Paper>
    </Box>
  );
}
