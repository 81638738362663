import WarningIcon from "@mui/icons-material/WarningAmber";
import ApprovalIcon from "@mui/icons-material/VerifiedOutlined";
import YesIcon from "@mui/icons-material/Done";
import ActionsIcon from "@mui/icons-material/TaskAlt";
import VerifiedIcon from "@mui/icons-material/Verified";
import NoIcon from "@mui/icons-material/Close";
import AnalysisIcon from "@mui/icons-material/Insights";
import InvestigationIcon from "@mui/icons-material/Verified";
import MaybeIcon from "@mui/icons-material/QuestionMark";
import DraftIcon from "@mui/icons-material/Edit";

export const filterDrawerWidth = {
  xs: "80%",
  sm: "240px",
  md: "300px",
  lg: "300px",
  xl: "300px",
};

export const analysisDrawerSize = {
  bottom: {
    master: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)", md: "40vh" },
    detail: {
      xs: "75%",
      sm: "70%",
      md: "calc(60vh - 64px)",
    },
  },
  right: {
    xs: "80%",
    sm: "80%",
    md: "700px",
    lg: "800px",
    xl: "1100px",
  },
};

export const detailDrawerSize = {
  bottom: {
    master: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)", md: "40vh" },
    detail: {
      xs: "75%",
      sm: "70%",
      md: "calc(60vh - 64px)",
    },
  },
  right: {
    xs: "80%",
    sm: "80%",
    md: "500px",
    lg: "500px",
    xl: "600px",
  },
};

export const largeDrawerSize = {
  right: {
    xs: "100%",
    sm: "90%",
    md: "70%",
    lg: "750px",
    xl: "800px",
  },
};
export const xlDrawerSize = {
  right: {
    xs: "100%",
    sm: "100%",
    md: "80%",
    lg: "850px",
    xl: "950px",
  },
};

export const routeTitles = {
  "/": "Incidents",
  "/incident/:id": "Incident Information",
  "/import": "Bulk Import",
  "/analysis": "Incident Analysis",
  "/assessment": "Incident Assessment",
  "/actions": "Incident Actions",
  "/approval": "Incident Approval",
  "/clients": "Client Administration",
  "/admin/users": "User Administration",
  "/admin/optionsets": "Optionset Administration",
  "/admin/tenants": "Tenant Administration",
}

export const mainMenuWidth = 220;

export const acceptedFileTypes =
  ".csv";

export const maxUploadSize = 5242880;

export const inputGroupSx = {
  group: { display: "flex", flexDirection: "row" },
  formControlLabel: {
    width: { xs: 1, sm: 0.5 },
    mr: 0,
  },
};

export const mb3Sx = { mb: 3 };

export const mb1Sx = { mb: 1 };

export const mb2Sx = { mb: 2 };

export const mb025Sx = { mb: 0.25 };

export const incidentStateTabs = {
  assessment: 1,
  analysis: 2,
  actions: 3,
  approval: 4,
  approved: 0,
};

export const analysisStates = [
  "analysis",
  "actions",
  "approval",
  "approved"
];

export const incidentFields = {
  reference: {label: "Reference"},
  eventTitle: {label: "Event title"},
  eventDetails: {label: "Event details"},
  date: {label: "Event Date"},
  time: {label: "Event Time"},
  injuryType: {label: "Injury type"},
  businessDivision: {label: "Business division"},
  incidentMechanism: {label: "Incident Mechanism"},
  actual: {label: "Actual"},
  potential: {label: "Potential"},
  location: {label: "Location 1"},
  locationAdditional: {label: "Location 2"},
  riskCategoryClient: {label: "Client risk category"},
  eventOwnership: {label: "Event ownership"},
  timeWithinShift: {label: "Time within shift"},
  shiftPattern: {label: "Shift pattern"},
  riskCategory: {label: "Risk category"},
  fatalityRisk: {label: "Fatality risk"},
  sif: {label: "SIFp status"},
  controlsAnalysis: {label: "Control"},
  errorType: {label: "Error type"},
  controlEnablement: {label: "Enablement"},
  cognitiveHazard: {label: "Cognitive hazard"},
  operationalFactor: {label: "Operational factor"},
  organisationalFactor: {label: "Organisational factor"},
  qaNotes: {label: "QA Notes"},
  controlSystemType: {label: "Control system type"},
  counterMeasureType: {label: "Countermeasure type"},
  controlSystemTypeImproved: {label: "Improved control system type"},
  counterMeasureTypeImproved: {label: "Improved countermeasure type"}
};

export const fatalityRisk = [
  {value: "false", label: "No", color: "warning", icon: <NoIcon/> },
  {value: "true", label: "Yes", color: "success", icon: <YesIcon/>  }
];

export const sifOptions = [
  {value: "no", label: "No", color: "warning", icon: <NoIcon/> },
  {value: "maybe", label: "Maybe", color: "info", icon: <MaybeIcon/> },
  {value: "yes", label: "Yes", color: "success", icon: <YesIcon/>  }
];

export const incidentStatus = {
  "draft": { label: "Draft", color: "warning", icon: <DraftIcon/> }, 
  "assessment": { label: "Assessment", color: "error", icon: <WarningIcon/> }, 
  "analysis": { label: "Analysis", color: "primary", icon: <AnalysisIcon />  },
  "ignored": { label: "Ignored", color: "default", icon: <NoIcon/>  },
  "actions": { label: "Actions", color: "info", icon: <ActionsIcon/>  },
  "approval": { label: "Approval", color: "success", icon: <ApprovalIcon/>  },
  "approved": { label: "Approved", color: "success", icon: <VerifiedIcon/>  },
  "investigated": { label: "Investigated", color: "primary", icon: <InvestigationIcon/>  }
};

export const roles = [
  { optionSetLabel: "User role", optionSetName: "role", value: "client", label: "Client", tooltip: "Users assigned the Client role a restricted scope and can only access incidents assigned to the client entity they belong to" },
  { optionSetLabel: "User role", optionSetName: "role", value: "tenant", label: "Tenant", tooltip: "Users assigned the Tenant role are able to access any incidents, across all clients in the tenancy they belong to." },
  { optionSetLabel: "User role", optionSetName: "role", value: "admin", label: "Administrator", tooltip: "In addition to Tenant privileges, Administrators are able to perform administrative functions within a tenancy." },
  { optionSetLabel: "User role", optionSetName: "role", value: "sysadmin", label: "System Administrator", tooltip: "In addition to Administrator privileges, System Aministrators are able to administer and switch between tenancies." },
];
