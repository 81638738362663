import React from "react";
import { Controller } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

export default function RadioInput({
  optionSet,
  control,
  helper,
  sx,
  componentSx,
  handleChange,
  hideLabel
}) {
  const { optionSetLabel, optionSetName } = optionSet[0];
  
  return (
    <Controller
      name={optionSetName}
      control={control}
      aria-label={optionSetLabel}
      defaultValue=""
      render={({field, fieldState}) => {
        return <FormControl
          sx={sx}
          error={Boolean(fieldState.error)}
          component="fieldset"
          fullWidth
        >
          {!hideLabel &&<FormLabel component="legend">{optionSetLabel}</FormLabel>}
          <RadioGroup sx={componentSx && componentSx.group} {...field} 
            onChange={(e) => {
              field.onChange(e);
              if (handleChange) handleChange(e.target.value);
            }}>
            {optionSet.map((opt, idx) => (
              <FormControlLabel
                sx={componentSx && componentSx.formControlLabel}
                key={idx}
                value={opt.value}
                control={<Radio color="secondary" />}
                label={opt.label}
              />
            ))}
          </RadioGroup>
          <FormHelperText id={`${optionSetName}-helper-text`}>
            {fieldState.error?.message || helper || " "}
          </FormHelperText>
        </FormControl>
      }}
    />
  );
}
