import React from "react";
import { GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid-premium";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";

export default function TenantsToolbar({ tenantsCount, openNewTenant }) {
  return (
    <Toolbar
      sx={{
        minHeight: 8,
        maxHeight: 8,
        backgroundColor: "background.greyMid",
        borderBottom: 1,
        borderBottomColor: "border.main"
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton sx={{ ml: 2 }} />
      <GridToolbarDensitySelector sx={{ ml: 2 }} />

      <Typography sx={{ ml: "auto" }}>{`${tenantsCount} Total Tenants`}</Typography>
      <Button variant="contained" aria-label="Add Tenant" color="secondary" sx={{ ml: 2 }} onClick={openNewTenant}>
        <Add sx={{ mr: 1 }} />
        Add Tenant
      </Button>
    </Toolbar>
  );
}
