import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextInput from "../formUtils/TextInput";
import SelectInput from "../formUtils/SelectInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CognitiveIcon from "@mui/icons-material/PsychologyOutlined";
import OperationalIcon from "@mui/icons-material/EngineeringOutlined";
import OrganisationIcon from "@mui/icons-material/BusinessOutlined";
import AddIcon from "@mui/icons-material/Add";
import YesIcon from "@mui/icons-material/Done";
import NoIcon from "@mui/icons-material/Close";
import UndoIcon from "@mui/icons-material/Undo";
import Label from "../app/Label";
import Annotation from "./Annotation";
import { mb3Sx, mb2Sx, incidentFields } from "../app/appConstants";
import useOptionSets from "../optionsets/useOptionSets";
import ControlRating from "./ControlRating";
import { controlRatingMatrix, controlEnablemnt, controlSystemTypeOptions, counterMeasureTypeOptions } from "./controlConstants";
// import CountermeasureSelect from "./CountermeasureSelect";
// import ControlTypeSelect from "./ControlTypeSelect";

export default function ControlActions({
  idx,
  analysis,
  riskCategory,
  control,
  watch,
  setValue,
  controlsExpanded,
  setControlsExpanded,
  fullPage,
  canAction,
}) {
  const optionSets = useOptionSets();
  const [fieldStates, setFieldStates] = useState({});
  const [controlRating, setControlRating] = useState(0);
  const [improvedControlRating, setImprovedControlRating] = useState(0);

  // const errorType = watch(analysis.errorType`);

  const controlValues = watch(`controls.${idx}`);
  const controlActionImprovement1 = watch(`controls.${idx}.controlActionImprovement1`);
  const controlActionImprovement2 = watch(`controls.${idx}.controlActionImprovement2`);
  const controlSystemTypeImproved = watch(`controls.${idx}.controlSystemTypeImproved`);
  const counterMeasureTypeImproved = watch(`controls.${idx}.counterMeasureTypeImproved`);

  useEffect(() => {
    const controlSystemIdx = parseInt(controlSystemTypeImproved);
    const counterMeasureIdx = parseInt(counterMeasureTypeImproved);
    if (!isNaN(controlSystemIdx) && !isNaN(counterMeasureIdx)) {
      setImprovedControlRating(controlRatingMatrix[counterMeasureTypeImproved][controlSystemTypeImproved]);
    } else {
      setImprovedControlRating(0);
    }
  }, [controlSystemTypeImproved, counterMeasureTypeImproved]);

  useEffect(() => {
    const controlSystemIdx = parseInt(analysis.controlSystemType);
    const counterMeasureIdx = parseInt(analysis.counterMeasureType);
    if (!isNaN(controlSystemIdx) && !isNaN(counterMeasureIdx)) {
      setControlRating(controlRatingMatrix[analysis.counterMeasureType.toString()][analysis.controlSystemType.toString()]);
    }
  }, [analysis]);

  useEffect(() => {
    if (analysis) {
      var initialFieldStates = {};
      for (var i = 1; i < 3; i++) {
        if (Boolean(analysis["controlActionImprovement" + i]) && Boolean(analysis["controlActionImprovement" + (i + 1)]))
          initialFieldStates["controlActionImprovement" + i] = analysis["controlActionImprovement" + i];
      }
      setFieldStates(initialFieldStates);
    }
  }, [analysis]);

  const removeImprovement = (name, prevNum, fullName) => {
    var newStates = Object.assign({}, fieldStates);
    delete newStates[name.slice(0, -1) + prevNum.toString()];
    setFieldStates(newStates);
    setValue(fullName, "");
  };

  const removeControlActions = () => {
    setFieldStates({});
    setValue(`controls.${idx}.controlAction`, null, { shouldValidate: true, shouldDirty: true, shouldtouch: true });
    setValue(`controls.${idx}.controlActionImprovement1`, null, { shouldValidate: true, shouldDirty: true, shouldtouch: true });
    setValue(`controls.${idx}.controlActionImprovement2`, null, { shouldValidate: true, shouldDirty: true, shouldtouch: true });
    setValue(`controls.${idx}.controlActionImprovement3`, null, { shouldValidate: true, shouldDirty: true, shouldtouch: true });
    setValue(`controls.${idx}.controlSystemTypeImproved`, null, { shouldValidate: true, shouldDirty: true, shouldtouch: true });
    setValue(`controls.${idx}.counterMeasureTypeImproved`, null, { shouldValidate: true, shouldDirty: true, shouldtouch: true });
  };

  const renderFactorHead = (label, icon) => (
    <Box sx={{ display: "flex", width: 1 }}>
      <Box sx={{ width: 0.5, px: 3, py: 0, display: "flex", backgroundColor: "background.greyLight" }}>
        {icon}
        <Typography sx={{ pl: 1 }} color="text.secondary" variant="overline">
          {label}s
        </Typography>
      </Box>
      <Box sx={{ width: 0.5, px: 3, py: 0 }}></Box>
    </Box>
  );

  const removeFactorAction = (name) => {
    setValue(`controls.${idx}.${name}Action`, null, { shouldValidate: true, shouldDirty: true, shouldtouch: true });
    setValue(`controls.${idx}.${name}ActionImprovement`, "", { shouldValidate: true, shouldDirty: true, shouldtouch: true });
  };



  const renderFactor = (name, num, isCogHaz) => (
    <Box sx={{ display: "flex", width: 1 }}>
      <Box sx={{ width: 0.5, px: 3, pt: controlValues[`${name}Action`] === true ? 3 : 1, display: "flex", flexWrap: "wrap", backgroundColor: "background.greyLight" }}>
        <Typography sx={{ mb: 1, ml: 4, fontWeight: 500, flex: 1 }}>
          {num}. {optionSets.itemsById[controlValues[name]].label}
        </Typography>
        {!!controlValues[`${name}Notes`] && <Annotation value={controlValues[`${name}Notes`]} />}
      </Box>
      <Box sx={{ width: 0.5, px: 3, py: 0, alignItems: "center" }}>
        {controlValues[`${name}Action`] === true ? (
          <Box sx={{ display: "flex", alignItems: "flex-start", pt: 2 }}>
            <Box sx={{ flex: 1 }}>
              <TextInput
                disabled={!canAction}
                name={`controls.${idx}.${name}ActionImprovement`}
                label={`Actions to address ${isCogHaz ? "hazard" : "factor"}`}
                control={control}
                sx={mb2Sx}
                multiline
                size="small"
                helper={true}
              />
            </Box>
            {canAction && <IconButton sx={{ mt: 0.5 }} onClick={() => removeFactorAction(name)} size="small">
              <RemoveIcon />
            </IconButton>}
          </Box>
        ) : (
          <Controller
            name={`controls.${idx}.${name}Action`}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <FormControl error={Boolean(fieldState.error)} component="fieldset" fullWidth>
                  {canAction && (field.value === null || field.value === "") ? (
                    <Box sx={{ pt: 0.5, width: 1, display: "flex", flexWrap: "wrap" }}>
                      <Typography sx={{ flex: 1, pt: 1 }} variant="body2" color="text.secondary">{`Add actions to address ${
                        isCogHaz ? "hazard" : "factor"
                      }?`}</Typography>
                      <Button color="error" size="small" onClick={(e) => field.onChange(false)} startIcon={<NoIcon />} sx={{ ml: 1 }}>
                        No
                      </Button>
                      <Button color="success" size="small" onClick={(e) => field.onChange(true)} startIcon={<YesIcon />} sx={{ ml: 1 }}>
                        Yes
                      </Button>
                      <FormHelperText sx={{ flexBasis: "100%" }} error id={`controls.${idx}.${name}Action-helper-text`}>
                        {fieldState.error?.message || true}
                      </FormHelperText>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "flex-start", pt: 0.5 }}>
                      <Typography sx={{ pt: 1 }} variant="body2" color="text.disabled">
                        No actions specified
                      </Typography>
                      {canAction && <Button startIcon={<UndoIcon />} sx={{ ml: 1 }} onClick={(e) => field.onChange(null)} size="small">
                        Undo
                      </Button>}
                    </Box>
                  )}
                </FormControl>
              );
            }}
          />
        )}
      </Box>
    </Box>
  );

  const renderImprovement = (num, order, name, remove) => (
    <Box sx={{ display: "flex", alignItems: "flex-start", mt: 1 }}>
      <Box sx={{ flex: 1 }}>
        <TextInput
          disabled={!canAction}
          name={`controls.${idx}.${name}`}
          label={`${order} action to strengthen control`}
          control={control}
          sx={mb2Sx}
          multiline
          size="small"
          helper={true}
        />
      </Box>
      {remove && canAction ? (
        <IconButton sx={{ mt: 0.5 }} onClick={() => removeImprovement(name, num - 1, `controls.${idx}.${name}`)} size="small">
          <RemoveIcon />
        </IconButton>
      ) : (
        <Box sx={{ width: 34 }}></Box>
      )}
    </Box>
  );

  const renderAddButton = (factor1Val, factor1Name, factor2Val, factor2Name) => {
    return (
      !(Boolean(fieldStates[factor1Name]) && Boolean(fieldStates[factor2Name])) && canAction && (
        <Box sx={{ display: "flex", mt: -1 }}>
          <Button
            sx={{ ml: "auto", mr: "34px" }}
            size="small"
            variant="outlined"
            endIcon={<AddIcon />}
            disabled={!Boolean(factor1Val) || (Boolean(fieldStates[factor1Name]) && Boolean(factor1Val) && !Boolean(factor2Val))}
            onClick={() => setFieldStates(Object.assign({}, fieldStates, { [factor1Name]: factor1Val, [factor2Name]: factor2Val }))}
          >
            Add Action
          </Button>
        </Box>
      )
    );
  };

  return (
    <Box>
      <Divider />
      <Accordion
        expanded={controlsExpanded[`control${idx}`]}
        onChange={(e, expanded) => setControlsExpanded(Object.assign({}, controlsExpanded, { [`control${idx}`]: expanded }))}
        elevation={0}
      >
        <AccordionSummary sx={{ "& .MuiAccordionSummary-content": { alignItems: "center" } }} expandIcon={<ExpandMoreIcon />}>
          <Typography color="text.secondary" sx={{ pl: 1, flex: 1, fontWeight: 500, fontSize: "1.1rem" }}>
            Control {idx + 1}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Box sx={{ display: "flex", width: 1 }}>
            <Box sx={{ width: 0.5, px: 3, pt: 2, pb: 3, backgroundColor: "background.greyLight" }}>
              <Box>
                <Typography
                  component="p"
                  color="text.secondary"
                  variant="overline"
                  sx={{ lineHeight: 1.5, fontWeight: 500, fontSize: "0.9rem", mb: 2 }}
                >
                  Control Analysis
                </Typography>
                <Label>{incidentFields["controlsAnalysis"].label}</Label>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography sx={{ mb: 1, fontWeight: 500, flex: 1 }}>{optionSets.itemsById[analysis.control.toString()]?.label}</Typography>
                  {!!analysis.controlNotes && <Annotation value={analysis.controlNotes} />}
                </Box>

                <Label>{incidentFields["errorType"].label}</Label>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Typography sx={{ mb: 1, fontWeight: 500, flex: 1 }}>{optionSets.itemsById[analysis.errorType.toString()]?.label}</Typography>
                  {!!analysis.errorTypeNotes && <Annotation value={analysis.errorTypeNotes} />}
                </Box>

                <Label>{incidentFields["controlEnablement"].label}</Label>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Typography sx={{ mb: 1.5, fontWeight: 500, flex: 1 }}>
                  {controlEnablemnt.find((i) => i.value === analysis.controlEnablement).label}
                </Typography>
                  {!!analysis.controlEnablementNotes && <Annotation value={analysis.controlEnablementNotes} />}
                </Box>

                <Box sx={{ "&:after": { content: '""', display: "table", clear: "both" } }}>
                  <ControlRating sx={{ mt: 1 }} controlRating={controlRating} />

                  <Box sx={{ mr: 15 }}>
                    <Label>{incidentFields["controlSystemType"].label}</Label>
                    <Typography sx={{ mb: 1, fontWeight: 500 }}>
                      {controlSystemTypeOptions.find((i) => i.id === analysis.controlSystemType)?.label}
                    </Typography>

                    <Label>{incidentFields["counterMeasureType"].label}</Label>
                    <Typography sx={{ mb: 1, fontWeight: 500 }}>
                      {counterMeasureTypeOptions.find((i) => i.id === analysis.counterMeasureType)?.label}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: 0.5, px: 3, pt: 2, pb: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2, minHeight: 32 }}>
                <Typography color="text.secondary" variant="overline" sx={{ flex: 1, lineHeight: 1.5, fontWeight: 500, fontSize: "0.9rem" }}>
                  Control Improvement
                </Typography>
                {controlValues.controlAction === true && canAction && (
                  <Button sx={{ mr: 2 }} size="small" startIcon={<DeleteIcon />} onClick={() => removeControlActions()}>
                    Remove
                  </Button>
                )}
              </Box>
              {controlValues.controlAction !== true && (
                <Controller
                  name={`controls.${idx}.controlAction`}
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl error={Boolean(fieldState.error)} component="fieldset" fullWidth>
                        {canAction && (field.value === null || field.value === "") ? (
                          <Box sx={{ pt: 0.5, width: 1, display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}>
                            <Typography sx={{ flex: 1, py: 0.5, lineHeight: 2 }} variant="body2" color="text.secondary">
                              Add actions to strengthen control?
                            </Typography>
                            <Button color="error" variant="outlined" onClick={() => field.onChange(false)} startIcon={<NoIcon />} sx={{ ml: 1 }}>
                              No
                            </Button>
                            <Button color="success" variant="outlined" onClick={() => field.onChange(true)} startIcon={<YesIcon />} sx={{ ml: 1 }}>
                              Yes
                            </Button>
                            <FormHelperText sx={{ flexBasis: "100%" }} error id={`controls.${idx}.controlAction-helper-text`}>
                              {fieldState.error?.message || true}
                            </FormHelperText>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "flex-start", pt: 0.5 }}>
                            <Typography sx={{ pt: 1 }} variant="body2" color="text.disabled">
                              No control improvement specified
                            </Typography>
                            {canAction && <Button startIcon={<UndoIcon />} sx={{ ml: 1 }} onClick={(e) => field.onChange(null)} size="small">
                              Undo
                            </Button>}
                          </Box>
                        )}
                      </FormControl>
                    );
                  }}
                />
              )}
              {controlValues.controlAction === true && (
                <Box sx={{ mt: 2 }}>
                  {renderImprovement(1, "First", "controlActionImprovement1", false)}

                  {Boolean(fieldStates.controlActionImprovement1) &&
                    renderImprovement(2, "Second", "controlActionImprovement2", !fieldStates.controlActionImprovement2)}

                  {Boolean(fieldStates.controlActionImprovement2) && renderImprovement(3, "Third", "controlActionImprovement3", true)}

                  {renderAddButton(controlActionImprovement1, "controlActionImprovement1", controlActionImprovement2, "controlActionImprovement2")}

                  <Box sx={{ mt: 3 }}>
                    <ControlRating improved controlRating={improvedControlRating} />

                    <Box sx={{ mr: 14 }}>
                      <SelectInput
                        disabled={!canAction}
                        name={`controls.${idx}.controlSystemTypeImproved`}
                        control={control}
                        label={"Improved control system type"}
                        optionSet={controlSystemTypeOptions}
                        sx={fullPage ? mb3Sx : mb2Sx}
                        helper={true}
                        size="small"
                      />
                      <SelectInput
                        disabled={!canAction}
                        name={`controls.${idx}.counterMeasureTypeImproved`}
                        control={control}
                        label={"improved countermeasure type"}
                        optionSet={counterMeasureTypeOptions}
                        sx={mb2Sx}
                        size="small"
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          {optionSets["cognitiveHazard"][analysis.errorType] && (
            <Box>
              {!!analysis.cogHaz1 && renderFactorHead(incidentFields["cognitiveHazard"].label, <CognitiveIcon color="disabled" />)}

              {!!analysis.cogHaz1 && renderFactor("cogHaz1", 1, true)}
              {!!analysis.cogHaz2 && renderFactor("cogHaz2", 2, true)}
              {!!analysis.cogHaz3 && renderFactor("cogHaz3", 3, true)}
            </Box>
          )}

          {!!analysis.operationalFactor1 && renderFactorHead(incidentFields["operationalFactor"].label, <OperationalIcon color="disabled" />)}

          {!!analysis.operationalFactor1 && renderFactor("operationalFactor1", 1)}
          {!!analysis.operationalFactor2 && renderFactor("operationalFactor2", 2)}
          {!!analysis.operationalFactor3 && renderFactor("operationalFactor3", 3)}

          {!!analysis.orgFactor1 && renderFactorHead(incidentFields["organisationalFactor"].label, <OrganisationIcon color="disabled" />)}

          {!!analysis.orgFactor1 && renderFactor("orgFactor1", 1)}
          {!!analysis.orgFactor2 && renderFactor("orgFactor2", 2)}
          {!!analysis.orgFactor3 && renderFactor("orgFactor3", 3)}

          <Box sx={{ display: "flex", width: 1 }}>
            <Box sx={{ width: 0.5, pl: 3, pr: 2, pt: 3, backgroundColor: "background.greyLight" }}></Box>
            <Box sx={{ width: 0.5, pl: 2, pr: 3, py: 0 }}></Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
