import React, { Fragment, useState } from "react";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import authState from "./authState";
import { ApplicationPaths } from "./ApiAuthorizationConstants";

export default function AccountMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useRecoilValue(authState);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ml:1}}>
      {auth.authenticated === true && (
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="primary"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-account-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <ListSubheader sx={{ mt: -1, bgcolor: "grey.100" }}>
              {auth.user?.email}
            </ListSubheader>
            <MenuItem
              component={Link}
              to={ApplicationPaths.Profile}
              onClick={handleClose}
            >
              Manage Account
            </MenuItem>
            <MenuItem
              component={Link}
              to={{
                pathname: ApplicationPaths.LogOut,
                state: { local: true },
              }}
            >
              Sign Out
            </MenuItem>
          </Menu>
        </div>
      )}
      {auth.authenticated === false && (
        <Fragment>
          <Button
            component={Link}
            to={ApplicationPaths.Login}
            sx={{ ml: 2 }}
            color="primary"
            variant="contained"
          >
            Sign In
          </Button>
          {/* <Button
            component={Link}
            to={ApplicationPaths.Register}
            sx={{ ml: 2 }}
            color="primary"
            variant="contained"
          >
            Sign Up
          </Button> */}
        </Fragment>
      )}
    </Box>
  );
}
