import React, { Fragment } from "react";
//import { useRecoilState } from "recoil";
import { Link, useLocation } from "react-router-dom";
import { AppBar as MuiAppBar, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
//import HelpIcon from "@mui/icons-material/HelpOutlined";
import AccountMenu from "../auth/AccountMenu";
import TenantsAutocomplete from "../tenants/TenantsAutocomplete";
import { mainMenuWidth, routeTitles } from "./appConstants";
//import helpState from "../help/helpState";

export default function AppBar({ authenticated, toggleMainMenu, mainMenuOpen, lgUp, showTenantSelection }) {
  const location = useLocation();
  //const [help, setHelp] = useRecoilState(helpState);

  // const handleHelpClick = () => {
  //   setHelp(!help);
  // };

  return (
    <MuiAppBar
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        boxShadow: 1,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: (theme) =>
          theme.transitions.create(["marginLeft", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        marginLeft: 0,
        ...(mainMenuOpen && {
          width: `calc(100% - ${mainMenuWidth}px)`,
          marginLeft: `${mainMenuWidth}px`,
          transition: (theme) =>
            theme.transitions.create(["marginLeft", "width"], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }),
      }}
    >
      <Toolbar>
        {!mainMenuOpen && (
          <Fragment>
            {authenticated && (
              <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ position: "absolute" }} onClick={toggleMainMenu}>
                <MenuIcon color="primary" />
              </IconButton>
            )}
            <Box sx={{ ml: authenticated ? 6 : 0, mr: 4, display: "flex" }}>
              <Link to="/" style={{ display: "inline-block", height: "39px" }}>
                <img src="/images/logo.png" alt="IA logo" style={{ aspectRatio: "500/154", height: "39px" }} />
              </Link>
            </Box>
          </Fragment>
        )}
        <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant="h6">
          {authenticated &&
            (routeTitles[location.pathname]
              ? routeTitles[location.pathname]
              : location.pathname.startsWith("/incident/")
              ? "Incident Information"
              : "")}
        </Typography>
        {authenticated && (
          <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
            {showTenantSelection && <TenantsAutocomplete sx={{ minWidth: 260 }} />}
            {/* <IconButton size="large" onClick={handleHelpClick} color="primary" sx={{ ml: 2 }}>
              <HelpIcon />
            </IconButton> */}
            <AccountMenu />
          </Box>
        )}
      </Toolbar>
    </MuiAppBar>
  );
}
