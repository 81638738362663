import React, {  useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { v4 as guid } from "uuid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextInput from "../formUtils/TextInput";
import DatePickerInput from "../formUtils/DatePickerInput";
import TimePickerInput from "../formUtils/TimePickerInput";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import RuleIcon from "@mui/icons-material/Rule";
import ControlledClientsAutocomplete from "../clients/ControlledClientsAutocomplete";
import authState from "../auth/authState";
import { mb3Sx } from "../app/appConstants";
import { yupInputRequired, yupDateInputRequired } from "../formUtils/validationRules";
import snackbarState from "../app/snackbarState";
import fetchSimple from "../services/fetchSimple";
import IncidentTabbedView from "./IncidentTabbedView";
import permissionsState from "../auth/permissionsState";

const defaultValues = {
  id: "",
  client: "",
  clientId: "",
  name: "",
  reference: "",
  injuryType: "",
  businessDivision: "",
  eventTitle: "",
  incidentMechanism: "",
  eventDetails: "",
  actual: "",
  potential: "",
  date: null,
  time: null,
  location: "",
  locationAdditional: "",
  riskCategoryClient: "",
  eventOwnership: "",
  timeWithinShift: "",
  shiftPattern: "",
  riskCategory: "",
  fatalityRisk: "",
  state: null,
  isNew: true,
};

export default function NewIncident({ open, setClose, insertNewIncident, processRowUpdate, handlePhaseChange, isClientRole }) {
  const auth = useRecoilValue(authState);
  const [, setSnackbar] = useRecoilState(snackbarState);
  const permissions = useRecoilValue(permissionsState);
  const [incident, setIncident] = useState(false);
  const [isUnsaved, setIsUnsaved] = useState(true);

  var clientYup = yup.object().nullable();
  if(!isClientRole) clientYup.required("A value is required");

  const newIncidentFormSchema = yup.object().shape({
    client: clientYup,
    reference: yupInputRequired,
    eventTitle: yupInputRequired,
    eventDetails: yupInputRequired,
    date: yupDateInputRequired,
    time: yupDateInputRequired,
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(newIncidentFormSchema),
    mode: "onBlur",
    defaultValues: Object.assign({}, defaultValues, { id: guid() }),
  });

  // useEffect(() => {
  //   if(open && setFocus) setTimeout(() => setFocus("name"), 500);
  // }, [open, setFocus]);

  const handleClose = () => {
    reset(Object.assign({}, defaultValues, { id: guid() }));
    setClose();
    setIncident(false);
    setIsUnsaved(true);
  };

  const saveIncident = (data, insert) => {
    data.assessOnCreation = true;
    data.clientId = data.client?.id;
    data.clientName = data.client?.name;
    setIncident({ fetching: true });
    return fetchSimple("/api/incidents", "POST", auth.token, null, null, data).then((newIncident) => {
      const updatedIncident = { ...newIncident, isNew: false };
      if (insertNewIncident && insert) insertNewIncident(updatedIncident);
      return updatedIncident;
    });
  };

  const saveAndClose = (data) => {
    setSnackbar({ severity: "info", message: "Saving incident" });
    saveIncident(data, true).then((newIncident) => {
      if(newIncident.id) setSnackbar({ severity: "success", message: "Incident saved successfully" });
      else setSnackbar({ severity: "error", message: "Something went wrong saving the incident" });
      handleClose();
    });
  };

  const saveAndAssess = (data) => {
    setSnackbar({ severity: "info", message: "Saving incident" });
    saveIncident(data).then((newIncident) => {
      if(newIncident.id) {
        setIncident(newIncident);
        setIsUnsaved(false);
        setSnackbar({ severity: "success", message: "Incident saved successfully" });
      } 
      else {
        setSnackbar({ severity: "error", message: "Something went wrong saving the incident" });
        handleClose();
      }
    });
  };

  const handleInternalPhaseChange = (updatedIncident) => {
    setIncident(updatedIncident);
    handlePhaseChange(updatedIncident, true);
  };

  return (
    <Dialog
      sx={{ maxHeight: "100vh", overflowY: "hidden" }}
      open={open}
      onClose={handleClose}
      maxWidth={isUnsaved ? "sm" : "lg"}
      fullWidth
      keepMounted
    >
      <Toolbar>
        <DialogTitle variant="h6" sx={{ pl: 0, flex: 1 }}>
           {!isUnsaved && !incident.fetching ? `Add Incident: ${incident.reference} - ${incident.eventTitle}` : "Add Incident"}
        </DialogTitle>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <DialogContent sx={{ p: 0, maxHeight: "calc(100vh - 128px)", overflowY: "auto" }}>
        {isUnsaved && !incident.fetching && (
          <form noValidate>
            <Box sx={{ p: 3 }}>
              {!isClientRole && <Box sx={mb3Sx}>
                <ControlledClientsAutocomplete name="client" control={control} helper={true} />
              </Box>}

              <TextInput name="reference" label="Reference" control={control} sx={mb3Sx} helper={true} />
              <TextInput name="eventTitle" label="Event title" control={control} sx={mb3Sx} helper={true} />
              <TextInput name="eventDetails" label="Event details" multiline control={control} sx={mb3Sx} helper={true} />
              <DatePickerInput name="date" label="Event Date" control={control} sx={mb3Sx} helper={true} />
              <TimePickerInput name="time" label="Event Time" control={control} sx={mb3Sx} helper={true} />

              <Box sx={{ display: "flex" }}>
                <Button onClick={handleClose} startIcon={<CloseIcon />} sx={{ mr: "auto" }}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit(saveAndClose)}
                  disabled={incident.fetching}
                  startIcon={<SaveIcon />}
                  sx={{ mr: 2 }}
                  color="secondary"
                >
                  Save & Close
                </Button>
                {!permissions.fetching && permissions.granted[permissions.all.incidentAssess] && <Button
                  onClick={handleSubmit(saveAndAssess)}
                  disabled={incident.fetching}
                  startIcon={<RuleIcon />}
                  variant="contained"
                  color="secondary"
                >
                  Assessment
                </Button>}
              </Box>
            </Box>
          </form>
        )}
        {!!incident && (
          <IncidentTabbedView
            incident={incident}
            incidentFetching={incident.fetching}
            handlePhaseChange={handleInternalPhaseChange}
            processRowUpdate={processRowUpdate}
            hideTitle
          />
        )}
      </DialogContent>
      {/* <DialogActions sx={{ p: 3, minHeight: 85 }}>

        </DialogActions> */}
    </Dialog>
  );
}
