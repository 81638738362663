import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import Box from "@mui/material/Box";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Done from "@mui/icons-material/Done";
import Close from "@mui/icons-material/Close";
import ScrollContainer from "../app/ScrollContainer";
import ClientsToolbar from "./ClientsToolbar";
import ClientsMenu from "./ClientsMenu";
import NewEditClient from "./NewEditClient";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import snackbarState from "../app/snackbarState";

export default function ClientsPage(props) {
  const auth = useRecoilValue(authState);
  const [, setSnackbar] = useRecoilState(snackbarState);
  const [clients, setClients] = useState([]);
  const [fetching, setFetching] = useState({ fetching: true });
  const [editClientOpen, setEditClientOpen] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(null);

  async function fetchClients() {
    await fetchSimple("/api/clients?includeInactive=true", "GET", auth.token, setFetching, setClients).finally(() => setFetching(false));
  }

  useEffect(() => {
    async function loadClients() {
      await fetchSimple("/api/clients?includeInactive=true", "GET", auth.token, setFetching, setClients).finally(() => setFetching(false));
    }
    loadClients();
  }, [auth.token, setClients]);

  function insertNewClient(newClient) {
    var newClients = Object.assign([], clients);
    newClients.push(newClient);
    setClients(newClients);
  }

  function openNewClient() {
    setEditClientOpen(true);
  }

  function openEditClient(row) {
    setClientToEdit(row);
    setEditClientOpen(true);
  }

  function handleUserModalClose() {
    setEditClientOpen(false);
    setClientToEdit(null);
  }

  async function disableClient(client) {
    setSnackbar({ severity: "info", message: "Disabling client" });
    await fetchSimple(`/api/clients/disable`, "POST", auth.token, null, null, client).finally(() => {
      fetchClients();
      setSnackbar({ severity: "success", message: "Disablement complete" });
    });
  }

  async function activateClient(client) {
    setSnackbar({ severity: "info", message: "Activating client" });
    await fetchSimple(`/api/clients/activate`, "POST", auth.token, null, null, client).finally(() => {
      fetchClients();
      setSnackbar({ severity: "success", message: "Client successfully activated" });
    });
  }

  return (
    <ScrollContainer>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={(theme) => {
            return {
              flexGrow: 1,
              bgcolor: theme.palette.common.white,
              "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(3) },
              [`${theme.breakpoints.down("sm")}`]: {
                "& .MuiDataGrid-row > .left-cell, & .MuiDataGrid-columnHeader.left-cell": { paddingLeft: theme.spacing(2) },
              },
            };
          }}
        >
          <DataGridPremium
            sx={{ border: 0 }}
            loading={fetching.fetching}
            rows={clients}
            hideFooter={true}
            slots={{
              toolbar: ClientsToolbar,
            }}
            slotProps={{
              toolbar: {
                clientsCount: clients ? clients.length : 0,
                openNewClient: openNewClient,
              },
            }}
            columns={[
              { field: "name", headerName: "Name", headerClassName: "left-cell", cellClassName: "left-cell", flex: 1 },
              {
                field: "active",
                headerName: "Active",
                type: "boolean",
                renderCell: (params) => {
                  return params.value ? (
                    <Done sx={{ color: (theme) => theme.palette.success.main }} />
                  ) : (
                    <Close sx={{ color: (theme) => theme.palette.text.disabled }} />
                  );
                },
              },
              {
                field: "disableParticipantInheritanceFromTenant",
                headerName: "Inherit Tenant Participants",
                type: "boolean",
                renderCell: (params) => {
                  return params.value ? (
                    <Close sx={{ color: (theme) => theme.palette.text.disabled }} />
                  ) : (
                    <Done sx={{ color: (theme) => theme.palette.success.main }} />
                  );
                },
              },
              {
                field: "id",
                headerName: "Actions",
                width: 58,
                disableColumnMenu: true,
                hideSortIcons: true,
                filterable: false,
                resizable: false,
                sortable: false,
                align: "right",
                renderCell: (params) => (
                  <ClientsMenu params={params} openEditClient={openEditClient} activateClient={activateClient} disableClient={disableClient} />
                ),
                renderHeader: (params) => <span></span>,
              },
            ]}
          />

          <NewEditClient
            open={editClientOpen}
            setClose={handleUserModalClose}
            clientToEdit={clientToEdit}
            saveCallback={clientToEdit ? fetchClients : insertNewClient}
          />
        </Box>
      </Box>
    </ScrollContainer>
  );
}
