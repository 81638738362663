import React, { Fragment } from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

export default function CheckboxInput({ name, label, control, helper, sx, componentSx, handleChange }) {
  return (
    <Box sx={sx}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <Fragment>
              <FormControl sx={sx} error={fieldState.error} component="fieldset" fullWidth>
                <FormGroup sx={componentSx && componentSx.group}>
                  <FormControlLabel
                    sx={componentSx && componentSx.formControlLabel}
                    control={
                      <Checkbox
                        color="secondary"
                        value={field.value}
                        checked={!!field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          if (handleChange) handleChange();
                        }}
                      />
                    }
                    label={label}
                  />
                </FormGroup>
                <FormHelperText id={`${name}-helper-text`}>{(fieldState.isTouched && fieldState.error?.message) || helper || " "}</FormHelperText>
              </FormControl>
            </Fragment>
          );
        }}
      />
    </Box>
  );
}
