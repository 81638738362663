import React from 'react'
import { useRecoilValue } from 'recoil';
import authState from "./authState";


export default function SysAdminRoute({ children }) {
    
    const auth = useRecoilValue(authState)

    return ["sysadmin"].indexOf(auth.user?.role) !== -1 ? children : <div></div>

}
