import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import VerifiedIcon from "@mui/icons-material/Verified";
import Divider from "@mui/material/Divider";
import {  incidentFields } from "../app/appConstants";
import { yupBooleanRequired, yupInputRequiredIfOtherFieldFalse, yupBooleanRequiredIfOtherFieldNotNull } from "../formUtils/validationRules";
import Loader from "../app/Loader";
import ControlApproval from "./ControlApproval";
import useOptionSets from "../optionsets/useOptionSets";
import snackbarState from "../app/snackbarState";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import permissionsState from "../auth/permissionsState";

const defaultValues = {
  controls: [],
};

export default function IncidentApproval({ incident, analysis, handlePhaseChange, fullPage }) {
  const optionSets = useOptionSets();
  const auth = useRecoilValue(authState);
  const permissions = useRecoilValue(permissionsState);
  const canApprove = !permissions.fetching && permissions.granted[permissions.all.incidentApprove];
  const [, setSnackbar] = useRecoilState(snackbarState);
  const [controlsExpanded, setControlsExpanded] = useState({ control0: true, control1: true, control2: true });

  const controlsFormSchema = yup.object().shape({
    controls: yup.array().of(
      yup.object().shape({
        cogHaz1ActionQANotes: yupInputRequiredIfOtherFieldFalse("cogHaz1ActionQAState"),
        cogHaz1ActionQAState: yupBooleanRequiredIfOtherFieldNotNull("cogHaz1"),
        cogHaz2ActionQANotes: yupInputRequiredIfOtherFieldFalse("cogHaz2ActionQAState"),
        cogHaz2ActionQAState: yupBooleanRequiredIfOtherFieldNotNull("cogHaz2"),
        cogHaz3ActionQANotes: yupInputRequiredIfOtherFieldFalse("cogHaz3ActionQAState"),
        cogHaz3ActionQAState: yupBooleanRequiredIfOtherFieldNotNull("cogHaz3"),
        controlQANotes: yupInputRequiredIfOtherFieldFalse("controlQAState"),
        controlQAState: yupBooleanRequired,
        operationalFactor1ActionQANotes: yupInputRequiredIfOtherFieldFalse("operationalFactor1ActionQAState"),
        operationalFactor1ActionQAState: yupBooleanRequiredIfOtherFieldNotNull("operationalFactor1"),
        operationalFactor2ActionQANotes: yupInputRequiredIfOtherFieldFalse("operationalFactor2ActionQAState"),
        operationalFactor2ActionQAState: yupBooleanRequiredIfOtherFieldNotNull("operationalFactor2"),
        operationalFactor3ActionQANotes: yupInputRequiredIfOtherFieldFalse("operationalFactor3ActionQAState"),
        operationalFactor3ActionQAState: yupBooleanRequiredIfOtherFieldNotNull("operationalFactor3"),
        orgFactor1ActionQANotes: yupInputRequiredIfOtherFieldFalse("orgFactor1ActionQAState"),
        orgFactor1ActionQAState: yupBooleanRequiredIfOtherFieldNotNull("orgFactor1"),
        orgFactor2ActionQANotes: yupInputRequiredIfOtherFieldFalse("orgFactor2ActionQAState"),
        orgFactor2ActionQAState: yupBooleanRequiredIfOtherFieldNotNull("orgFactor2"),
        orgFactor3ActionQANotes: yupInputRequiredIfOtherFieldFalse("orgFactor3ActionQAState"),
        orgFactor3ActionQAState: yupBooleanRequiredIfOtherFieldNotNull("orgFactor3"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { isDirty, isSubmitting },
  } = useForm({
    resolver: yupResolver(controlsFormSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const { fields } = useFieldArray({
    control,
    keyName: "controlId",
    name: "controls",
  });

  useEffect(() => {
    if (analysis) {
      reset(analysis);
    }
  }, [analysis, reset]);


  const handleApprovedPhaseChange = () => {
    handlePhaseChange(Object.assign({}, incident, {state: "approved"}));
  }

  const handleComplete = async (formData) => {
    setSnackbar({ severity: "info", message: "Saving approval" });
    await fetchSimple(`/api/analysis/approval`, "POST", auth.token, null, handleApprovedPhaseChange, formData).then((data) =>
      reset(Object.assign({}, { id: incident.id }, data))
    );
    setSnackbar({ severity: "success", message: "Approval saved successfully" });
  };

  const focusErrors = (errors) => {
    var expanded = errors.controls.reduce((obj, item, i) => Object.assign(obj, { [`control${i}`]: true }), {});
    setControlsExpanded(Object.assign({}, controlsExpanded, expanded));
  };

  const riskCategory = watch("riskCategory");

  return (
    <form noValidate style={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          borderColor: (theme) => theme.palette.divider,
          backgroundColor: (theme) => theme.palette.background.white,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flex: "1 1 auto", overflowY: "auto" }}>
          {!optionSets.fetching && (
            <Box>
              <Box sx={{ px: 3, py: 3 }}>
                <Typography color="text.secondary" variant="overline" sx={{ lineHeight: 1.5 }}>
                  {incidentFields["riskCategory"]?.label}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
                  {optionSets.itemsById[analysis.riskCategory.toString()]?.label}
                </Typography>
              </Box>
            </Box>
          )}
          {analysis.fetching || optionSets.fetching ? (
            <Loader />
          ) : (
            <Box sx={{ mb: 3 }}>
              {fields.map((ca, idx) => (
                <ControlApproval
                  canApprove={canApprove}
                  key={ca.id}
                  idx={idx}
                  controlsExpanded={controlsExpanded}
                  setControlsExpanded={setControlsExpanded}
                  control={control}
                  analysis={ca}
                  riskCategory={riskCategory}
                  watch={watch}
                  setValue={setValue}
                  trigger={trigger}
                  fullPage={!!fullPage}
                />
              ))}
            </Box>
          )}
        </Box>

        {fullPage && <Divider />}

        {canApprove && <Box
          sx={{
            p: 3,
            backgroundColor: fullPage ? "background.white" : "background.mainLight",
            borderTop: fullPage ? 0 : 1,
            borderColor: "divider",
            ...(fullPage ? { px: 3, pb: 3 } : { flex: "0 1 90px", boxShadow: 4, clipPath: "polygon(0% -20%, 100% -20%, 100% 100%, 0% 100%)" }),
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{ ml: "auto" }}
              startIcon={<VerifiedIcon />}
              disabled={analysis.fetching || fields.length === 0 || isSubmitting || (incident.state !== "analysis" && !isDirty)}
              color="success"
              variant="contained"
              size="large"
              onClick={handleSubmit(handleComplete, focusErrors)}
            >
              Approve
            </Button>
          </Box>
        </Box>}
      </Box>
    </form>
  );
}
