import React from "react";
import Box from "@mui/material/Box";

export default function ScrollContainer({ children, sx, ...rest }) {
  return (
    <Box
      sx={{
        mt: { xs: 7, sm: 8 },
        position: "relative",
        height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
        overflow: "auto",
        ...sx
      }}
      id="pageScroll"
      {...rest}
    >
      {children}
    </Box>
  );
}
