import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import IncidentInfoAccordion from "./IncidentInfoAccordion";
//import ToggleButtonInput from "../formUtils/ToggleButtonInput";
import TextInput from "../formUtils/TextInput";
//import SelectInput from "../formUtils/SelectInput";
import DatePickerInput from "../formUtils/DatePickerInput";
import TimePickerInput from "../formUtils/TimePickerInput";
import { mb025Sx, incidentFields } from "../app/appConstants";
//import useOptionSets from "../optionsets/useOptionSets";
import Label from "../app/Label";

export default function IncidentEventDetails({ control, setValue, handleBlur, handleChange, incident, canEdit }) {
  //const optionSets = useOptionSets();

  return (
    <IncidentInfoAccordion defaultExpanded title="Incident Details">
      {canEdit ? (
        <TextInput
          name="reference"
          label={incidentFields["reference"].label}
          control={control}
          sx={mb025Sx}
          size="small"
          handleBlur={handleBlur}
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["reference"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.reference}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="eventTitle"
          label={incidentFields["eventTitle"].label}
          control={control}
          sx={mb025Sx}
          size="small"
          handleBlur={handleBlur}
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["eventTitle"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.eventTitle}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TextInput
          name="eventDetails"
          label={incidentFields["eventDetails"].label}
          multiline
          control={control}
          sx={mb025Sx}
          size="small"
          handleBlur={handleBlur}
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["eventDetails"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident?.eventDetails}</Typography>
        </Box>
      )}

      {canEdit ? (
        <DatePickerInput
          name="date"
          label={incidentFields["date"].label}
          control={control}
          sx={mb025Sx}
          setValue={setValue}
          size="small"
          handleChange={handleChange}
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["date"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident.date ? format(Date.parse(incident.date), "dd/MM/yyyy") : ""}</Typography>
        </Box>
      )}

      {canEdit ? (
        <TimePickerInput
          name="time"
          label={incidentFields["time"].label}
          control={control}
          sx={mb025Sx}
          setValue={setValue}
          size="small"
          handleChange={handleChange}
          fieldSx={{ "& .MuiInputBase-root": { backgroundColor: "background.white" } }}
        />
      ) : (
        <Box sx={{ mb: 2 }}>
          <Label>{incidentFields["time"].label}</Label>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>{incident.time ? format(Date.parse(incident.time), "h:mm a") : ""}</Typography>
        </Box>
      )}

      {/* {!optionSets.fetching && incident.state === "ignored" && (
        <SelectInput
          optionSet={optionSets["riskCategory"]}
          label={incidentFields["riskCategory"]?.label}
          control={control}
          sx={mb025Sx}
          setValue={setValue}
          size="small"
          handleChange={handleChange}
        />
      )}
      {incident && incident.state !== "assessment" && incident.state !== "draft" && incident.state !== "ignored" && (
        <ToggleButtonInput
          name="sif"
          label={incidentFields["sif"].label}
          options={sifOptions}
          control={control}
          sx={mb025Sx}
          size="small"
          incidentId={incident.id}
          handleChange={handleChange}
          fieldSx={{ "& .MuiButtonBase-root": { backgroundColor: "background.white" } }}
        />
      )} */}
    </IncidentInfoAccordion>
  );
}
