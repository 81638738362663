import React from "react";
import Typography from "@mui/material/Typography";

export default function Label({children, sx}) {
  return (
    <Typography component="p" color="text.secondary" variant="overline" sx={{ lineHeight: 1.5, ...sx }}>
    {children}
  </Typography>
  );
}
