import format from "date-fns/format";

export const renderDate = (value) => {
  return value ? format(Date.parse(value), "dd/MM/yyyy") : "";
};

export const renderTime = (value) => {
  return value ? format(Date.parse(value), "h:mm a") : "";
};

export const renderDateTime = (value) => {
  return value ? format(Date.parse(value), "dd/MM/yyyy h:mm a") : "";
};

