import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import fetchSimple from "../services/fetchSimple";
import authState from "../auth/authState";

export default function ControlledClientsAutocomplete({ control, name, helper }) {
  const auth = useRecoilValue(authState);
  const [clients, setClients] = useState([]);
  const [, setFetching] = useState({ fetching: true });

  useEffect(() => {
    async function fetchClients() {
      await fetchSimple("/api/clients?includeInactive=true", "GET", auth.token, setFetching, setClients).finally(() => setFetching(false));
    }
    fetchClients();
  }, [auth.token]);

  return (
    <Controller
      render={({ field, fieldState }) => (
        <Autocomplete
          disablePortal
          {...field}
          options={clients}
          getOptionLabel={(option) => (Boolean(option.name) ? option.name : "Select client")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              color="secondary"
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message || helper || " "}
              sx={{
                "& .MuiInputBase-root": { backgroundColor: "background.input", color: !!field.value ? "text.primary" : "text.secondary" },
                "& .MuiInputBase-root:hover": { backgroundColor: "background.mainLight" },
                "& .MuiInputBase-root.Mui-focused": { backgroundColor: "background.main" },
              }}
              label="Select client"
              variant="outlined"
            />
          )}
          onChange={(_, client) => field.onChange(client)}
        />
      )}
      name={name}
      control={control}
    />
  );
}
