import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useForm, useFieldArray } from "react-hook-form";
import { v4 as guid } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ActionsIcon from "@mui/icons-material/TaskAlt";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import SelectInput from "../formUtils/SelectInput";
import { mb025Sx, incidentFields } from "../app/appConstants";
import { yupInputRequired, yupSelectionRequired } from "../formUtils/validationRules";
import Loader from "../app/Loader";
import ControlAnalysis from "./ControlAnalysis";
import useOptionSets from "../optionsets/useOptionSets";
import snackbarState from "../app/snackbarState";
import authState from "../auth/authState";
import fetchSimple from "../services/fetchSimple";
import permissionsState from "../auth/permissionsState";

const defaultValues = {
  riskCategory: "",
  controls: [],
};

export default function IncidentAnalysis({ incident, analysis, handlePhaseChange, multiColumn, fullPage }) {
  const optionSets = useOptionSets();
  const auth = useRecoilValue(authState);
  const permissions = useRecoilValue(permissionsState);
  const canAnalyse = !permissions.fetching && permissions.granted[permissions.all.incidentAnalyse];
  const [, setSnackbar] = useRecoilState(snackbarState);
  //const [analysis, setAnalysis] = useState({ fetching: true });
  const [prevRiskCategory, setPrevRiskCategory] = useState(null);
  const [confirmCategoryChangeOpen, setConfirmCategoryChangeOpen] = useState(false);
  const [controlsExpanded, setControlsExpanded] = useState({ control0: true, control1: true, control2: true });
  const [confirmDeleteIdx, setConfirmDeleteIdx] = useState(null);

  const cogHazRequired = (errorType) => {
    if (!errorType) return false;
    const errorTypeLabel = optionSets["errorType"].find((o) => o.id === parseInt(errorType)).label;
    return ["Mistake", "Slip/lapse"].indexOf(errorTypeLabel) !== -1;
  };

  const operationalFactorRequired = (errorType) => {
    if (!errorType) return false;
    const errorTypeLabel = optionSets["errorType"].find((o) => o.id === parseInt(errorType)).label;
    return ["Act of Sabotage", "Exceptional Violation", "Routine Violation", "Situational Violation"].indexOf(errorTypeLabel) !== -1;
  };

  const controlsFormSchema = yup.object().shape({
    riskCategory: yupInputRequired,
    controls: yup.array().of(
      yup.object().shape({
        control: yupSelectionRequired,
        errorType: yupSelectionRequired,
        controlSystemType: yupSelectionRequired,
        counterMeasureType: yupSelectionRequired,
        controlEnablement: yupSelectionRequired,
        cogHaz1: yup
          .string()
          .nullable()
          .when("errorType", {
            is: (errorType) => cogHazRequired(errorType),
            then: yupSelectionRequired,
          }),
        operationalFactor1: yup
          .string()
          .nullable()
          .when("errorType", {
            is: (errorType) => operationalFactorRequired(errorType),
            then: yupSelectionRequired,
          }),
        orgFactor1: yup
          .string()
          .nullable()
          .when("controlEnablement", {
            is: (controlEnablement) => controlEnablement === "nonenabled",
            then: yupSelectionRequired,
          }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useForm({
    resolver: yupResolver(controlsFormSchema),
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    keyName: "controlId",
    name: "controls",
  });

  useEffect(() => {
    if (analysis) {
      reset(analysis);
    }
  }, [analysis, reset]);

  const addControl = () => {
    setControlsExpanded(Object.assign({}, controlsExpanded, { [`control${fields.length - 1}`]: false }));
    const id = guid();
    append({
      id,
      clientId: incident.clientId,
      incidentId: incident.id,
      control: "",
      controlNotes: "",
      controlEnablement: "",
      controlEnablementNotes: "",
      errorType: "",
      errorTypeNotes: "",
      cogHaz1: "",
      cogHaz1Notes: "",
      cogHaz2: "",
      cogHaz2Notes: "",
      cogHaz3: "",
      cogHaz3Notes: "",
      operationalFactor1: "",
      operationalFactor1Notes: "",
      operationalFactor2: "",
      operationalFactor2Notes: "",
      operationalFactor3: "",
      operationalFactor3Notes: "",
      orgFactor1: "",
      orgFactor1Notes: "",
      orgFactor2: "",
      orgFactor2Notes: "",
      orgFactor3: "",
      orgFactor3Notes: "",
      controlSystemType: "",
      counterMeasureType: "",
      isNew: true,
    });
  };

  const confirmDelete = (e, idx) => {
    e.stopPropagation();
    setConfirmDeleteIdx(idx);
  };

  const deleteControl = () => {
    if (!fields[confirmDeleteIdx].isNew) {
      fetchSimple(`/api/analysis/${fields[confirmDeleteIdx].id}`, "DELETE", auth.token, null, null, null);
    }
    remove(confirmDeleteIdx);
    setConfirmDeleteIdx(null);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteIdx(null);
  };

  const handleActionPhaseChange = (data) => {
    handlePhaseChange(Object.assign({}, incident, {state: "actions", riskCategory: data.riskCategory}));
  }

  const handleIgnorePhaseChange = () => {
    handlePhaseChange(Object.assign({}, incident, {state: "ignored"}));
  }

  const handleIgnoreClick = async () => {
    setSnackbar({ severity: "info", message: "Ignoring incident" });
    await fetchSimple(`/api/incidents/ignore-analysis`, "POST", auth.token, null, handleIgnorePhaseChange, incident);
    setSnackbar({ severity: "success", message: "Incident updated successfully" });
  };

  const handleComplete = async (formData) => {
    setSnackbar({ severity: "info", message: "Saving controls" });
    await fetchSimple(`/api/analysis`, "POST", auth.token, null, handleActionPhaseChange, formData).then((data) =>
      reset(Object.assign({}, { id: incident.id }, data))
    );
    setSnackbar({ severity: "success", message: "Controls saved successfully" });
  };

  const focusErrors = (errors) => {
    var expanded = errors.controls.reduce((obj, item, i) => Object.assign(obj, { [`control${i}`]: true }), {});
    setControlsExpanded(Object.assign({}, controlsExpanded, expanded));
  };

  const handleRiskCategoryChange = (prevVal) => {
    setPrevRiskCategory(prevVal);
    if (fields.length) setConfirmCategoryChangeOpen(true);
  };

  const handleConfirmRiskCategoryChangeClose = () => {
    setConfirmCategoryChangeOpen(false);
  };

  const commitRiskCategoryChange = () => {
    handleConfirmRiskCategoryChangeClose();
    var existingIds = fields.filter((f) => !f.isNew).map((f) => f.id);
    if (existingIds.length) {
      existingIds.forEach((id) => fetchSimple(`/api/analysis/${id}`, "DELETE", auth.token, null, null, null));
    }
    remove();
  };

  const cancelRiskCategoryChange = () => {
    setValue("riskCategory", prevRiskCategory);
    handleConfirmRiskCategoryChangeClose();
  };

  const riskCategory = watch("riskCategory");

  return (
    <form noValidate style={{ width: "100%", height: "100%", overflowY: "auto" }}>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          borderLeft: 0,
          borderColor: "palette.divider",
          backgroundColor: "background.white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <ContentToolbar title="Incident Analysis" /> */}

        <Box sx={{ flex: "1 1 auto", overflowY: "auto" }}>
          {!optionSets.fetching && (
            <Box>
              <Box sx={{ px: 3, py: 3 }}>
                <Box
                  sx={(theme) => {
                    return { [theme.breakpoints.up("md")]: { maxWidth: 0.5, pr: 2, pt: 1 } };
                  }}
                >
                  <SelectInput
                    disabled={!canAnalyse}
                    optionSet={optionSets["riskCategory"]}
                    label={incidentFields["riskCategory"]?.label}
                    control={control}
                    sx={mb025Sx}
                    setValue={setValue}
                    handleBeforeChange={handleRiskCategoryChange}
                    helper={true}
                    selectSx={{
                      "&.MuiInputBase-root": { backgroundColor: (theme) => theme.palette.background.greenLight },
                      "&.MuiInputBase-root:hover": { backgroundColor: (theme) => theme.palette.background.green },
                      "&.MuiInputBase-root.Mui-disabled": { backgroundColor: "background.white" },
                      "&.MuiInputBase-root.Mui-disabled:hover": { backgroundColor: "background.white" },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {analysis.fetching || optionSets.fetching ? (
            <Loader />
          ) : (
            <Box sx={{ mb: 3 }}>
              {fields.length === 0 ? (
                <Box
                  sx={{
                    p: 1,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: 1,
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ p: 6, mx: "auto", color: "text.secondary", textAlign: "center" }}>
                    There is no analysis for this incident.{" "}
                    {canAnalyse && <Link sx={{ cursor: "pointer" }} onClick={addControl} variant="body1">
                      Add a control
                    </Link>}{" "}
                    {canAnalyse && "to get started."}
                  </Typography>
                </Box>
              ) : (
                fields.map((ca, idx) => (
                  <ControlAnalysis
                    canAnalyse={canAnalyse}
                    key={ca.id}
                    idx={idx}
                    controlsExpanded={controlsExpanded}
                    setControlsExpanded={setControlsExpanded}
                    control={control}
                    analysis={ca}
                    riskCategory={riskCategory}
                    watch={watch}
                    setValue={setValue}
                    confirmDelete={confirmDelete}
                    fullPage={true}
                  />
                ))
              )}
              {canAnalyse && <Button
                sx={{ m: 3, mb: fullPage ? 0 : 3 }}
                disabled={fields.length === 3}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={addControl}
              >
                Add Control
              </Button>}

              <Dialog open={confirmDeleteIdx !== null} onClose={handleConfirmDeleteClose}>
                <DialogTitle>Delete Control?</DialogTitle>
                <DialogContent>
                  <DialogContentText>Deleting a control is permanent and cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleConfirmDeleteClose} color="success">
                    Cancel
                  </Button>
                  <Button onClick={deleteControl} autoFocus color="error" variant="contained">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={confirmCategoryChangeOpen} onClose={handleConfirmRiskCategoryChangeClose}>
                <DialogTitle>Change Risk Category?</DialogTitle>
                <DialogContent>
                  <DialogContentText>Changing the risk category will cause all existing controls to be permanently deleted.</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={cancelRiskCategoryChange} color="success">
                    Cancel
                  </Button>
                  <Button onClick={commitRiskCategoryChange} autoFocus color="error" variant="contained">
                    Continue
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </Box>

        {fullPage && <Divider />}

        {canAnalyse && <Box
          sx={{
            p: 3,
            backgroundColor: fullPage ? "background.white" : "background.mainLight",
            borderTop: fullPage ? 0 : 1,
            borderColor: "divider",
            ...(fullPage ? { px: 3, pb: 3 } : { flex: "0 1 90px", boxShadow: 4, clipPath: "polygon(0% -20%, 100% -20%, 100% 100%, 0% 100%)" }),
          }}
        >
          <Box sx={{ display: "flex" }}>
            {incident.state === "analysis" && (
              <Button
                sx={{ backgroundColor: "background.white" }}
                startIcon={<CloseIcon />}
                color="error"
                variant="outlined"
                size="large"
                onClick={handleIgnoreClick}
                disabled={analysis.fetching}
              >
                Ignore
              </Button>
            )}
            <Button
              sx={{ ml: "auto" }}
              startIcon={<ActionsIcon />}
              disabled={analysis.fetching || fields.length === 0 || isSubmitting || (incident.state !== "analysis" && !isDirty)}
              color="success"
              variant="contained"
              size="large"
              onClick={handleSubmit(handleComplete, focusErrors)}
            >
              Actions
            </Button>
          </Box>
        </Box>}
      </Box>
    </form>
  );
}
