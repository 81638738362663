import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    background: {
      main: '#f3f1fa',
      mainLight: '#f9f8ff',
      greyLight: '#fcfaf9',
      greyMid: '#f8f4f2',
      input: '#fafafa',
      white: '#fff',
      blue: "#d7f4ff",
      dark: "#0d182e",
      greenLight: "#01a18114",
      green: "#01a18129",
      yellow: '#fdf8e2',
    },
    primary: {
      main: '#4540a5',
      contrastText: '#ffffff',
      background:'#e7e9ed'
    },
    secondary: {
      light: '#34ebc7',
      main: '#01a181',
      contrastText: '#fff',
    },
    note: {
      light: "#FFECA1",
      main: "#D18E00",
    },
    text: {
      primary: "#0d182e"
    },
    border: {
      main: "#e0e0e0"
    },
    draft: {
      main: "#ffc107",
      contrastText: '#0d182e'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2250,
    }
  }
});

export default theme;