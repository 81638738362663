import React from "react";
import { useRecoilState } from "recoil";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
//import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
//import Breadcrumbs from "@mui/material/Breadcrumbs";
import CloseIcon from "@mui/icons-material/Close";
import helpState from "./helpState";
import HelpPageAnalysisErrorTypes from "./HelpPageAnalysisErrorTypes";
import HelpIndex from "./HelpIndex";

export default function HelpDrawer({ variant, anchor, drawerSize, children }) {
  const [help, setHelp] = useRecoilState(helpState);

  const dimensions = {
    width: drawerSize.right ? drawerSize.right : drawerSize,
    top: { xs: "56px", sm: "64px" },
    height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
    [`& .MuiDrawer-paper`]: {
      width: drawerSize.right ? drawerSize.right : drawerSize,
      boxSizing: "border-box",
      top: { xs: "56px", sm: "64px" },
      height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
    },
  };

  return (
    <Drawer
      open={Boolean(help)}
      anchor={anchor ?? "right"}
      variant={variant ? variant : "persistent"}
      onClose={() => setHelp(false)}
      PaperProps={{ sx: { overflowY: "hidden" } }}
      sx={{
        ...dimensions,
        flexShrink: 0,
        overflow: "hidden",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          pl: 2,
          bgcolor: "background.greyLight",
          borderBottom: 1,
          borderBottomColor: "border.main",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            alignSelf: "center",
            pr: 2,
            fontWeight: "regular",
          }}
        >
          User guide
        </Typography>
        <Box sx={{ flex: "1" }}></Box>
        <IconButton sx={{ mr: -1 }} onClick={() => setHelp(false)}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto"
        }}
      >
        {help === true ? <HelpIndex /> : <Box
        sx={{
          "& .MuiTypography-h6.sticky-head": { cursor:"pointer", mb: 2.5, mt: 2.5, minHeight: 40, fontWeight:500, lineHeight: "40px", bgcolor:"background.white", color:"primary.main" },
          "& .MuiTypography-h6": { mb: 2, mt: 2, fontWeight:400 },
          "& .MuiTypography-body1": { mb: 1.5 },
          "& ol, & ul": { my: 0 },
          "& li": { mb: 1 },
          px: 3,
          pt: 1,
          pb: 3,
        }}>
        {help.page === "Control Enablement" && <HelpPageAnalysisErrorTypes />}

        </Box>}
      </Box>
    </Drawer>
  );
}
