import React from "react";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { TimePicker } from "@mui/x-date-pickers-pro";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function TimePickerInputCell({ id, value, field }) {
  const apiRef = useGridApiContext();

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  // const handleRef = (element) => {
  //   if (element) {
  //     const input = element.querySelector(`input[value="${value}"]`);

  //     input?.focus();
  //   }
  // };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderWidth: 0 },
        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input": { fontSize: "14px" },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
}
