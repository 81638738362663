import download from "downloadjs";

export default function fetchFileDownload(apiRoute, token, fileName, callback) {
    fetch(apiRoute, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(function(resp) {
      if(resp.status !== 200)
        throw Error("Forbidden");
      return resp.blob();
    }).then(function(blob) {
      download(blob, fileName);
      if(callback) { 
        callback();
      }
    }).catch((error) => {
      console.error("Error:", error);
    });;
}