import React from "react";
import Chip from "@mui/material/Chip";
//import QaIcon from "@mui/icons-material/ReviewsOutlined";
import format from "date-fns/format";
import { incidentStatus } from "../app/appConstants";

export const renderStatus = (params) => {
  return (
    params.value && !isEmptyObject(params.row) && (
      <Chip
        variant="outlined"
        size="small"
        color={incidentStatus[params.value].color}
        icon={incidentStatus[params.value].icon}
        label={incidentStatus[params.value].label}
      />
    )
  );
};

export const renderDate = (params) => {
  return params.value ? format(Date.parse(params.value), "dd/MM/yyyy") : "";
};

export const renderTime = (params) => {
  return params.value ? format(Date.parse(params.value), "h:mm a") : "";
};

// export const renderQa = (params) => {
//   return params.value ? <QaIcon color="action"/>: "";
// };

export const isEmptyObject = (o) => {
  for (var i in o) return false;
  return true;
};

export const optionSetValueFormatter = (value, optionSets) => (value ? optionSets["itemsById"][value].label : value);
