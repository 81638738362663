import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import IncidentTabbedView from "./IncidentTabbedView";
import IncidentRestrictedView from "./IncidentRestrictedView";
import authState from "../auth/authState";
import ScrollContainer from "../app/ScrollContainer";
import fetchSimple from "../services/fetchSimple";
import Loader from "../app/Loader";
import { incidentStateTabs } from "../app/appConstants";

export default function IncidentPage(props) {
  const auth = useRecoilValue(authState);
  const [incident, setIncident] = useState({ fetching: true });
  const routeMatch = useRouteMatch();

  const handleIncidentDates = (incidentData) => {
    incidentData.date = new Date(incidentData.date);
    incidentData.time = new Date(incidentData.time);
    setIncident(incidentData)
  } 

  useEffect(() => {
    async function fetchIncident() {
      await fetchSimple(`/api/incident/${routeMatch.params.id}`, "GET", auth.token, setIncident, handleIncidentDates);
    }
    fetchIncident();
  }, [auth.token, routeMatch.params.id]);

  // const handlePhaseChange = (updatedIncident) => {
  //   setIncident(updatedIncident)
  // }

  return (
    <ScrollContainer sx={{ backgroundColor: "background.greyMid" }}>
      <Container maxWidth={incident.state === "ignored" || incident.state === "draft" ? "sm" : "lg"}>
        <Box sx={{ pb: 8 }}>
          {incident.fetching ? (
            <Loader />
          ) : incident.state === "ignored" || incident.state === "draft" ? (
            <IncidentRestrictedView
              incident={incident}
              incidentFetching={incident.fetching}
              initialTab={incidentStateTabs[incident.state]}
              handlePhaseChange={handleIncidentDates}
              fullPage
            />
          ) : (
            <IncidentTabbedView incident={incident} handlePhaseChange={handleIncidentDates} incidentFetching={incident.fetching} initialTab={incidentStateTabs[incident.state]} fullPage />
          )}
        </Box>
      </Container>
    </ScrollContainer>
  );
}
