import React from "react";
import { Link, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

export default function MainMenuLink({ label, route, toggleMainMenu, icon, matchChildren }) {

  const location = useLocation();

  return (
    <ListItemButton
      component={Link}
      to={route}
      sx={{ px: 3, textTransform: "none", color: "primary.contrastText"}}
      onClick={toggleMainMenu ? toggleMainMenu : null}
      selected={matchChildren ? location.pathname.startsWith(route) : location.pathname === route}
    >
      {icon && <ListItemIcon sx={{minWidth:48}}>{icon}</ListItemIcon>}
      <ListItemText>{label}</ListItemText>
    </ListItemButton>
  );
}
